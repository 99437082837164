import Raven from 'raven-js';
import env from 'enviro';
export default function (chunkName) {
  return function asyncComponentErrorHandler(error) {
    const errorMessage = `Failed to load chunk ${chunkName}`;
    if (!env.deployed()) {
      console.warn(errorMessage);
      console.error(error);
      throw error;
    }
    Raven.captureMessage(errorMessage, {
      extra: {
        chunkName,
        originalError: error
      }
    });
    throw error;
  };
}