import { TIMEOUT_MAX_MS, TIMEOUT_MIN_MS } from './constants';
const FATAL_ERROR_MESSAGES = {
  invalidClientInstance: `expected "client" to be available with these method(s) ["resolve", "logExposure", "logExposures"].`
};
const ERROR_MESSAGES = {
  storeInvalid: 'expected "store" to be a `superstore` instance implementing each of these method(s) ["has", "get", "set", "delete"].',
  timeoutInvalid: `expected "timeout" to be set between ${TIMEOUT_MIN_MS} - ${TIMEOUT_MAX_MS} milliseconds.`,
  ceidInvalid: 'expected "ceid" to be a string or number.',
  operationModeInvalid: 'expected "mode" to be a string.',
  operationModeInvalidOption: 'expected "mode" to be either "default", "cahce-only" or "network-only".',
  treatmentsNotObject: 'expected "treatments" to be an object.',
  treatmentInvalid: treatmentKey => `expected treatments to be an object containing each of these properties ["identifier", "parameters"]. Treatment: "${treatmentKey}"`,
  treatmentMaxAgeInvalid: treatmentKey => `expected treatment maxAge to be a number greater than 0. Treatment: "${treatmentKey}"`,
  treatmentParametersInvalid: treatmentKey => `expected treatment parameters to be of the shape Object<String, Array>. Treatment: "${treatmentKey}"`,
  treatmentParameterInvalid: (treatmentKey, treatmentParameter) => `expected parameters options to be a non-empty array. Treatment: "${treatmentKey}", Parameter: "${treatmentParameter}"`,
  identifiersNotObject: 'expected "identifiers" to be an object.',
  identifierTypeInvalid: (validIdentifierKeysString, identifierType) => `expected identifier to be one of ["${validIdentifierKeysString}"]. Identifier: "${identifierType}"`,
  experimentsNotObject: 'expected "experiments" to be an object.',
  experimentInvalid: experimentKey => `expected experiment to be an object containing each of these properties ["identifierType", "parameters"]. Experiment: "${experimentKey}"`,
  experimentIdentifierTypeInvalid: experimentKey => `expected experiment identifierType to be a string. Experiment: "${experimentKey}"`,
  experimentInvalidIdentifierType: (validIdentifierTypesString, identifierType, experimentKey) => `expected experiment identifierType to be one of ["${validIdentifierTypesString}"]. Experiment: "${experimentKey}", Identifier Type: "${identifierType}"`,
  identifierTypeNotIncludedInIdentifiers: (experimentIdentifierKey, experimentKey) => `expected experiment identifierType of "${experimentIdentifierKey}" to be present on the "identifiers" object. Experiment: "${experimentKey}"`,
  experimentMaxAgeInvalid: experimentKey => `expected experiment maxAge to be a number greater than 0. Experiment: "${experimentKey}"`,
  experimentParametersInvalid: experimentKey => `expected experiment parameters to be of the shape Object<String, Array>. Experiment: "${experimentKey}"`,
  experimentParameterInvalid: (experimentKey, parameterName) => `expected experiment parameters options to be a non-empty array. Experiment: "${experimentKey}", Parameter: "${parameterName}"`,
  experimentWithoutParameters: experimentKey => `expected experiment parameters to be a non-empty object. Experiment: "${experimentKey}"`,
  identifiersNotProvidedWithExperiment: 'expected "identifiers" to be present when passing "experiments".',
  experimentsProvidedWithTreatments: 'expected either "treatments" or "experiments" to be present. not both.',
  httpModuleInvalid: 'expected "http" to be a `hub-http` client implementing each of these method(s) ["get", "post"].',
  faultyHttpModule: 'The "hub-http" module was not present at runtime. Attempting to fall-back to experiments.yaml',
  quickFetchModuleInvalid: 'expected "quickFetch" to be a `quick-fetch` instance implementing each of these method(s) ["getRequestStateByName, "makeEarlyRequest", "getApiUrl"].',
  faultyQuickFetchModule: 'quickFetchLabel was provided but the "quick-fetch" module was not imported correctly.',
  missingWindowQuickFetch: 'The "quick-fetch" module was correctly imported but `window.quickFetch` is "undefined". Attempting to fall-back to `hub-http`.',
  quickFetchLabelInvalid: 'expected "quickFetchLabel" to be null or a non-empty string.',
  instanceNameInvalid: 'expected "quickFetchLabel" to be null or a non-empty string.',
  apiDomainInvalid: 'expected "apiDomain" to be a non-empty string.',
  exposuresInvalid: 'expected to be provided with an array of treatment keys.',
  exposureItemToBeDefined: 'expected treatment to be a treatment key (string).',
  internalTimeout: 'quick-fetch timed out and will fall-back to `hub-http` if possible.',
  httpUnavailableOnQuickFetch: 'quick-fetch timed out and `hub-http` was not available at the time of the request.'
};
const WARNING_MESSAGES = {
  didEncounterErrors: amount => `validation encountered a total of "${amount}" errors. Please check the logs for more details. The client will proceed with defaults where possible and attempt to recover from these errors. In doubt reach out #growth-data.`,
  invalidQuickFetchLabelDisableQuickFetch: 'an invalid (or empty) quick-fetch label was provided. As a security measure quick-fetch will be disabled for this client.',
  invalidInstanceNameDisableCache: 'an invalid (or empty) instance name was provided. As a security measure this instance will not be added to the instance cache pool.',
  invalidApiDomainFallbackToDefault: 'an invalid (or empty) api domain was provided. The default api domain is going to be used for this instance.',
  invalidCeidDisableCeid: 'an invalid (or empty or non-positive) ceid was provided. As a security measure the ceid override will be disabled for this client.',
  ceidDeprecationNotice: 'detected an "ceid" parameter. This parameter is deprecated. Cache will be ignored and all treatments will be retrieved over the network',
  treatmentsDeprecationNotice: 'detected an "treatments" parameter. This parameter is deprecated. Please use the `experiments` parameter when possible'
};
const DEBUG_MESSAGES = {
  resolvedFromCache: 'resolved existing treatments from cache (IndexDB)',
  skippedCache: 'skipped retrieving existing treatments from cache (IndexDB)',
  noNetworkRequestsNeeded: 'no network requests needed. Using cache data',
  networkRequestsNeeded: 'network requests are needed. Attempting to retrieve treatments over the network',
  resolvedFromNetwork: 'resolved treatments from network',
  failedResolveFromNetwork: 'failed to get treatments from network',
  treatmentIgnored: treatmentKey => `treatment "${treatmentKey}" will not be sent because it has an identifier with nullish value.`,
  treatmendExposed: amount => `"${amount}" treatment(s) exposure(s) were succesful`,
  instanceCacheHit: instanceName => `instance "${instanceName}" is cached and the cache will be used instead of the current instance`
};
export { FATAL_ERROR_MESSAGES, ERROR_MESSAGES, WARNING_MESSAGES, DEBUG_MESSAGES };