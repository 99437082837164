import { RequestStates } from '../constants/RequestStates';
export const requestActions = type => ({
  request: () => ({
    type,
    payload: RequestStates.REQUESTING
  }),
  success: () => ({
    type,
    payload: RequestStates.SUCCEEDED
  }),
  failure: error => ({
    type,
    payload: RequestStates.FAILED,
    error
  })
});
export const updateAction = type => payload => ({
  type,
  payload
});
export const simpleAction = type => () => ({
  type
});