module.exports = {
  "/": {
    "name": "root",
    "success": {
      "login page loaded": [
        "LOGIN_LOAD"
      ]
    }
  },
  "/sso": {
    "name": "sso",
    "success": {
      "sso page loaded": [
        "SSO_LOAD"
      ]
    }
  },
  "/sso/acs": {
    "name": "ssoAcs",
    "success": {
      "sso-acs page loaded": [
        "SSO_ACS_LOAD"
      ]
    },
    "error": [
      "SSO_ACS_ERROR"
    ]
  },
  "/sso/complete": {
    "name": "ssoComplete",
    "success": {
      "sso-complete page loaded": [
        "SSO_COMPLETE_LOAD"
      ]
    },
    "error": [
      "SSO_COMPLETE_ERROR"
    ]
  },
  "/two-factor": {
    "name": "twoFactor",
    "success": {
      "two-factor page loaded": [
        "TWO_FACTOR_LOAD"
      ]
    }
  },
  "/two-factor/recovery": {
    "name": "twoFactorRecovery",
    "success": {
      "two-factor recovery page loading": [
        "TWO_FACTOR_RECOVERY_LOADING"
      ],
      "two-factor recovery page loaded": [
        "TWO_FACTOR_RECOVERY_LOAD"
      ]
    }
  },
  "/two-factor/support-recovery": {
    "name": "twoFactorSupportRecovery",
    "success": {
      "two-factor support recovery page loaded": [
        "TWO_FACTOR_SUPPORT_RECOVERY_LOAD"
      ]
    }
  },
  "/two-factor/admin-recovery": {
    "name": "twoFactorAdminRecovery",
    "success": {
      "two-factor admin recovery page loaded": [
        "TWO_FACTOR_ADMIN_RECOVERY_LOAD"
      ]
    }
  },
  "/two-factor/recovery-verify": {
    "name": "twoFactorRecoveryVerify",
    "success": {
      "two-factor recovery verify page loaded": [
        "TWO_FACTOR_RECOVERY_VERIFY_LOAD"
      ]
    }
  },
  "/confirm-to-login": {
    "name": "confirmToLogin",
    "success": {
      "c2l page loaded": [
        "CONFIRM_TO_LOGIN_LOAD"
      ]
    }
  },
  "/passwordReset": {
    "name": "passwordReset",
    "success": {
      "password reset page loaded": [
        "PASSWORD_RESET_LOAD"
      ]
    }
  },
  "/googleLoginRedirect": {
    "name": "googleLogin",
    "success": {
      "google login redirect page loaded": [
        "GOOGLE_LOGIN_REDIRECT_LOAD"
      ]
    }
  },
  "/trust-token": {
    "name": "trustToken",
    "success": {
      "trust token page loaded": [
        "TRUST_TOKEN_LOAD"
      ]
    }
  },
  "/forgot": {
    "success": {
      "forgot password page loaded": [
        "FORGOT_LOAD"
      ],
      "email sent page loaded": [
        "EMAIL_SEND_SUCCESS"
      ]
    }
  },
  "/password-leak": {
    "success": {
      "password leaked page loaded": [
        "LOCKED_LOAD"
      ],
      "email sent page loaded": [
        "EMAIL_SEND_SUCCESS"
      ]
    }
  },
  "/suspicious-activity": {
    "success": {
      "suspicious activity page loaded": [
        "LOCKED_LOAD"
      ],
      "email sent page loaded": [
        "EMAIL_SEND_SUCCESS"
      ]
    }
  },
  "/idle-user": {
    "success": {
      "idle user page loaded": [
        "LOCKED_LOAD"
      ],
      "email sent page loaded": [
        "EMAIL_SEND_SUCCESS"
      ]
    }
  },
  "/update-password": {
    "success": {
      "update password page loaded": [
        "LOCKED_LOAD"
      ],
      "email sent page loaded": [
        "EMAIL_SEND_SUCCESS"
      ]
    }
  },
  "/beta": {
    "success": {
      "email entry page loaded": [
        "SL_EMAIL_ENTRY_SUCCESS"
      ]
    }
  },
  "/chatspot": {
    "success": {
      "email entry page loaded": [
        "SL_EMAIL_ENTRY_SUCCESS"
      ]
    }
  },
  "/connect": {
    "success": {
      "email entry page loaded": [
        "SL_EMAIL_ENTRY_SUCCESS"
      ]
    }
  },
  "/googleLoginLoopback": {
    "success": {
      "google login loopback loaded": [
        "GOOGLE_LOGIN_REDIRECT_LOAD"
      ]
    }
  },
  "/microsoftLoginRedirect": {
    "success": {
      "microsoft login redirect loaded": [
        "MICROSOFT_LOGIN_REDIRECT_LOAD"
      ]
    }
  },
  "/internal-app": {
    "success": {
      "internal login set app cookie page loaded": [
        "INTERNAL_LOGIN_APP_COOKIE_LOAD"
      ]
    }
  }
};