/* hs-eslint ignored failing-rules */

'use es6';

import Raven from 'raven-js';

/* eslint-disable-next-line hubspot-dev/no-confusing-browser-globals */
if (self !== top) {
  console.warn(`Detected HubSpot login being framed from ${document.referrer}. Note that this is not supported and we suggest you look into integrating properly.`);
  Raven.setExtraContext({
    iframeReferrer: document.referrer
  });
}