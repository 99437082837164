/**
 * Returns the styles for the toast based on the variant
 * @param variant - The variant of the toast
 * @returns The styles for the toast
 */
export const getVariantStyles = variant => {
  switch (variant) {
    case 'info':
      return {
        backgroundColor: 'fill-secondary-subtle',
        borderBlockColor: 'fill-info-hover',
        borderInlineColor: 'fill-info-hover'
      };
    case 'warning':
      return {
        backgroundColor: 'fill-caution-subtle',
        borderBlockColor: 'fill-caution-hover',
        borderInlineColor: 'fill-caution-hover'
      };
    case 'danger':
      return {
        backgroundColor: 'fill-alert-subtle',
        borderBlockColor: 'fill-accent-red-hover',
        borderInlineColor: 'fill-accent-red-hover'
      };
    case 'success':
      return {
        backgroundColor: 'fill-positive-subtle',
        borderBlockColor: 'fill-accent-green-hover',
        borderInlineColor: 'fill-accent-green-hover'
      };
    default:
      {
        const __exhaustiveCheck = variant;
        throw new Error(`Unhandled \`variant\` argument:\n ${variant}`);
      }
  }
};