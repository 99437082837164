/**
 * Map of shorthand style props to their longhand logical style props.
 * This map includes shorthand properties that don't automatically map to longhand properties browser-side.
 * Instead, we map these shorthand properties to the longhand logical properties code-side.
 */
export const SHORTHAND_STYLE_PROPS_MAP = {
  padding: ['paddingBlockStart', 'paddingInlineEnd', 'paddingBlockEnd', 'paddingInlineStart'],
  margin: ['marginBlockStart', 'marginInlineEnd', 'marginBlockEnd', 'marginInlineStart'],
  inset: ['insetBlockStart', 'insetInlineEnd', 'insetBlockEnd', 'insetInlineStart'],
  borderWidth: ['borderBlockStartWidth', 'borderInlineEndWidth', 'borderBlockEndWidth', 'borderInlineStartWidth'],
  borderStyle: ['borderBlockStartStyle', 'borderInlineEndStyle', 'borderBlockEndStyle', 'borderInlineStartStyle'],
  borderColor: ['borderBlockStartColor', 'borderInlineEndColor', 'borderBlockEndColor', 'borderInlineStartColor'],
  borderRadius: ['borderStartStartRadius', 'borderStartEndRadius', 'borderEndEndRadius', 'borderEndStartRadius']
};