'use es6';

import { hasValidStatus } from './treatments';
export function canExpose(treatment) {
  if (treatment && treatment.key && treatment.identifier) {
    return hasValidStatus(treatment);
  }
  return false;
}
export function shouldExpose(treatment) {
  if (canExpose(treatment)) {
    const {
      isEnrolled,
      isDefaulted,
      isActive,
      isOverridden
    } = treatment.status;
    return isOverridden || isActive && isEnrolled && !isDefaulted;
  }
  return false;
}
export function resolveExposure(treatmentKey, treatments = {}) {
  const treatment = treatments[treatmentKey];
  return shouldExpose(treatment) ? treatment : null;
}
export function adaptExposuresForApi(treatments = {}) {
  return Object.keys(treatments).map(treatmentKey => {
    const treatment = treatments[treatmentKey];
    return {
      key: treatmentKey,
      identifier: treatment.identifier
    };
  });
}
export function resolveExposures({
  treatments = {},
  treatmentKeys = []
} = {}) {
  return treatmentKeys.reduce((acc, treatmentKey) => {
    const exposure = resolveExposure(treatmentKey, treatments);
    return Object.assign({}, acc, exposure && {
      [exposure.key]: exposure
    });
  }, {});
}