'use es6';

import { TwoFactorRecoveryActionTypes } from '../actions/twoFactorRecovery';
import { RequestStates, requestSucceeded, requestFailed } from '../constants/RequestStates';
import { Map as ImmutableMap } from 'immutable';
import { AdminRecoveryEligibilityStatuses } from '../constants/AdminRecoveryEligibilityStatuses';
import TwoFactorAuthStatuses from '../constants/TwoFactorAuthStatuses';
const initialState = new ImmutableMap({
  recoveryEmailState: RequestStates.UNATTEMPTED,
  recoveryAdminEmailState: RequestStates.UNATTEMPTED,
  verifyRecoveryTokenState: RequestStates.UNATTEMPTED,
  supportEmailState: RequestStates.UNATTEMPTED,
  verifyPhoneNumber: {
    extension: '',
    phoneNumber: ''
  },
  verifyLastLoginWhere: '',
  verifyLastLoginWhen: '',
  verifyUsualLoginWhere: '',
  verifyUsualLoginHow: '',
  verifyCompanyAndDomain: '',
  adminRecoveryEligibility: {
    eligible: AdminRecoveryEligibilityStatuses.UNATTEMPTED,
    admins: []
  },
  recoveryCode: '',
  isInvalidCodeAlertVisible: false,
  adminRecoveryStatus: RequestStates.UNATTEMPTED,
  accountEmailToRecoverStatus: AdminRecoveryEligibilityStatuses.UNATTEMPTED,
  accountEmailToRecover: '',
  isAdminRecoveryErrorAlertVisible: false,
  eligibleForNametag: false,
  nameTag: {
    isRequestInitSuccessful: undefined,
    errorMessage: null,
    id: undefined
  }
});
export default function twoFactorRecovery(state = initialState, action) {
  switch (action.type) {
    case TwoFactorRecoveryActionTypes.SEND_RECOVERY_EMAIL:
    case TwoFactorRecoveryActionTypes.RESEND_RECOVERY_EMAIL:
      return state.set('recoveryEmailState', action.payload);
    case TwoFactorRecoveryActionTypes.SEND_RECOVERY_EMAIL_TO_ADMINS:
      return state.set('recoveryAdminEmailState', action.payload);
    case TwoFactorRecoveryActionTypes.VERIFY_RECOVERY_TOKEN:
      return state.set('verifyRecoveryTokenState', action.payload);
    case TwoFactorRecoveryActionTypes.SEND_SUPPORT_EMAIL:
      return state.set('supportEmailState', action.payload);
    case TwoFactorRecoveryActionTypes.UPDATE_VERIFY_PHONE_NUMBER:
      return state.set('verifyPhoneNumber', action.payload);
    case TwoFactorRecoveryActionTypes.UPDATE_VERIFY_LAST_LOGIN_WHERE:
      return state.set('verifyLastLoginWhere', action.payload);
    case TwoFactorRecoveryActionTypes.UPDATE_VERIFY_LAST_LOGIN_WHEN:
      return state.set('verifyLastLoginWhen', action.payload);
    case TwoFactorRecoveryActionTypes.UPDATE_VERIFY_USUAL_LOGIN_WHERE:
      return state.set('verifyUsualLoginWhere', action.payload);
    case TwoFactorRecoveryActionTypes.UPDATE_VERIFY_USUAL_LOGIN_HOW:
      return state.set('verifyUsualLoginHow', action.payload);
    case TwoFactorRecoveryActionTypes.UPDATE_VERIFY_COMPANY_AND_DOMAIN:
      return state.set('verifyCompanyAndDomain', action.payload);
    case TwoFactorRecoveryActionTypes.INIT_NAMETAG_RECOVERY:
      if (requestSucceeded(action.payload)) {
        const {
          id
        } = action.data;
        return state.set('nameTag', {
          isRequestInitSuccessful: true,
          errorMessage: null,
          id
        });
      }
      if (requestFailed(action.payload)) {
        return state.set('nameTag', {
          errorMessage: action.error.message ? action.error.message : ''
        });
      }
      return state;
    case TwoFactorRecoveryActionTypes.INIT_NAMETAG_RECOVERY_CANCEL:
      return state.set('nameTag', {
        isRequestInitSuccessful: undefined
      });
    case TwoFactorRecoveryActionTypes.SET_ADMIN_RECOVERY_ELIGIBILITY:
      //this
      if (requestSucceeded(action.payload)) {
        let eligibilityStatus = AdminRecoveryEligibilityStatuses.INELIGIBLE;
        if (action.data.eligible) {
          eligibilityStatus = action.data.shouldForce ? AdminRecoveryEligibilityStatuses.FORCED_ELIGIBLE : AdminRecoveryEligibilityStatuses.ELIGIBLE;
        }
        return state.set('adminRecoveryEligibility', {
          eligible: eligibilityStatus,
          admins: action.data.admins
        }).set('eligibleForNametag', action.data.eligibleForNametag);
      }
      if (requestFailed(action.payload)) {
        return state.merge({
          isAdminRecoveryErrorAlertVisible: true,
          adminRecoveryStatus: action.payload
        });
      }
      return state;
    case TwoFactorRecoveryActionTypes.AUTHORIZE_2FA_REMOVAL:
      if (requestSucceeded(action.payload)) {
        if (action.data === TwoFactorAuthStatuses.INVALID_CODE) {
          return state.merge({
            adminRecoveryStatus: action.payload,
            isInvalidCodeAlertVisible: true
          });
        }
        return state.set('adminRecoveryStatus', action.data);
      }
      if (requestFailed(action.payload)) {
        return state.merge({
          isAdminRecoveryErrorAlertVisible: true,
          adminRecoveryStatus: action.payload
        });
      }
      return state.set('adminRecoveryStatus', action.payload);
    case TwoFactorRecoveryActionTypes.VERIFY_2FA_REMOVAL:
      if (requestSucceeded(action.payload)) {
        if (action.data.recoveryStatus === TwoFactorAuthStatuses.OK) {
          return state.merge({
            accountEmailToRecover: action.data.userEmail,
            accountEmailToRecoverStatus: action.payload
          });
        }
        return state.set('accountEmailToRecoverStatus', action.data.recoveryStatus);
      }
      return state.set('accountEmailToRecoverStatus', action.payload);
    case TwoFactorRecoveryActionTypes.UPDATE_RECOVERY_CODE:
      return state.set('recoveryCode', action.payload);
    case TwoFactorRecoveryActionTypes.HIDE_INVALID_RECOVERY_CODE:
      return state.set('isInvalidCodeAlertVisible', false);
    default:
      return state;
  }
}