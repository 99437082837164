/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-const */
/* eslint-disable no-throw-literal */

'use es6';

import { getFullUrl } from 'hubspot-url-utils';
import Alarm from '../model/Alarm';
import ApiError from './ApiError';
import { getCookie } from '../util/Cookies';
class AlarmApi {
  constructor(dom, storage, events) {
    this.dom = dom;
    this.storage = storage;
    this.events = events;
  }
  getAlarms(appName, callback) {
    const {
      dom,
      storage,
      events
    } = this;
    let httpRequest = this.getHttpRequest();
    if (!httpRequest) {
      throw 'Could not create XMLHttpRequest. Browser may not support AJAX.';
    }
    const url = this.getUrl(appName);
    const requestHandler = () => {
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        if (httpRequest.status === 200) {
          try {
            const result = JSON.parse(httpRequest.responseText);
            let alarms = [];
            if (Array.isArray(result)) {
              alarms = result.map(alarmData => {
                return new Alarm(alarmData, dom, storage, events);
              });
            }
            callback(alarms, null);
          } catch (e) {
            callback(null, new ApiError(httpRequest, e));
          }
          return;
        } else {
          callback(null, new ApiError(httpRequest));
        }
      }
    };
    httpRequest.onreadystatechange = requestHandler;
    httpRequest.open('GET', url);
    const csrfCookie = getCookie('csrf.app');
    if (csrfCookie !== null) {
      httpRequest.withCredentials = true;
      httpRequest.setRequestHeader('X-HubSpot-CSRF-hubspotapi', csrfCookie);
    } else {
      console.log('No CSRF Cookie found.');
    }
    httpRequest.send();
    return true;
  }

  /**
   * @VisibleForTesting
   */
  getHttpRequest() {
    return new XMLHttpRequest();
  }
  getUrl(appName) {
    const lang = window.I18n && window.I18n.langEnabled ? window.I18n.lang : 'en';
    const portalIdArray = /^\/(?:[A-Za-z0-9-_]*)\/(\d+)(?:\/|$)/.exec(document.location.pathname);
    const maybePortalIdParam = portalIdArray && portalIdArray.length > 1 ? `/${portalIdArray[1]}` : '';
    const fullUrl = getFullUrl('api');
    return `${fullUrl}/firealarm/v4/alarm/${appName}${maybePortalIdParam}?lang=${lang}`;
  }
}
export default AlarmApi;