'use es6';

class EventGroups {
  constructor() {
    this.eventGroups = {
      ON_LOAD: 'onLoad',
      ON_RECEIVE_ALARM: 'onReceiveAlarm',
      ON_RENDER_ALARM: 'onRenderAlarm',
      ON_RENDER_ALL: 'onRenderAll',
      ON_DISMISS_ALARM: 'onDismissAlarm',
      CREATE_ALARM: 'createAlarm'
    };
    this.keys().forEach(key => {
      const value = this.eventGroups[key];
      this[key] = value;
    });
  }
  keys() {
    return Object.keys(this.eventGroups);
  }
  getByKey(key) {
    return this[key];
  }
}
export default new EventGroups();