/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable no-throw-literal */
/* eslint-disable hubspot-dev/no-confusing-browser-globals */
/* eslint-disable prefer-rest-params */

'use es6';

import AlarmApi from './api/AlarmApi';
import EventGroups from './constants/EventGroups';
import Severity from './constants/Severity';
import Alarm from './model/Alarm';
import AlarmContainer from './model/AlarmContainer';
import EventData from './model/EventData';
import debug from './util/Debug';
import Dom from './util/Dom';
import { isQa } from './util/Env';
import Events from './util/Events';
import { triggerEvent } from './util/CustomEvent';
import { defaults, objectAssign } from './util/Objects';
import Storage from './util/Storage';
import StyleSheet from './view/StyleSheet';
import alarmsDisabled from './util/Selenium';
const DEFAULT_CUSTOM_CONTAINER_CLASS = 'fire-alarm-custom-container';
const HUBSPOT_FIREALARM_KEY = 'fireAlarm';
const INCLUDE_BENDER_DOC_LINK = 'https://git.hubteam.com/HubSpot/FireAlarmUi/blob/master/README.md#including-benderprojectdepandpathvariables';
const NAV_V4_ID = 'hs-nav-v4';
const SHOW_DEBUG_ALARMS_KEY = 'firealarm.show.debug.alarms';
class FireAlarmApp {
  /*
   * @param Object hubspot        window.hubspot object
   * @param Object originalConfig (optional) Config object with the following keys:
   *   {
   *     appName:    String (optional) if this is empty appName is taken from bender.
   *     containers: {
   *       default: {
   *         after:              HTMLElement (optional) element in the parent to insert before
   *         additionalClasses:  CSS classes to add to the container
   *         parent:             HTMLElement The element to add the alarm container to,
   *         before:             HTMLElement (optional) element in the parent to insert before
   *       },
   *       critsit: (optional) An over-ride for Crisit Alarms. Same footprint as the default
   *                container
   *     }
   *   }
   */
  constructor(hubspot, originalConfig = {}) {
    this.className = 'FireAlarmApp';
    this.hubspot = hubspot;
    this.events = new Events();
    this.events.extractPreloadEvents(hubspot[HUBSPOT_FIREALARM_KEY] || {});
    this.alarms = [];
    try {
      const localStorage = window && window.localStorage ? window.localStorage : false;
      this.storage = new Storage(localStorage);
    } catch (e) {
      this.storage = new Storage(false);
    }
    this.events.runEvents(EventGroups.ON_LOAD);
    this.config = defaults(originalConfig, {
      containers: {}
    });
    hubspot[HUBSPOT_FIREALARM_KEY] = this;
  }

  /**
   * @param Object config   App Config, same as gets passed to the constructor
   * @return AlarmContainer Returns a container built from config.containers.critsit where the config is set,
   *                        otherwise it falls back to using the supplied container, if that container was
   *                        built from custom config (config.containers.default). Finally, if no container config
   *                        was passed, it creates a new critsit container.
   */
  selectOrMakeCritsitContainer(config, container) {
    if (config.containers.critsit) {
      let critsitConfig = config.containers.critsit;
      critsitConfig.additionalClasses = critsitConfig.additionalClasses || [];
      critsitConfig.additionalClasses.push('firealarm-critsits');
      return this.makeContainer(critsitConfig);
    } else if (config.containers.default) {
      return container;
    } else {
      return this.makeNavContainer(true);
    }
  }
  addCss() {
    const headElement = document.getElementsByTagName('head')[0];
    if (headElement) {
      const styleEl = StyleSheet.getCss();
      headElement.appendChild(styleEl);
    }
  }

  /**
   * @VisibleForTesting
   */
  getAlarmApi() {
    return new AlarmApi(this.getDom(), this.storage, this.events);
  }

  /**
   * @VisibleForTesting
   */
  getDom() {
    if (!this.dom) {
      this.dom = new Dom(document);
    }
    return this.dom;
  }
  getNav() {
    if (!this.navElement) {
      let navElement = this.getDom().getElementById(NAV_V4_ID);
      // If we can't find an element with the nav id just chuck the alarms at the top of the page.
      if (!navElement) {
        navElement = this.getDom().getBody().firstChild;
        // Find the first non-text node
        while (navElement != null && navElement.nodeType == 3) {
          navElement = navElement.nextSibling;
        }
      }
      if (!navElement) {
        throw 'No Nav element could be found to add FireAlarms';
      }
      this.navElement = navElement;
    }
    return this.navElement;
  }

  /**
   * Get Alarms for this app
   */
  loadAndRenderAppAlarms(appName) {
    const {
      addCss,
      hubspot
    } = this;
    this.getAlarmApi().getAlarms(appName, (newAlarms, apiError) => {
      if (apiError) {
        debug('ApiError', apiError.getMessage(), apiError);
      } else {
        debug('Loaded alarms from FireAlarmApi', newAlarms);
        this.events.runEvents(EventGroups.ON_RECEIVE_ALARM, new EventData(newAlarms));

        // Is this QA? Do we have hubspot.bender.currentProject? If not show a FireAlarm warning the dev.
        if (isQa() && (!hubspot.bender || !hubspot.bender.currentProject)) {
          newAlarms.push(this.getBenderWarningAlarm());
        }
        if (newAlarms.length > 0) {
          if (this.alarms == 0) {
            //Check to make sure the CSS isn't added a second time due to an addAlarm call
            addCss();
          }
          this.alarms = newAlarms;
          this.renderAlarms();
        }
      }
    });
  }
  renderAlarms() {
    const alarms = this.prioritiseAlarms(this.filterAlarms(this.alarms));
    this.containers.default.removeAllAlarms();
    this.containers.critsit.removeAllAlarms();
    alarms.forEach(alarm => {
      if (alarm.severity === Severity.CRITSIT) {
        this.containers.critsit.addAlarm(alarm);
      } else {
        this.containers.default.addAlarm(alarm);
      }
    });
    this.renderContainers();
    alarms.forEach(alarm => {
      this.events.runEvents(EventGroups.ON_RENDER_ALARM, alarm);
    });
    this.events.runEvents(EventGroups.ON_RENDER_ALL, new EventData(alarms));
  }
  getAppName() {
    if (!this.hubspot || !this.hubspot.bender || !this.hubspot.bender.currentProject) {
      return null;
    }
    return this.hubspot.bender.currentProject;
  }
  makeContainer(config) {
    const additionalClasses = (config.additionalClasses || []).concat([DEFAULT_CUSTOM_CONTAINER_CLASS]);
    const mergedConfig = objectAssign({
      additionalClasses
    }, config);
    return new AlarmContainer(mergedConfig);
  }
  makeNavContainer(critsit = false) {
    const dom = this.getDom();
    const body = dom.getBody();
    const nav = this.getNav();
    let config;
    if (critsit) {
      config = {
        parent: body,
        before: nav,
        additionalClasses: ['firealarm-critsits']
      };
    } else {
      config = {
        parent: body,
        after: nav
      };
    }
    return new AlarmContainer(config);
  }

  /**
   * Get the QA warning message asking devs to install benderProjectDepAndPathVariables
   */
  getBenderWarningAlarm() {
    return new Alarm({
      title: 'FireAlarm needs some setup!',
      message: `Please include 'benderProjectDepAndPathVariables' in this app to report it to FireAlarm. See the <a href="${INCLUDE_BENDER_DOC_LINK}">FireAlarm docs</a> for more information.`,
      createdAt: Date.now(),
      severity: Severity.MAINTENANCE.key
    }, this.getDom(), this.storage);
  }
  filterAlarms(alarms) {
    return alarms.filter(alarm => {
      if (alarm.debug && this.storage.getItem(SHOW_DEBUG_ALARMS_KEY) !== 'true') {
        return false;
      }
      let canRenderAlarm = true;
      if (alarm.urlRegexPattern) {
        const regEx = new RegExp(alarm.urlRegexPattern);
        canRenderAlarm = regEx.test(window.location.href);
      }
      if (alarm.querySelector) {
        try {
          // "window.document.querySelector()" will throw error if selector passed is invalid
          const hasValidSelector = window.document.querySelector(alarm.querySelector);
          canRenderAlarm = !!hasValidSelector;
        } catch (_err) {
          canRenderAlarm = false;
        }
      }
      return canRenderAlarm;
    });
  }

  /**
   * Returns alarms sorted by priority, highest to lowest
   *
   * @return array
   */
  prioritiseAlarms(alarms) {
    return alarms.sort((a, b) => {
      if (a.severity === b.severity) {
        return 0;
      }
      return a.severity.priority < b.severity.priority ? 1 : -1;
    });
  }

  // This is used by FireAlarmClient to create frontend alarms
  addAlarm(alarmData) {
    let alarm = new Alarm(alarmData, this.getDom(), this.storage, this.events, alarmData.date);
    if (this.alarms.length == 0) {
      this.addCss();
    }
    this.alarms.push(alarm);
    if (alarm.severity === Severity.CRITSIT) {
      this.containers.critsit.addAlarm(alarm);
    } else {
      this.containers.default.addAlarm(alarm);
    }
    this.renderContainers();
    this.events.runEvents(EventGroups.ON_RENDER_ALARM, alarm);
  }
  renderContainers() {
    if (!alarmsDisabled(this.storage)) {
      if (this.containers.default.alarmElements.length > 0) {
        this.containers.default.render();
      }
      if (this.containers.default !== this.containers.critsit && this.containers.critsit.alarmElements.length > 0) {
        this.containers.critsit.render();
      }
    }
  }
  addLocationChangeEvent() {
    //
    // Warning: Modifying global history object
    //
    history.pushState = (f => function pushState() {
      const ret = f.apply(this, arguments);
      triggerEvent('fireAlarmLocationChange');
      return ret;
    })(history.pushState);
    history.replaceState = (f => function replaceState() {
      const ret = f.apply(this, arguments);
      triggerEvent('fireAlarmLocationChange');
      return ret;
    })(history.replaceState);
    window.addEventListener('popstate', () => {
      triggerEvent('fireAlarmLocationChange');
    });
    window.addEventListener('fireAlarmLocationChange', () => {
      debug('Refiltering fire alarms for this page');
      this.renderAlarms();
    });
  }
  start() {
    debug('FireAlarm started');
    const appName = this.config.appName || this.getAppName();
    const containerConfig = this.config.containers.default || false;
    const container = containerConfig ? this.makeContainer(containerConfig) : this.makeNavContainer();
    const critsitContainer = this.selectOrMakeCritsitContainer(this.config, container);
    this.containers = {
      default: container,
      critsit: critsitContainer
    };
    this.loadAndRenderAppAlarms(appName);
    this.addLocationChangeEvent();
    this.events.runEvents(EventGroups.CREATE_ALARM, this);
  }
}
FireAlarmApp['HUBSPOT_FIREALARM_KEY'] = HUBSPOT_FIREALARM_KEY;
export default FireAlarmApp;