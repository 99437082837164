import styled from 'styled-components';

// @ts-expect-error not converted to TS
import Image from '../../images/imageUrls.js';
export const SplashBackground = styled.div.withConfig({
  displayName: "MarketingCtastyles__SplashBackground",
  componentId: "wzlmop-0"
})(["background-color:#fef4ea;background-size:cover;position:absolute;height:100%;width:100%;z-index:-1;background-clip:border-box;background-image:url(", ");background-position:25% 25%;"], Image.desktopCircle);
export const ExtraCtaBackground = styled.div.withConfig({
  displayName: "MarketingCtastyles__ExtraCtaBackground",
  componentId: "wzlmop-1"
})(["background-color:#fcead7;background-size:auto;position:absolute;height:100%;width:100%;z-index:-1;background-clip:border-box;background-position:center;@media (min-width:401px){background-image:url(", ");}@media (max-width:400px){background-image:url(", ");}"], Image.desktopDonut, Image.mobileDonut);
export const MarketingCtaWrapper = styled.div.withConfig({
  displayName: "MarketingCtastyles__MarketingCtaWrapper",
  componentId: "wzlmop-2"
})(["display:flex;gap:9px;padding-top:16px;padding-bottom:16px;justify-content:center;button.uiButton{min-width:228px;margin-left:0px;}@media (max-width:545px){flex-wrap:wrap;flex-direction:column;width:100%;padding-bottom:40px;button.uiButton{width:100%;}}@media (max-width:400px){padding-bottom:40px;}"]);
export const MarketingCtaFormWrapper = styled.div.withConfig({
  displayName: "MarketingCtastyles__MarketingCtaFormWrapper",
  componentId: "wzlmop-3"
})(["width:512px;margin:0 auto;max-width:100%;padding-left:16px;padding-right:16px;form{padding:16px 24px 32px 24px;}h3{color:#2e475b;}"]);
export const SplashWrapper = styled.div.withConfig({
  displayName: "MarketingCtastyles__SplashWrapper",
  componentId: "wzlmop-4"
})(["display:flex;justify-content:center;gap:10%;flex-wrap:wrap;padding:16px;padding-bottom:42px;"]);
export const SplashContent = styled.div.withConfig({
  displayName: "MarketingCtastyles__SplashContent",
  componentId: "wzlmop-5"
})(["display:flex;flex-direction:column;align-items:", ";min-width:40%;max-width:464px;padding-top:6%;"], props => props.centered ? 'center' : 'flex-start');
export const PercentText = styled.h2.withConfig({
  displayName: "MarketingCtastyles__PercentText",
  componentId: "wzlmop-6"
})(["color:#f05129;font-size:60px;font-weight:600;margin-bottom:0;"]);