export const toastReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TOAST':
      return Object.assign({}, state, {
        toasts: [...state.toasts, action.payload]
      });
    case 'DELETE_TOAST':
      {
        const updatedToasts = state.toasts.filter(toast => toast.id !== action.payload);
        return Object.assign({}, state, {
          toasts: updatedToasts
        });
      }
    case 'CLEAR_TOASTS':
      return Object.assign({}, state, {
        toasts: []
      });
    default:
      {
        const __exhaustiveCheck = action;
        throw new Error(`Unhandled action type: ${action}`);
      }
  }
};