// @ts-expect-error no types from package
import Cookies from 'js-cookie';
import { parseUrl } from 'hub-http/helpers/url';
import { parse } from 'hub-http/helpers/params';
const NONCE_COOKIE = 'hsgn';
export default {
  get() {
    return Cookies.get(NONCE_COOKIE);
  },
  storeAndRedirect({
    nonce,
    redirectUrl
  }) {
    Cookies.set(NONCE_COOKIE, nonce, {
      path: '/',
      secure: true,
      expires: 1 / 48
    });
    window.location = redirectUrl; // Safe usage of window.location assignment. Passed url is constructed via login service.
  },
  storeAndReturnGoogleId({
    nonce,
    redirectUrl
  }) {
    Cookies.set(NONCE_COOKIE, nonce, {
      path: '/',
      secure: true,
      expires: 1 / 48
    });
    return {
      googleId: parse(parseUrl(redirectUrl).query).client_id
    };
  }
};