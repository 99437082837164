import { useEffect } from 'react';
import Raven from 'raven-js';
const Compliance = ({
  pathname
}) => {
  useEffect(() => {
    try {
      // @ts-expect-error _hsg is provided by compliance script in index.html.tsx
      if (window._hsg) {
        // @ts-expect-error _hsg is provided by compliance script in index.html.tsx
        window._hsg.compliance.scanCookies();
        setTimeout(() => {
          const hiddenFooterCookieSettings = document.querySelector('.hs-footer-cookie-settings[hidden]');
          if (hiddenFooterCookieSettings) {
            // @ts-expect-error _hsg is provided by compliance script in index.html.tsx
            window._hsg.compliance.addSettingsLink();
          }
        }, 200);
      }
    } catch (error) {
      Raven.captureException(error);
      console.error(error);
    }
  }, [pathname]);
  return null;
};
export default Compliance;