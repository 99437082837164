import { ConfirmToLoginActionTypes } from '../actions/confirmToLogin';
import { RequestStates } from '../constants/RequestStates';
const initialState = {
  verificationCode: '',
  sendVerificationCodeState: RequestStates.UNATTEMPTED,
  verifyVerificationCodeState: RequestStates.UNATTEMPTED
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case ConfirmToLoginActionTypes.UPDATE_VERIFICATION_CODE:
      return Object.assign({}, state, {
        verificationCode: action.payload,
        verifyVerificationCodeState: RequestStates.UNATTEMPTED
      });
    case ConfirmToLoginActionTypes.SEND_VERIFICATION_CODE:
      return Object.assign({}, state, {
        sendVerificationCodeState: action.payload,
        verifyVerificationCodeState: RequestStates.UNATTEMPTED,
        verificationCode: '',
        verificationCodeError: action.error
      });
    case ConfirmToLoginActionTypes.VERIFY_VERIFICATION_CODE:
      return Object.assign({}, state, {
        verifyVerificationCodeState: action.payload,
        sendVerificationCodeState: RequestStates.UNATTEMPTED,
        verificationCodeError: action.error
      });
    case ConfirmToLoginActionTypes.CLICKED_BACK_BUTTON:
      return initialState;
    case ConfirmToLoginActionTypes.CLICKED_DISMISS_CODE_SENT_BUTTON:
      {
        return Object.assign({}, state, {
          sendVerificationCodeState: RequestStates.UNATTEMPTED
        });
      }
    default:
      return state;
  }
});