import { useTheme } from 'styled-components';
/**
 * This hook uses size tokens and returns the appropriate CSS object for the CloseButton.
 *
 * @param variantTokens - Object that contains the necessary variant tokens for idle, hover, active, and disabled.
 *
 * @returns A new CSS Object that returns the appropriate styles.
 */
export const useVariantCssObject = variantTokens => {
  const theme = useTheme();

  // The type for background and border colors was broadened in
  // `ButtonStateStyles` to `Color | (string & {})` to accommodate
  // setting a transparent, non-token value for the ghost and transparent
  // button variants. In the future, this value may come from a token
  // and we can remove the variables and type casts below.
  const backgroundColorIdle = variantTokens.idle.backgroundColor;
  const colorIdle = variantTokens.idle.color;
  const backgroundColorHover = variantTokens.hover.backgroundColor;
  const colorHover = variantTokens.hover.color;
  const backgroundColorActive = variantTokens.active.backgroundColor;
  const colorActive = variantTokens.active.color;
  const backgroundColorDisabled = variantTokens.disabled.backgroundColor;
  const colorDisabled = variantTokens.disabled.color;
  return {
    display: 'block',
    backgroundColor: theme.color[backgroundColorIdle],
    borderRadius: theme.borderRadius['300'],
    borderWidth: theme.borderWidth['100'],
    color: theme.color[colorIdle],
    width: 'fit-content',
    '&:hover:not([aria-disabled="true"])': {
      backgroundColor: theme.color[backgroundColorHover],
      color: theme.color[colorHover]
    },
    '&:active:not([aria-disabled="true"])': {
      backgroundColor: theme.color[backgroundColorActive],
      color: theme.color[colorActive]
    },
    '&[aria-disabled="true"]': {
      backgroundColor: theme.color[backgroundColorDisabled],
      color: theme.color[colorDisabled],
      cursor: variantTokens.disabled.cursor
    }
  };
};

/**
 * This hook uses size tokens and returns the appropriate CSS object for the CloseButton.
 *
 * @param sizeTokens - Object that contains the padding & iconSize of the CloseButton.
 *
 * @returns A new CSS Object that returns the appropriate padding.
 */
export const useSizeCssObject = sizeTokens => {
  const theme = useTheme();
  return {
    padding: theme.space[sizeTokens.padding]
  };
};