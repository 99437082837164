import styled from 'styled-components';
export const GoogleSignInDiv = styled.div.withConfig({
  displayName: "GoogleLoginButtonstyles__GoogleSignInDiv",
  componentId: "sc-1n3aib7-0"
})(["align-items:center;background-color:", ";border:", ";border-radius:0.1875rem;color:", ";cursor:", ";display:flex;font-family:'Roboto',sans-serif;font-weight:600;gap:4px;height:41px;justify-content:space-between;padding-left:1px;text-align:center;&:hover{background:", ";border-color:", ";color:", ";text-decoration:none;}"], props => {
  if (props.disabled) return '#eaf0f6';
  return props.smartLogin ? 'white' : '#4285f4';
}, props => props.smartLogin ? '1px solid #dadce0' : '1px solid 4285f4', props => {
  if (props.disabled) return '#b0c1d4';
  return props.smartLogin ? '#3c4043' : 'white';
}, props => props.disabled ? 'not-allowed' : 'pointer', props => {
  if (props.disabled) return '#eaf0f6';
  return props.smartLogin ? '#f8fbff' : '#4776C4';
}, props => props.smartLogin ? '#d2e3fc' : 'none', props => {
  if (props.disabled) return '#b0c1d4';
  return props.smartLogin ? '#3c4043' : 'white';
});
export const ImageWrapper = styled.span.withConfig({
  displayName: "GoogleLoginButtonstyles__ImageWrapper",
  componentId: "sc-1n3aib7-1"
})(["min-width:39px;background-color:", ";border-radius:0.1875rem;display:flex;justify-content:center;align-items:center;height:39px;width:39px;> *{filter:", ";}"], props => props.smartLogin ? 'none' : 'white', props => props.disabled ? 'grayscale(100%)' : 'none');
export const ButtonText = styled.span.withConfig({
  displayName: "GoogleLoginButtonstyles__ButtonText",
  componentId: "sc-1n3aib7-2"
})(["flex-grow:1;padding-right:", ";"], props => props.fullWidth ? '51px' : '12px');