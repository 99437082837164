import { createContainer } from 'usage-tracker-multi';
import events from '../../events.yaml';
const {
  createTracker
} = createContainer({
  storeUuidOnUrl: false
});
const tracker = createTracker({
  events,
  trackerType: 'public',
  allowUnauthed: true
});
const anonymousTracker = createTracker({
  events,
  trackerType: 'anonymous'
});
export const loginVerificationWithHSAuthInitiated = userEmail => {
  tracker.setProperties({
    email: userEmail
  });
  tracker.track('loginVerificationWithHSAuthenticator', {
    action: 'Initiated',
    type: 'login-verification-hs-authenticator-initiated'
  });
};
export const loginVerificationWithHSAuthAccomplished = () => {
  tracker.track('loginVerificationWithHSAuthenticator', {
    action: 'Accomplished',
    type: 'login-verification-hs-authenticator-succeded'
  });
};
export const loginVerificationWithHSAuthFailed = () => {
  tracker.track('loginVerificationWithHSAuthenticator', {
    action: 'Failed',
    type: 'login-verification-hs-authenticator-failed'
  });
};
export const loginVerificationWithHSAuthResend = () => {
  tracker.track('loginVerificationWithHSAuthResent', {
    action: 'Resend',
    type: 'login-verification-hs-authenticator-resent'
  });
};
export const loginVerificationWithHSAuthCodeInitiated = () => {
  tracker.track('loginVerificationWithHSAuthCode', {
    action: 'Initiated',
    type: 'login-verification-hs-authenticator-code-initiated'
  });
};
export const loginVerificationWithHSAuthCodeAccomplished = () => {
  tracker.track('loginVerificationWithHSAuthCode', {
    action: 'Accomplished',
    type: 'login-verification-hs-authenticator-code-succeded'
  });
};
export const trackRememberThisDevice = () => {
  anonymousTracker.track('remeberDevice', {
    action: 'Initiated',
    type: 'login-and-remeber-device'
  });
};
export const trackDoNotRememberThisDevice = () => {
  anonymousTracker.track('doNotRemeberDevice', {
    action: 'Initiated',
    type: 'login-and-do-not-remeber-device'
  });
};
export const trackSmartLoginLoad = userEmail => {
  tracker.setProperties({
    email: userEmail
  });
  tracker.track('smartLoginPageOpened');
};
export const trackSmartLoginOptOut = userEmail => {
  tracker.setProperties({
    email: userEmail
  });
  tracker.track('smartLoginOptOut');
};
export const trackTwoFactorRecoveryMobileAuth = reason => {
  tracker.track('twoFactorRecoveryMobileAuth', {
    action: 'Initiated',
    type: `hs-authenticator-recovery-${reason}`
  });
};
export const trackLoginPageView = () => {
  anonymousTracker.track('loginPageView');
};
export const trackSignupLinkClick = () => {
  anonymousTracker.track('signupLinkClick');
};
export const trackVettingPageView = () => {
  anonymousTracker.track('vettingPageView');
};