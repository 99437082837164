/**
 * The query strings to be attached to the final signup url.
 * @param {SignupEmbedderOptions} options
 * @param {Map} query
 * @returns {string}
 */
export const getSignupQueryString = (options, query) => {
  let querystrings = [];

  // Other queries
  query.forEach((value, key) => querystrings.push([key, value]));

  // Options
  querystrings = querystrings.concat(options);

  // Encode all values
  const encoded = querystrings.reduce((acc, [key, value]) => {
    acc.push(`${key}=${encodeURIComponent(value)}`);
    return acc;
  }, []);
  return !querystrings.length ? '' : `?${encoded.join('&')}`;
};