/* hs-eslint ignored failing-rules */
/* eslint-disable eqeqeq */

'use es6';

class Storage {
  constructor(storage) {
    if (storage === false) {
      this.storageEnabled = false;
    } else {
      this.localStorage = storage;
      this.storageEnabled = null;
    }
  }
  isStorageEnabled() {
    if (this.storageEnabled !== null) {
      return this.storageEnabled;
    }
    try {
      const testKey = 'firealarm-test';
      const time = Date.now();
      this.localStorage.setItem(testKey, time);
      this.storageEnabled = this.localStorage.getItem(testKey) == time;
      this.localStorage.removeItem(testKey);
    } catch (e) {
      this.storageEnabled = false;
    }
    return this.storageEnabled;
  }
  getItem(key) {
    if (this.isStorageEnabled()) {
      return this.localStorage.getItem(key);
    }
    return null;
  }
  removeItem(key) {
    if (this.isStorageEnabled()) {
      this.localStorage.removeItem(key);
    }
  }
  setItem(key, value) {
    if (this.isStorageEnabled()) {
      this.localStorage.setItem(key, value);
    }
  }
}
export default Storage;