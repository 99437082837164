'use es6';

import { getTreatmentsFromHttp, getTreatmentsFromQuickFetch, mutateNetworkError } from './api';
import { filterObject, ensureFn } from './helpers';
import { verifyIfNetworkRequestIsNeeded } from './utils';
import { getOverridesFromCache } from './overrides';
import { DEBUG_MESSAGES, ERROR_MESSAGES } from '../messages';
const mapSchemaToNetworkRequest = (schema = {}) => {
  const entries = Object.entries(schema);
  return entries.map(([key, {
    identifier
  }]) => ({
    key,
    identifier
  }));
};
export function getAsyncNetworkAdapter(args = {}) {
  const {
    apiDomain,
    ceid,
    http,
    quickFetch,
    quickFetchLabel,
    schema,
    identifiers,
    onError,
    timeout
  } = args;
  const getCommonParams = () => ({
    apiDomain,
    timeout,
    // this removes all the properties that are empty or undefined
    query: filterObject(Object.assign({}, identifiers, {
      ceid
    })),
    data: {
      requests: mapSchemaToNetworkRequest(schema),
      identifiers
    }
  });
  const getDataFromHttp = (opts = {}) => getTreatmentsFromHttp(Object.assign({}, getCommonParams(), {
    onComplete: opts.onComplete,
    onError: opts.onError,
    http
  }));
  const getDataFromQuickFetch = (opts = {}) => getTreatmentsFromQuickFetch(Object.assign({}, getCommonParams(), {
    // If a timeout of quick-fetch happens, e.g.: because quick-fetch request actually timed-out
    // Or because the request failed due to window.quickFetch being undefined (which essentially causes a timeout)
    // then we attempt to fallback to a hub-http request.
    // Note.: If this happens within `earlyRequester.js` it is important to mention that `hub-http` will not be available
    // at the runtime. Which means it will silently fail.
    onTimeout: warning => {
      // We only want to report the Error (in this case a warning) but still proceed with
      // retrieving data from hub-http as we don't want the execution to be stopped
      ensureFn(onError(warning));
      getDataFromHttp(Object.assign({}, opts, {
        onError: () =>
        // If `hub-http` fails during `quick-fetch` mode it usually means `hub-http` was unavailable during `quick-fetch`
        // because `hub-http` is not part of the earlyRequester bundle (which is expected)
        // Other errors might occur of course, such as regular hub-http errors, but we should often ignore them as
        // hub-http here is a best-effort attempt to retrieve data from Laboratory BE
        opts.onError(mutateNetworkError(ERROR_MESSAGES.httpUnavailableOnQuickFetch))
      }));
    },
    onComplete: opts.onComplete,
    onError: opts.onError,
    quickFetch,
    quickFetchLabel
  }));
  return quickFetchLabel ? getDataFromQuickFetch : getDataFromHttp;
}
export function wrapNetworkAdapterWithMeta(args = {}) {
  const {
    networkAdapter,
    asyncStore,
    cacheKeys,
    ceid,
    schema,
    onError,
    logDebug,
    quickFetchLabel,
    mode: operationMode
  } = args;
  const safeLogDebug = ensureFn(logDebug);

  // The name of the method to be used within the debug logs
  const methodName = 'wrapNetworkAdapterWithMeta';
  const asyncOverridesFromCache = getOverridesFromCache({
    cacheKeys,
    asyncStore,
    onError
  });
  const data = {
    overridesFromCache: {},
    schema,
    treatments: [],
    networkAdapter: quickFetchLabel ? 'quick-fetch' : 'hub-http'
  };
  return (opts = {}) => {
    return asyncOverridesFromCache.then(overridesFromCache => {
      if (operationMode !== 'network-only') {
        data.overridesFromCache = overridesFromCache;
      }
      safeLogDebug(operationMode === 'network-only' ? DEBUG_MESSAGES.skippedCache : DEBUG_MESSAGES.resolvedFromCache, methodName);
      const requiresNetwork = verifyIfNetworkRequestIsNeeded({
        ceid,
        schema,
        cacheKeys,
        overridesFromCache,
        operationMode
      });
      safeLogDebug(requiresNetwork ? DEBUG_MESSAGES.networkRequestsNeeded : DEBUG_MESSAGES.noNetworkRequestsNeeded, methodName);
      const safeOnComplete = ensureFn(opts.onComplete);
      if (requiresNetwork) {
        networkAdapter({
          onComplete: (treatments = []) => {
            safeLogDebug(DEBUG_MESSAGES.resolvedFromNetwork, methodName);
            data.treatments = treatments;
            safeOnComplete(data);
          },
          onError: err => {
            safeLogDebug(DEBUG_MESSAGES.failedResolveFromNetwork, methodName);
            ensureFn(opts.onError)(err, data);
          }
        });
      } else {
        safeOnComplete(data);
      }
    });
  };
}