import { RequestStates } from '../constants/RequestStates';
import TwoFactorRecoveryDao from '../data/TwoFactorRecoveryDao';
export const TwoFactorRecoveryActionTypes = {
  SEND_RECOVERY_EMAIL: 'SEND_RECOVERY_EMAIL',
  SEND_RECOVERY_EMAIL_TO_ADMINS: 'SEND_RECOVERY_EMAIL_TO_ADMINS',
  RESEND_RECOVERY_EMAIL: 'RESEND_RECOVERY_EMAIL',
  VERIFY_RECOVERY_TOKEN: 'VERIFY_RECOVERY_TOKEN',
  SEND_SUPPORT_EMAIL: 'SEND_SUPPORT_EMAIL',
  UPDATE_VERIFY_PHONE_NUMBER: 'UPDATE_VERIFY_PHONE_NUMBER',
  UPDATE_VERIFY_LAST_LOGIN_WHERE: 'UPDATE_VERIFY_LAST_LOGIN_WHERE',
  UPDATE_VERIFY_LAST_LOGIN_WHEN: 'UPDATE_VERIFY_LAST_LOGIN_WHEN',
  UPDATE_VERIFY_USUAL_LOGIN_WHERE: 'UPDATE_VERIFY_USUAL_LOGIN_WHERE',
  UPDATE_VERIFY_USUAL_LOGIN_HOW: 'UPDATE_VERIFY_USUAL_LOGIN_HOW',
  UPDATE_VERIFY_COMPANY_AND_DOMAIN: 'UPDATE_VERIFY_COMPANY_AND_DOMAIN',
  SET_ADMIN_RECOVERY_ELIGIBILITY: 'SET_ADMIN_RECOVERY_ELIGIBILITY',
  AUTHORIZE_2FA_REMOVAL: 'AUTHORIZE_2FA_REMOVAL',
  UPDATE_RECOVERY_CODE: 'UPDATE_RECOVERY_CODE',
  SHOW_INVALID_RECOVERY_CODE: 'SHOW_INVALID_RECOVERY_CODE',
  HIDE_INVALID_RECOVERY_CODE: 'HIDE_INVALID_RECOVERY_CODE',
  VERIFY_2FA_REMOVAL: 'VERIFY_2FA_REMOVAL',
  INIT_NAMETAG_RECOVERY: 'INIT_NAMETAG_RECOVERY',
  INIT_NAMETAG_RECOVERY_CANCEL: 'INIT_NAMETAG_RECOVERY_CANCEL'
};
const isExpiredTokenResponseText = error => {
  return error.responseText && error.responseText.indexOf('EXPIRED_TWO_FACTOR_TOKEN') >= 0;
};
const isNeedsSupportResponseText = error => {
  return error.responseText && error.responseText.indexOf('EMAIL_NOT_SENT_NEEDS_SUPPORT') >= 0;
};
const requestActions = type => ({
  request: () => ({
    type,
    payload: RequestStates.REQUESTING
  }),
  success: data => ({
    type,
    payload: RequestStates.SUCCEEDED,
    data
  }),
  failure: error => ({
    type,
    payload: isExpiredTokenResponseText(error) ? RequestStates.FAILED_EXPIRED : isNeedsSupportResponseText(error) ? RequestStates.FAILED_NEEDS_SUPPORT : RequestStates.FAILED,
    error
  })
});
const updateAction = type => payload => ({
  type,
  payload
});
const SendTwoFactorRecoveryEmail = requestActions(TwoFactorRecoveryActionTypes.SEND_RECOVERY_EMAIL);
export const sendTwoFactorRecoveryEmail = token => dispatch => {
  dispatch(SendTwoFactorRecoveryEmail.request());
  return TwoFactorRecoveryDao.sendRecoveryEmail(token).then(() => dispatch(SendTwoFactorRecoveryEmail.success())).catch(error => dispatch(SendTwoFactorRecoveryEmail.failure(error)));
};
const ResendTwoFactorRecoveryEmail = requestActions(TwoFactorRecoveryActionTypes.RESEND_RECOVERY_EMAIL);
export const resendTwoFactorRecoveryEmail = token => dispatch => {
  dispatch(ResendTwoFactorRecoveryEmail.request());
  return TwoFactorRecoveryDao.resendRecoveryEmail(token).then(() => dispatch(ResendTwoFactorRecoveryEmail.success())).catch(error => dispatch(ResendTwoFactorRecoveryEmail.failure(error)));
};
const SendTwoFactorAdminRecoveryEmail = requestActions(TwoFactorRecoveryActionTypes.SEND_RECOVERY_EMAIL_TO_ADMINS);
export const sendTwoFactorAdminRecoveryEmail = token => dispatch => {
  dispatch(SendTwoFactorAdminRecoveryEmail.request());
  return TwoFactorRecoveryDao.sendAdminRecoveryEmail(token).then(() => dispatch(SendTwoFactorAdminRecoveryEmail.success())).catch(error => dispatch(SendTwoFactorAdminRecoveryEmail.failure(error)));
};
const InitNameTagRecovery = requestActions(TwoFactorRecoveryActionTypes.INIT_NAMETAG_RECOVERY);
export const initNameTagRecovery = token => dispatch => {
  dispatch(InitNameTagRecovery.request());
  return TwoFactorRecoveryDao.initNameTagRecovery(token).then(data => dispatch(InitNameTagRecovery.success(data))).catch(error => {
    return dispatch(InitNameTagRecovery.failure(error));
  });
};
export const initNameTagRecoveryCancel = updateAction(TwoFactorRecoveryActionTypes.INIT_NAMETAG_RECOVERY_CANCEL);
const AuthorizeTwoFactorRemovalByAdmin = requestActions(TwoFactorRecoveryActionTypes.AUTHORIZE_2FA_REMOVAL);
export const authorizeTwoFactorRemovalByAdmin = (adminToken, userCode) => dispatch => {
  dispatch(AuthorizeTwoFactorRemovalByAdmin.request());
  return TwoFactorRecoveryDao.authorizeAccountRecovery(adminToken, userCode).then(data => dispatch(AuthorizeTwoFactorRemovalByAdmin.success(data))).catch(error => {
    dispatch(AuthorizeTwoFactorRemovalByAdmin.failure(error));
  });
};
const VerifyAdminCanRemoveTwoFactor = requestActions(TwoFactorRecoveryActionTypes.VERIFY_2FA_REMOVAL);
export const verifyTwoFactorRemovalByAdmin = token => dispatch => {
  dispatch(VerifyAdminCanRemoveTwoFactor.request());
  return TwoFactorRecoveryDao.verifyAccountRecovery(token).then(data => dispatch(VerifyAdminCanRemoveTwoFactor.success(data))).catch(error => {
    dispatch(VerifyAdminCanRemoveTwoFactor.failure(error));
  });
};
const SetTwoFactorAdminRecoveryEligibility = requestActions(TwoFactorRecoveryActionTypes.SET_ADMIN_RECOVERY_ELIGIBILITY);
export const verifyTwoFactorAdminRecoveryEligibility = token => dispatch => {
  dispatch(SetTwoFactorAdminRecoveryEligibility.request());
  return TwoFactorRecoveryDao.checkIfEligibleForAdminRecovery(token).then(data => dispatch(SetTwoFactorAdminRecoveryEligibility.success(data))).catch(error => dispatch(SetTwoFactorAdminRecoveryEligibility.failure(error)));
};
const VerifyRecoveryToken = requestActions(TwoFactorRecoveryActionTypes.VERIFY_RECOVERY_TOKEN);
export const verifyRecoveryToken = token => dispatch => {
  dispatch(VerifyRecoveryToken.request());
  return TwoFactorRecoveryDao.checkRecoveryToken(token).then(() => dispatch(VerifyRecoveryToken.success())).catch(error => dispatch(VerifyRecoveryToken.failure(error)));
};
const SendTwoFactorSupportEmail = requestActions(TwoFactorRecoveryActionTypes.SEND_SUPPORT_EMAIL);
export const sendTwoFactorSupportEmail = (token, responses) => dispatch => {
  dispatch(SendTwoFactorSupportEmail.request());
  return TwoFactorRecoveryDao.sendSupportEmail(token, responses).then(() => dispatch(SendTwoFactorSupportEmail.success())).catch(error => dispatch(SendTwoFactorSupportEmail.failure(error)));
};
export const updateVerifyCompanyAndDomain = updateAction(TwoFactorRecoveryActionTypes.UPDATE_VERIFY_COMPANY_AND_DOMAIN);
export const updateVerifyLastLoginWhen = updateAction(TwoFactorRecoveryActionTypes.UPDATE_VERIFY_LAST_LOGIN_WHEN);
export const updateVerifyLastLoginWhere = updateAction(TwoFactorRecoveryActionTypes.UPDATE_VERIFY_LAST_LOGIN_WHERE);
export const updateVerifyPhoneNumber = updateAction(TwoFactorRecoveryActionTypes.UPDATE_VERIFY_PHONE_NUMBER);
export const updateVerifyUsualLoginHow = updateAction(TwoFactorRecoveryActionTypes.UPDATE_VERIFY_USUAL_LOGIN_HOW);
export const updateVerifyUsualLoginWhere = updateAction(TwoFactorRecoveryActionTypes.UPDATE_VERIFY_USUAL_LOGIN_WHERE);
export const updateRecoveryCode = updateAction(TwoFactorRecoveryActionTypes.UPDATE_RECOVERY_CODE);
export const hideInvalidRecoveryCodeAlert = updateAction(TwoFactorRecoveryActionTypes.HIDE_INVALID_RECOVERY_CODE);