import { SIGNUP_POPUP_BLOCKED_MESSAGE_TYPE } from '../constants/MessageTypes';
import { redirectWindowToUrl } from './redirectWindowToUrl';
export const installPopupBlockedRedirectTimer = ({
  signupRedirectUrl,
  popup,
  postMessageListeners
}) => {
  return setTimeout(() => {
    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      if (postMessageListeners.hasListeners(SIGNUP_POPUP_BLOCKED_MESSAGE_TYPE)) {
        postMessageListeners.getListeners(SIGNUP_POPUP_BLOCKED_MESSAGE_TYPE).forEach(({
          listener: popupBlockedCallback
        }) => {
          popupBlockedCallback();
        });
      } else {
        redirectWindowToUrl(signupRedirectUrl);
      }
    }
  }, 1000);
};