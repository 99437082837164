export let Language;
(function (Language) {
  Language[Language["de"] = 0] = "de";
  Language[Language["en"] = 1] = "en";
  Language[Language["es"] = 2] = "es";
  Language[Language["fr"] = 3] = "fr";
  Language[Language["ja"] = 4] = "ja";
  Language[Language["it"] = 5] = "it";
  Language[Language["nl"] = 6] = "nl";
  Language[Language["pt"] = 7] = "pt";
  Language[Language["ptBr"] = 8] = "ptBr";
  Language[Language["fi"] = 9] = "fi";
  Language[Language["pl"] = 10] = "pl";
  Language[Language["sv"] = 11] = "sv";
})(Language || (Language = {}));