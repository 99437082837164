'use es6';

import { ensureFn, once } from './helpers';
import { parseExperiments } from './parsers';
import { validateWithDefaults } from './validators';
import { createAsyncResolver } from './utils';
import { getCacheKeys, saveOverridesToCache } from './overrides';
import { getAsyncNetworkAdapter, wrapNetworkAdapterWithMeta } from './network';
import { resolveExposures as exposuresResolver } from '../resolvers/exposures';
import { resolveTreatments as treatmentsResolver } from '../resolvers/treatments';
import { DEBUG_MESSAGES } from '../messages';
function removeSchemaEntriesWithEmptyIdentifiers(logDebug, schema) {
  return Object.keys(schema).reduce((acc, key) => {
    const treatment = schema[key];
    if (treatment.identifier === null || treatment.identifier === undefined) {
      ensureFn(logDebug)(DEBUG_MESSAGES.treatmentIgnored(key), 'createTreatmentsResolver');
      return acc;
    }
    return Object.assign({}, acc, {
      [key]: treatment
    });
  }, {});
}
export function createTreatmentsResolver(args = {}) {
  const {
    apiDomain,
    asyncStore,
    ceid,
    http,
    onError,
    quickFetch,
    quickFetchLabel,
    treatments,
    experiments,
    identifiers,
    timeout,
    logDebug,
    mode
  } = args;

  // Removes all the schema entries that have empty identifiers
  // As they should not be sent to the server
  const schema = removeSchemaEntriesWithEmptyIdentifiers(logDebug,
  // if experiments (parameter) is present, parses them into a treatments schema
  // otherwise fallback to treatments, that if also not provided by default is an empty object
  experiments ? parseExperiments({
    experiments,
    identifiers
  }) : treatments);
  const cacheKeys = getCacheKeys({
    schema
  });
  const networkAdapter = getAsyncNetworkAdapter({
    apiDomain,
    ceid,
    http,
    quickFetch,
    quickFetchLabel,
    schema,
    identifiers,
    onError,
    timeout
  });
  const getAsyncData = wrapNetworkAdapterWithMeta({
    networkAdapter,
    asyncStore,
    cacheKeys,
    ceid,
    schema,
    onError,
    logDebug,
    quickFetchLabel,
    mode
  });
  const asyncTreatmentsResolver = createAsyncResolver({
    getAsyncData,
    resolve: once(treatmentsResolver),
    onError
  });
  const updateTreatmentsToCache = once(resolvedTreatments => saveOverridesToCache({
    schema,
    treatments: resolvedTreatments,
    asyncStore,
    onError
  }));
  return {
    resolveTreatments: callback => {
      asyncTreatmentsResolver(resolvedTreatments => {
        updateTreatmentsToCache(resolvedTreatments);
        ensureFn(callback)(resolvedTreatments);
      });
    }
  };
}
export function createExposureResolver({
  resolveTreatments,
  onError
} = {}) {
  const validators = validateWithDefaults(onError, 'createExposureResolver');
  const resolveExposableTreatments = (treatmentKeys, callback) => resolveTreatments(treatments => {
    // If `treatmentKeys` is null, undefined or falsey then we prefer to resolve all treatments
    // This is a feature as if the user doesn't provide a list of treatments to check if can be exposed
    // We fallback to all possible available treatments
    const treatmentsToExpose = treatmentKeys || Object.keys(treatments);
    ensureFn(callback)(exposuresResolver({
      treatmentKeys: treatmentsToExpose,
      treatments
    }));
  });
  return {
    resolveExposableTreatments: (treatmentKeys, onExposuresResolved) => {
      const result = treatmentKeys ?
      // We only validate the treatmentKeys if the value is provided, as the treatmentKeys are an optiona field
      // And by default we will resolve exposures of all available treatments if this list is not provided
      validators.validateExposures(treatmentKeys) : {
        treatmentKeys: undefined
      };
      resolveExposableTreatments(result.treatmentKeys, onExposuresResolved);
    }
  };
}