import { createClient } from 'laboratory-lib';
import experiments from '../../experiments.yaml';
import { getDeviceId } from 'usage-tracker-core/common/defaultTrackers';
import anonymousStorage from 'usage-tracker-core/containers/anonymousStorage';
const laboratoryClient = createClient({
  identifiers: {
    other: getDeviceId(anonymousStorage)
  },
  experiments: experiments,
  timeout: 3000
});
export default laboratoryClient;