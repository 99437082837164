export class SignupPostMessageListeners {
  constructor() {
    this.listeners = [];
  }
  /**
   * Pushes a new listener for a given message type.
   *
   * @param {string} type
   * @param {function} listener
   * @memberof SignupEmbedderListeners
   */
  push(type, listener) {
    if (typeof listener !== 'function') {
      throw new Error('The listener argument must be a Function instance.');
    }
    this.listeners.push({
      type,
      listener
    });
  }

  /**
   * Returns a list of listeners for a given type.
   *
   * @param {string} type
   * @returns {Array.<{ type: string, listener: function }>}
   * @memberof SignupEmbedderListeners
   */
  getListeners(type) {
    if (!type) {
      return this.listeners;
    }
    return this.listeners.filter(({
      type: listenerType
    }) => listenerType === type);
  }

  /**
   * Checks if we have listeners registered for a given type.
   *
   * @param {string} type
   * @returns {Boolean} True if a listener is found. False otherwise.
   * @memberof SignupPostMessageListeners
   */
  hasListeners(type) {
    return this.listeners.some(({
      type: listenerType
    }) => listenerType === type);
  }
}