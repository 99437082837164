'use es6';

import * as api from './api';
import { adaptExposuresForApi } from '../resolvers/exposures';
import { ensureFn, filterObject } from './helpers';
export function logError(...err) {
  ensureFn(console.error)(...err);
}
export function logWarning(...warn) {
  ensureFn(console.warn)(...warn);
}
export function createDebugLogger({
  debug
}) {
  const debugLogger = (message = '', caller = 'createDebugLogger') => {
    if (typeof message === 'function') {
      return console.debug(`[laboratory-lib "${caller}"] ${message()}`);
    }
    if (typeof message === 'object') {
      return console.debug(`[laboratory-lib "${caller}"]`, message);
    }
    return console.debug(`[laboratory-lib "${caller}"] ${message}`);
  };
  return {
    logDebug: ensureFn(debug && debugLogger)
  };
}
export function createExposureLogger({
  apiDomain,
  http,
  identifiers,
  resolveTreatments,
  onError,
  logDebug
}) {
  const onErrorWithExtra = e => {
    ensureFn(onError)(e, {
      fingerprint: ['laboratory-lib', 'lab:createExposureLogger'],
      tags: {
        'laboratory-lib:error': 'exposures'
      }
    });
  };
  const logExposures = treatmentKeys => {
    return resolveTreatments(treatments => {
      if (treatmentKeys && Array.isArray(treatmentKeys) && treatmentKeys.length > 0) {
        // Get only the treatments that are in the treatmentKeys array
        treatments = Object.fromEntries(Object.entries(treatments).filter(([key]) => treatmentKeys.includes(key)));
      }
      if (Object.keys(treatments).length === 0) {
        return;
      }
      api.logExposures({
        apiDomain,
        http,
        logDebug,
        onError: onErrorWithExtra,
        query: filterObject(Object.assign({}, identifiers)),
        data: {
          exposures: adaptExposuresForApi(treatments),
          identifiers
        }
      });
    });
  };
  return {
    logExposures,
    logExposure: treatmentKey =>
    // This method should not be called without arguments or with a falsy value
    // But to prevent edge scenarios, we then pass an empty array preventing any behaviour
    logExposures(treatmentKey ? [treatmentKey] : [])
  };
}