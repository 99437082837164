import { createGlobalStyle } from 'styled-components';
import { useIsKeyboardMode } from './KeyboardModeProvider';
export const GlobalStyles = createGlobalStyle(({
  theme: _theme
}) => {
  const theme = _theme;
  const isKeyboardMode = useIsKeyboardMode();
  return {
    '*': {
      boxSizing: 'border-box'
    },
    '*::before': {
      boxSizing: 'border-box'
    },
    '*::after': {
      boxSizing: 'border-box'
    },
    /*
     * Removes default focus styles for non-input elements.
     * This is the replacement for the `hubspot-disable-focus-styles` CSS class in UIComponents.
     */
    '*:focus:not(input):not(textarea):not(.keep-default-focus-styles)': {
      outline: isKeyboardMode ? undefined : '0'
    },
    body: Object.assign({}, theme.typography['body-100'], {
      color: theme.color['text-primary-default']
    })
  };
});