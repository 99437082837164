'use es6';

import { defaults } from '../util/Objects';
import Dom from '../util/Dom';
const NO_MARGIN_CLASS = 'fire-alarm_no-nav-margin-bottom';
const dom = new Dom(document);
class AlarmContainer {
  constructor(config = {}) {
    this.config = defaults(config, {
      after: null,
      additionalClasses: [],
      before: null,
      parent: null
    });
    this.alarmElements = [];
  }
  addAlarm(alarm) {
    if (!alarm.isDismissed()) {
      const alarmElement = alarm.getDomElement();
      if (this.alarmElements.indexOf(alarmElement) === -1) {
        this.alarmElements.push(alarmElement);
        this.getDomElement().appendChild(alarmElement);
      }
    }
  }
  removeAllAlarms() {
    if (this.domElement) {
      while (this.domElement.firstChild) {
        this.domElement.removeChild(this.domElement.firstChild);
      }
      this.alarmElements = [];
    }
  }
  getDomElement() {
    if (this.domElement == null) {
      const additionalClasses = this.config.additionalClasses.join(' ');
      this.domElement = dom.createElement('div', {
        class: `fire-alarm_alarms ${additionalClasses}`
      });
    }
    return this.domElement;
  }
  addNoMarginClass() {
    if (!this.addedNoMarginClass) {
      this.getDomElement().classList.add(NO_MARGIN_CLASS);
      this.addedNoMarginClass = true;
    }
  }
  getParent() {
    const {
      after,
      before,
      parent
    } = this.config;
    if (!parent) {
      if (after) {
        this.config.parent = after.parentNode;
      } else if (before) {
        this.config.parent = before.parentNode;
      }
    }
    return this.config.parent;
  }
  render() {
    const {
      after,
      before
    } = this.config;
    const parent = this.getParent();
    if (parent) {
      const container = this.getDomElement();
      if (after) {
        const afterBottomMargin = window.getComputedStyle(after).marginBottom;
        // Catch if the margin is 0, '0px' or undefined
        if (!parseInt(afterBottomMargin, 10)) {
          this.addNoMarginClass();
        }
        parent.insertBefore(container, after.nextSibling);
      } else if (before) {
        parent.insertBefore(container, before);
      } else {
        parent.appendChild(container);
      }
    }
  }
}
export default AlarmContainer;