import Url from 'urlinator/Url';
import http from 'hub-http/clients/noAuthApiClient';
import BaseLoginDao from './BaseLoginDao';
// @ts-expect-error no types from package
import HubSpotOnlyAppCookieLogin from 'Nabisco/app/HubSpotOnlyAppCookieLogin';
const appCookieLogin = new HubSpotOnlyAppCookieLogin({
  passThroughFallback: true
});
const URL_LOADED_LOCAL = Url.loaded.local;
class GoogleLoginDao extends BaseLoginDao {
  initialize(credentials) {
    credentials.redirectToLocal = URL_LOADED_LOCAL;
    return this.loginAtPath('/login-api/v1/google/init', credentials);
  }
  passCredentialResponse(response) {
    return http.post('/login-api/v1/google/one-tap', {
      data: response
    }).then(result => {
      if (!result.status) {
        return appCookieLogin.collectLoginResponses(result); //sets cookies and returns the response wrapped in an array
      } else {
        return [result]; // collectLoginResponses returns an array
      }
    });
  }
}
export default GoogleLoginDao;