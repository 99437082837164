/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-storage */

'use es6';

import Url from 'urlinator/Url';
import SafeStorage from 'SafeStorage';
import Cookies from './cookies';
import CookieSetError from './errors/CookieSetError';
import RedirectError from './errors/RedirectError';
const VISIT_MARKER_TEMPLATE = 'visited.${lb}.at';
const CSRF_KEY_TEMPLATE = 'csrf.${lb}';
const URL_LOADED = Url.loaded;
let IS_SAFARI;
export const _resetIsSafari = () => IS_SAFARI = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS');
_resetIsSafari();
class BaseCookieLogin {
  constructor(cookieLoadBalancers, loginRequest, prepRequest, visitPath, currentDomain = window.location.hostname, redirectTo = window.location.assign.bind(window.location)) {
    this.cookieLoadBalancers = cookieLoadBalancers;
    this.prepRequest = prepRequest;
    this.loginRequest = loginRequest;
    this.visitPath = visitPath;
    this.currentDomain = `.${currentDomain.split('.').slice(-2).join('.')}`;
    this.redirectTo = redirectTo;
    this.domainPreps = this.domainPreps.bind(this);
    this.collectDomainPreps = this.collectDomainPreps.bind(this);
    this.collectLoginResponses = this.collectLoginResponses.bind(this);
  }
  loginPrep() {
    if (!this.loginPrepPromise) {
      if (IS_SAFARI && this.cookieLoadBalancers.length > 1) {
        this.loginPrepPromise = Promise.all(this.domainPreps()).then(preps => this.collectDomainPreps(...preps)).catch(error => {
          console.error(error);
          if (error.name === 'RedirectError') {
            throw error;
          }
        });
      } else {
        this.loginPrepPromise = Promise.resolve();
      }
    }
    return this.loginPrepPromise;
  }
  domainPreps() {
    return this.cookieLoadBalancers.map(this.prepRequest);
  }
  collectDomainPreps(...domainPreps) {
    const redirectLoadBalancers = [];
    domainPreps.forEach(({
      visited
    }, i) => {
      const loadBalancer = this.cookieLoadBalancers[i];
      const marker = VISIT_MARKER_TEMPLATE.replace('${lb}', loadBalancer);
      if (visited) {
        SafeStorage.removeItem(marker);
      } else {
        const maybeMarkerTimestamp = SafeStorage.getItem(marker);
        const markerSetAt = maybeMarkerTimestamp != null ? +maybeMarkerTimestamp : 0;
        const now = new Date().getTime();
        if (now - markerSetAt < 120000) {
          SafeStorage.removeItem(marker);
          throw new CookieSetError();
        }
        redirectLoadBalancers.push(loadBalancer);
        SafeStorage.setItem(marker, now);
      }
    });
    this.visitUnvisited(redirectLoadBalancers);
  }
  visitUnvisited(loadBalancers) {
    if (loadBalancers.length) {
      let visitUrl = URL_LOADED.clone().href;
      loadBalancers.forEach(loadBalancer => {
        visitUrl = new Url(this.visitPath, {
          lb: loadBalancer,
          params: {
            redirect: decodeURIComponent(visitUrl)
          }
        }).href;
      });
      this.redirectTo(visitUrl);
      throw new RedirectError();
    }
  }
  login(credentials) {
    return this.loginPrep().then(() => Promise.all(this.loginRequests(credentials)).then(responses => this.collectLoginResponses(...responses))).catch(response => {
      try {
        response.responseJSON = response.responseJSON || JSON.parse(response.responseText);
      } catch (ex) {
        console.error(`Unable to parse error response JSON "${response.responseText}"`);
      }
      throw response;
    });
  }
  loginRequests(credentials) {
    return this.cookieLoadBalancers.map(loadBalancer => this.loginRequest(credentials, loadBalancer));
  }
  collectLoginResponses(...loginResponses) {
    loginResponses.forEach(({
      csrfToken,
      setSameSiteNone
    }, i) => {
      const csrfKey = CSRF_KEY_TEMPLATE.replace('${lb}', this.cookieLoadBalancers[i]);
      const sameSite = setSameSiteNone ? 'None' : null;
      Cookies.set(csrfKey, csrfToken, {
        path: '/',
        secure: true,
        expires: 365,
        domain: this.currentDomain,
        SameSite: sameSite
      });
    });
    return loginResponses;
  }
  ignoreRedirectWrapper(failureHandler) {
    return response => {
      if (response.name !== 'RedirectError') {
        failureHandler(response);
      }
    };
  }
}
export default BaseCookieLogin;