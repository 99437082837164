// THIS FILE IS AUTOMATICALLY GENERATED. DO NOT MODIFY IT MANUALLY.

/**
 * Design System theme object.
 *
 * Theme name: `next`
 *
 * Theme mode: `dark`
 */
export default {
  color: {
    'fill-surface-default': '#141414',
    'fill-surface-default-hover': '#242424',
    'fill-surface-default-pressed': '#292929',
    'fill-surface-recessed': '#141414',
    'fill-surface-raised': '#292929',
    'fill-surface-overlay': '#333333',
    'fill-primary-default': '#7D53E9',
    'fill-primary-hover': '#6431DA',
    'fill-primary-pressed': '#5113BA',
    'fill-primary-subtle': '#1B0C3B',
    'fill-secondary-default': '#EFEDEB',
    'fill-secondary-hover': '#CFCCCB',
    'fill-secondary-pressed': '#B6B1AF',
    'fill-secondary-subtle': '#171717',
    'fill-tertiary-default': '#FFFFFF',
    'fill-tertiary-hover': '#292929',
    'fill-tertiary-pressed': '#333333',
    'fill-tertiary-subtle': '#1F1F1F',
    'fill-brand-default': '#FF4800',
    'fill-brand-hover': '#FF7D4C',
    'fill-brand-pressed': '#FFA581',
    'fill-brand-subtle': '#241005',
    'fill-positive-default': '#008022',
    'fill-positive-hover': '#00641A',
    'fill-positive-pressed': '#004C12',
    'fill-positive-subtle': '#071905',
    'fill-positive-alt': '#38D84C',
    'fill-caution-default': '#EEB117',
    'fill-caution-hover': '#D39913',
    'fill-caution-pressed': '#B67E0E',
    'fill-caution-subtle': '#1F1306',
    'fill-caution-alt': '#FCCB57',
    'fill-alert-default': '#D9002B',
    'fill-alert-hover': '#AC0020',
    'fill-alert-pressed': '#850016',
    'fill-alert-subtle': '#290C05',
    'fill-info-default': '#016DE1',
    'fill-info-hover': '#0050C7',
    'fill-info-pressed': '#0035B2',
    'fill-info-subtle': '#00133F',
    'fill-accent-purple-default': '#7D53E9',
    'fill-accent-purple-hover': '#6431DA',
    'fill-accent-purple-pressed': '#5113BA',
    'fill-accent-purple-subtle': '#1B0C3B',
    'fill-accent-orange-default': '#FF4800',
    'fill-accent-orange-hover': '#FF7D4C',
    'fill-accent-orange-pressed': '#FFA581',
    'fill-accent-orange-subtle': '#241005',
    'fill-accent-green-default': '#008022',
    'fill-accent-green-hover': '#00641A',
    'fill-accent-green-pressed': '#004C12',
    'fill-accent-green-subtle': '#071905',
    'fill-accent-yellow-default': '#EEB117',
    'fill-accent-yellow-hover': '#D39913',
    'fill-accent-yellow-pressed': '#B67E0E',
    'fill-accent-yellow-subtle': '#1F1306',
    'fill-accent-red-default': '#D9002B',
    'fill-accent-red-hover': '#AC0020',
    'fill-accent-red-pressed': '#850016',
    'fill-accent-red-subtle': '#290C05',
    'fill-accent-blue-default': '#016DE1',
    'fill-accent-blue-hover': '#0050C7',
    'fill-accent-blue-pressed': '#0035B2',
    'fill-accent-blue-subtle': '#00133F',
    'fill-accent-teal-default': '#007C7D',
    'fill-accent-teal-hover': '#006162',
    'fill-accent-teal-pressed': '#00494B',
    'fill-accent-teal-subtle': '#051919',
    'fill-accent-magenta-default': '#D20688',
    'fill-accent-magenta-hover': '#A5016A',
    'fill-accent-magenta-pressed': '#800051',
    'fill-accent-magenta-subtle': '#2D061B',
    'fill-transparent-default': 'rgba(0,0,0,0)',
    'text-primary-default': '#E7E5E4',
    'text-primary-subtle': '#B6B1AF',
    'text-primary-disabled': '#FFFFFF',
    'text-primary-on-fill-default': '#1F1F1F',
    'text-primary-on-fill-subtle': '#B6B1AF',
    'text-interactive-default': '#AC98F8',
    'text-interactive-on-fill-default': '#FFFFFF',
    'text-interactive-on-fill-subtle': '#C4B4F7',
    'text-brand-default': '#FF7D4C',
    'text-brand-on-fill-default': '#411204',
    'text-brand-on-fill-subtle': '#FFA581',
    'text-positive-default': '#27BD3E',
    'text-positive-on-fill-default': '#FFFFFF',
    'text-positive-on-fill-subtle': '#38D84C',
    'text-positive-on-fill-alt': '#042905',
    'text-caution-default': '#D39913',
    'text-caution-on-fill-default': '#1F1F1F',
    'text-caution-on-fill-subtle': '#EEB117',
    'text-caution-on-fill-alt': '#331E06',
    'text-alert-default': '#FF7B70',
    'text-alert-on-fill-default': '#FFFFFF',
    'text-alert-on-fill-subtle': '#FF7B70',
    'text-info-default': '#5CAAFF',
    'text-info-on-fill-default': '#FFFFFF',
    'text-info-on-fill-subtle': '#8CC4F4',
    'text-accent-green-default': '#27BD3E',
    'text-accent-green-on-fill-default': '#FFFFFF',
    'text-accent-green-on-fill-subtle': '#38D84C',
    'text-accent-yellow-default': '#D39913',
    'text-accent-yellow-on-fill-default': '#FFFFFF',
    'text-accent-yellow-on-fill-subtle': '#EEB117',
    'text-accent-red-default': '#FF7B70',
    'text-accent-red-on-fill-default': '#FFFFFF',
    'text-accent-red-on-fill-subtle': '#FFA499',
    'text-accent-blue-default': '#5CAAFF',
    'text-accent-blue-on-fill-default': '#FFFFFF',
    'text-accent-blue-on-fill-subtle': '#8CC4F4',
    'text-accent-teal-default': '#21B7B7',
    'text-accent-teal-on-fill-default': '#FFFFFF',
    'text-accent-teal-on-fill-subtle': '#48D1CF',
    'text-accent-magenta-default': '#FF72BA',
    'text-accent-magenta-on-fill-default': '#FFFFFF',
    'text-accent-magenta-on-fill-subtle': '#FF9FCC',
    'text-accent-purple-default': '#AC98F8',
    'text-accent-purple-on-fill-default': '#FFFFFF',
    'text-accent-purple-on-fill-subtle': '#C4B4F7',
    'icon-primary-default': '#CFCCCB',
    'icon-primary-on-fill-default': '#1F1F1F',
    'icon-primary-on-fill-subtle': '#B6B1AF',
    'icon-interactive-default': '#C4B4F7',
    'icon-interactive-on-fill-default': '#FFFFFF',
    'icon-interactive-on-fill-subtle': '#C4B4F7',
    'icon-brand-default': '#FFA581',
    'icon-brand-on-fill-default': '#411204',
    'icon-brand-on-fill-subtle': '#FFA581',
    'icon-positive-default': '#38D84C',
    'icon-positive-on-fill-default': '#FFFFFF',
    'icon-positive-on-fill-subtle': '#38D84C',
    'icon-positive-on-fill-alt': '#042905',
    'icon-caution-default': '#EEB117',
    'icon-caution-on-fill-default': '#1F1F1F',
    'icon-caution-on-fill-subtle': '#EEB117',
    'icon-caution-on-fill-alt': '#331E06',
    'icon-alert-default': '#FF3842',
    'icon-alert-on-fill-default': '#FFFFFF',
    'icon-alert-on-fill-subtle': '#FF7B70',
    'icon-info-default': '#8CC4F4',
    'icon-info-on-fill-default': '#FFFFFF',
    'icon-info-on-fill-subtle': '#8CC4F4',
    'icon-accent-green-default': '#38D84C',
    'icon-accent-green-on-fill-default': '#FFFFFF',
    'icon-accent-green-on-fill-subtle': '#38D84C',
    'icon-accent-yellow-default': '#EEB117',
    'icon-accent-yellow-on-fill-default': '#1F1F1F',
    'icon-accent-yellow-on-fill-subtle': '#EEB117',
    'icon-accent-red-default': '#FF3842',
    'icon-accent-red-on-fill-default': '#FFFFFF',
    'icon-accent-red-on-fill-subtle': '#FF7B70',
    'icon-accent-blue-default': '#8CC4F4',
    'icon-accent-blue-on-fill-default': '#FFFFFF',
    'icon-accent-blue-on-fill-subtle': '#8CC4F4',
    'icon-accent-teal-default': '#48D1CF',
    'icon-accent-teal-on-fill-default': '#FFFFFF',
    'icon-accent-teal-on-fill-subtle': '#48D1CF',
    'icon-accent-magenta-default': '#FF9FCC',
    'icon-accent-magenta-on-fill-default': '#FFFFFF',
    'icon-accent-magenta-on-fill-subtle': '#FF9FCC',
    'icon-accent-purple-default': '#C4B4F7',
    'icon-accent-purple-on-fill-default': '#FFFFFF',
    'icon-accent-purple-on-fill-subtle': '#C4B4F7',
    'icon-accent-orange-default': '#FFA581',
    'icon-accent-orange-on-fill-default': '#411204',
    'icon-accent-orange-on-fill-subtle': '#FFA581',
    'border-primary-default': '#9B9897',
    'border-primary-subtle-default': '#FFFFFF',
    'border-primary-subtle-hover': '#FFFFFF',
    'border-primary-subtle-pressed': '#E7E5E4',
    'border-primary-on-fill-default': '#FFFFFF',
    'border-interactive-default': '#AC98F8',
    'border-brand-default': '#FF7D4C',
    'border-positive-default': '#219F34',
    'border-caution-default': '#B67E0E',
    'border-alert-default': '#FF3842',
    'border-info-default': '#078CFD',
    'border-surface-default': '#FFFFFF',
    'specialty-notification': '#D9002B',
    'specialty-scrim': '#000000',
    'fill-tertiary-alt': '#CBD6E2',
    'fill-accent-light-orange-default': '#FF8F59',
    'fill-accent-light-orange-hover': '#FFC7AC',
    'fill-accent-light-orange-pressed': '#E68250',
    'fill-accent-light-orange-subtle': '#FFF3EE',
    'fill-accent-neutral-default': '#425B76',
    'fill-accent-neutral-hover': '#516F90',
    'fill-accent-neutral-pressed': '#33475B',
    'fill-accent-neutral-subtle': '#425B76',
    'text-interactive-default-hover': '#007A8C',
    'text-interactive-default-pressed': '#7FD1DE',
    'text-interactive-default-disabled': '#99ACC2',
    'text-interactive-on-fill': '#ffffff',
    'text-alert-hover': '#F8A9AD',
    'text-alert-pressed': '#D94C53',
    'text-alert-on-fill': '#ffffff',
    'icon-primary-hover': '#7C98B6',
    'icon-primary-pressed': '#33475B',
    'icon-interactive-hover': '#007A8C',
    'icon-interactive-pressed': '#7FD1DE',
    'icon-alert-hover': '#F8A9AD',
    'icon-alert-pressed': '#D94C53',
    'border-primary-subtle': '#DFE3EB',
    'border-primary-disabled': '#EAF0F6',
    'border-primary-on-fill': '#ffffff',
    'border-interactive-pressed': '#0091AE'
  },
  space: {
    '0': '0px',
    '25': '1px',
    '50': '2px',
    '100': '4px',
    '200': '8px',
    '300': '12px',
    '400': '16px',
    '500': '20px',
    '600': '24px',
    '700': '28px',
    '800': '32px',
    '900': '36px',
    '1000': '40px',
    '1100': '48px',
    '1200': '56px',
    '1300': '60px',
    '1400': '64px',
    '1500': '96px',
    '1600': '144px',
    '1700': '192px'
  },
  size: {
    'icon-100': '14px',
    'icon-200': '16px',
    'icon-300': '20px',
    'icon-400': '24px',
    'icon-500': '20px',
    'icon-600': '24px',
    'icon-700': '28px',
    'icon-800': '32px'
  },
  borderRadius: {
    '0': '0px',
    '100': '4px',
    '200': '8px',
    '300': '16px'
  },
  borderWidth: {
    '0': '0px',
    '100': '1px',
    '200': '2px',
    '300': '4px'
  },
  boxShadow: {
    '100': '0 1px 0 0 rgba(0,0,0,0.24)',
    '200': '0 8px 16px 0 rgba(0,0,0,0.16)',
    '300': '0 16px 32px 0 rgba(0,0,0,0.24)',
    '400': '0 24px 48px 0 rgba(0,0,0,0.24)'
  },
  typography: {
    'display-100': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '56px',
      lineHeight: '72px',
      letterSpacing: '-0.32px'
    },
    'display-200': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      lineHeight: '76px',
      fontSize: '66px',
      letterSpacing: '-0.32px'
    },
    'display-300': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '76px',
      lineHeight: '88px',
      letterSpacing: '-0.32px'
    },
    'display-400': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '84px',
      lineHeight: '96px',
      letterSpacing: '-0.32px'
    },
    'display-500': {
      fontFamily: 'Lexend Deca',
      fontWeight: '500',
      fontSize: '94px',
      lineHeight: '100px',
      letterSpacing: '-0.32px'
    },
    'heading-25': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0px'
    },
    'heading-50': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0px'
    },
    'heading-100': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'heading-200': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'heading-300': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '26px',
      letterSpacing: '0px'
    },
    'heading-400': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '22px',
      lineHeight: '28px',
      letterSpacing: '0px'
    },
    'heading-500': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '26px',
      lineHeight: '32px',
      letterSpacing: '0px'
    },
    'heading-600': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '-0.16px'
    },
    'heading-700': {
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: '500',
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      letterSpacing: '-0.16px'
    },
    'heading-800': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '42px',
      lineHeight: '52px',
      letterSpacing: '-0.16px'
    },
    'heading-900': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '46px',
      lineHeight: '56px',
      letterSpacing: '-0.16px'
    },
    'heading-1000': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '500',
      fontSize: '52px',
      lineHeight: '64px',
      letterSpacing: '-0.32px'
    },
    'body-100': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px'
    },
    'body-125': {
      fontFamily: 'Lexend Deca',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px'
    },
    'body-200': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'body-300': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0px'
    },
    'body-400': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0px'
    },
    'body-500': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '22px',
      lineHeight: '28px',
      letterSpacing: '0px'
    },
    'body-600': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '26px',
      lineHeight: '32px',
      letterSpacing: '0px'
    },
    'body-700': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      fontSize: '28px',
      lineHeight: '36px',
      letterSpacing: '0px'
    },
    'detail-100': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '350',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0px'
    },
    'detail-200': {
      fontFamily: 'Lexend Deca, Helvetica, Arial, sans-serif',
      fontWeight: '350',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px'
    },
    'code-100': {
      fontFamily: 'Source Code Pro, Consolas, Monaco, Courier New, monospace',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0px'
    }
  }
};