export const RequestStates = {
  UNATTEMPTED: 'UNATTEMPTED',
  REQUESTING: 'REQUESTING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  FAILED_EXPIRED: 'FAILED_EXPIRED',
  FAILED_NEEDS_SUPPORT: 'FAILED_NEEDS_SUPPORT',
  FAILED_ON_LOAD: 'FAILED_ON_LOAD'
};
export const requestWasAttempted = state => state === RequestStates.FAILED || state === RequestStates.FAILED_EXPIRED || state === RequestStates.SUCCEEDED;
export const requestIsRequesting = state => state === RequestStates.REQUESTING;
export const requestSucceeded = state => state === RequestStates.SUCCEEDED;
export const requestFailed = state => state === RequestStates.FAILED;
export const requestFailedExpired = state => state === RequestStates.FAILED_EXPIRED;
export const requestFailedNeedsSupport = state => state === RequestStates.FAILED_NEEDS_SUPPORT;