import httpNoAuth from 'hub-http/clients/noAuthApiClient';
import http from 'hub-http/clients/apiClient';
class TwoFactorRecoveryDao {
  static sendRecoveryEmail(token) {
    return httpNoAuth.post('/two-factor/v1/recovery/email-sends', {
      data: {
        token
      }
    });
  }
  static resendRecoveryEmail(token) {
    return httpNoAuth.post(`/two-factor/v1/recovery/${token}/email-resends`);
  }
  static sendAdminRecoveryEmail(token) {
    return httpNoAuth.post('/two-factor/v1/admin-recovery/initiate', {
      data: {
        token
      }
    });
  }
  static checkIfEligibleForAdminRecovery(token) {
    return httpNoAuth.get(`/two-factor/v1/admin-recovery/eligible/${token}`);
  }
  static authorizeAccountRecovery(token, userCode) {
    return http.post('/two-factor/v1/admin-recovery/recover', {
      data: {
        token,
        userCode
      }
    });
  }
  static verifyAccountRecovery(token) {
    return http.get(`/two-factor/v1/admin-recovery/recover/verification/${token}`);
  }
  static checkRecoveryToken(token) {
    return httpNoAuth.get(`/two-factor/v1/recovery/tokens/${token}/verification`);
  }
  static sendSupportEmail(recoveryToken, formResponses) {
    return httpNoAuth.post('/two-factor/v1/recovery/contact-support', {
      data: {
        recoveryToken,
        formResponses
      }
    });
  }
  static initNameTagRecovery(recoveryToken) {
    return httpNoAuth.post(`/two-factor/v1/nametag/init`, {
      data: {
        twoFactorToken: recoveryToken
      }
    });
  }
  static nameTagPolling(recoveryToken) {
    return httpNoAuth.get(`/two-factor/v1/nametag/poll/${recoveryToken}`);
  }
}
export default TwoFactorRecoveryDao;