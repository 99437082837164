const selectSessionInvalidationWarning = authFailureReason => {
  if (!authFailureReason) {
    return undefined;
  }
  switch (authFailureReason) {
    case 'Session timed out':
      return 'INACTIVE_SESSION_TIMED_OUT';
      break;
    case 'two_factor_recovered':
      return 'COMPLETE_2FA_RECOVERY';
      break;
    default:
      return 'SESSION_TIMED_OUT';
      break;
  }
};
export default selectSessionInvalidationWarning;