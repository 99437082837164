export const HANDSHAKE_MESSAGE_TYPE = 'HANDSHAKE_MESSAGE_TYPE';
export const ACKNOWLEDGE_MESSAGE_TYPE = 'ACKNOWLEDGE_MESSAGE_TYPE';
export const COOKIE_CONSENT_MESSAGE_TYPE = 'COOKIE_CONSENT_MESSAGE_TYPE';
export const SIGNUP_STARTED_MESSAGE_TYPE = 'SIGNUP_STARTED_MESSAGE_TYPE';
export const SIGNUP_REDIRECT_MESSAGE_TYPE = 'SIGNUP_REDIRECT_MESSAGE_TYPE';
export const GOOGLE_AUTH_VERIFIED_NEW_USER_MESSAGE_TYPE = 'GOOGLE_AUTH_VERIFIED_NEW_USER_MESSAGE_TYPE';
export const GOOGLE_AUTH_VERIFIED_NEW_USER_PORTAL_CREATED_MESSAGE_TYPE = 'GOOGLE_AUTH_VERIFIED_NEW_USER_PORTAL_CREATED_MESSAGE_TYPE';
export const GOOGLE_AUTH_VERIFIED_EXISTING_USER_PORTAL_CREATED_MESSAGE_TYPE = 'GOOGLE_AUTH_VERIFIED_EXISTING_USER_PORTAL_CREATED';
export const GOOGLE_AUTH_VERIFIED_EXISTING_USER_MESSAGE_TYPE = 'GOOGLE_AUTH_VERIFIED_EXISTING_USER';
export const SIGNUP_INITIAL_FLOW_MESSAGE_TYPE = 'SIGNUP_INITIAL_FLOW_MESSAGE_TYPE';
export const SIGNUP_FLOW_NAVIGATION_MESSAGE_TYPE = 'SIGNUP_FLOW_NAVIGATION_MESSAGE_TYPE';
export const SIGNUP_DATA_MESSAGE_TYPE = 'SIGNUP_DATA_MESSAGE_TYPE';
export const SIGNUP_POPUP_BLOCKED_MESSAGE_TYPE = 'SIGNUP_POPUP_BLOCKED_MESSAGE_TYPE';
export const LAUNCH_LOGIN_POPUP_MESSAGE_TYPE = 'LAUNCH_LOGIN_POPUP_MESSAGE_TYPE';