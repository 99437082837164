// @ts-expect-error no types from library
import Cookies from 'js-cookie';
import * as LoginUtils from '../lib/LoginUtils';
import { OUTLOOK365 } from '../constants/LoginSources';
import http from 'hub-http/clients/noAuthApiClient';
const REMEMBER_DEFAULT = Cookies.get('prefs') === '1';
class BaseLoginDao {
  loginAtPath(urlPath, credentials) {
    const source = LoginUtils.getLoginSource();
    if (source === OUTLOOK365) {
      credentials.rememberLogin = true;
    }
    credentials.rememberLogin = credentials.rememberLogin !== undefined ? credentials.rememberLogin : REMEMBER_DEFAULT;
    return http.post(urlPath, {
      data: credentials
    });
  }
}
export default BaseLoginDao;