import TwoFactorAuthActionTypes from './TwoFactorAuthActionTypes';
const startVerifying = () => ({
  type: TwoFactorAuthActionTypes.START_VERIFYING
});
const finishVerifying = () => ({
  type: TwoFactorAuthActionTypes.FINISH_VERIFYING
});
const startRequestingCode = () => ({
  type: TwoFactorAuthActionTypes.START_REQUESTING_CODE
});
const finishRequestingCode = () => ({
  type: TwoFactorAuthActionTypes.FINISH_REQUESTING_CODE
});
const startRequestingTwoFactorCall = () => ({
  type: TwoFactorAuthActionTypes.START_REQUESTING_CODE_CALL
});
const finishRequestingTwoFactorCall = () => ({
  type: TwoFactorAuthActionTypes.FINISH_REQUESTING_CODE_CALL
});
const updateTotp = totp => ({
  type: TwoFactorAuthActionTypes.UPDATE_TOTP,
  payload: {
    totp
  }
});
const setTwoFactorMethod = (token, primary, secondary, backupCodesEnabled) => ({
  type: TwoFactorAuthActionTypes.SET_TWO_FACTOR_METHOD,
  payload: {
    token,
    primary,
    secondary,
    backupCodesEnabled
  }
});
const switchTwoFactorMethod = () => ({
  type: TwoFactorAuthActionTypes.SWITCH_TWO_FACTOR_METHOD,
  payload: {}
});
const setCredentials = credentials => ({
  type: TwoFactorAuthActionTypes.SET_CREDENTIALS,
  payload: {
    credentials
  }
});
const setToken = token => ({
  type: TwoFactorAuthActionTypes.SET_TOKEN,
  payload: Object.assign({}, token)
});
const showTokenExpiredAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_TOKEN_EXPIRED
});
const hideTokenExpiredAlert = () => ({
  type: TwoFactorAuthActionTypes.HIDE_TOKEN_EXPIRED
});
const showNewCodeSentAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_NEW_CODE_SENT
});
const showCodeCallAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_CODE_CALL_ALERT
});
const hideNewCodeSentAlert = () => ({
  type: TwoFactorAuthActionTypes.HIDE_NEW_CODE_SENT
});
const hideCodeCallAlert = () => ({
  type: TwoFactorAuthActionTypes.HIDE_CODE_CALL_ALERT
});
const showInvalidCodeAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_INVALID_CODE
});
const hideInvalidCodeAlert = () => ({
  type: TwoFactorAuthActionTypes.HIDE_INVALID_CODE
});
const showInvalidTokenAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_INVALID_TOKEN
});
const showSmsRateLimitAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_SMS_RATE_LIMIT
});
const showGenericTwoFactorErrorAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_GENERIC_TWO_FACTOR_ERROR
});
const showBlockedLoginMethodAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_BLOCKED_LOGIN_METHOD_ERROR
});
const showUserDeactivatedAlert = () => ({
  type: TwoFactorAuthActionTypes.SHOW_USER_DEACTIVATED
});
const setVettingUrl = vettingUrl => ({
  type: TwoFactorAuthActionTypes.SET_VETTING_URL,
  payload: {
    vettingUrl
  }
});
const hideUserDeactivatedAlert = () => ({
  type: TwoFactorAuthActionTypes.HIDE_USER_DEACTIVATED
});
const toggleRememberDevice = () => ({
  type: TwoFactorAuthActionTypes.TOGGLE_REMEMBER_DEVICE
});
const switchAppState = level => ({
  type: TwoFactorAuthActionTypes.SWITCH_APP_STATE,
  payload: {
    level
  }
});
const toggleRememberDeviceModal = () => ({
  type: TwoFactorAuthActionTypes.TOGGLE_REMEMBER_DEVICE_MODAL
});
const setLoginWithHSPrompt = () => ({
  type: TwoFactorAuthActionTypes.LOGIN_WITH_HS_PROMPT
});
export { startVerifying, finishVerifying, startRequestingCode, finishRequestingCode, startRequestingTwoFactorCall, finishRequestingTwoFactorCall, updateTotp, setTwoFactorMethod, switchTwoFactorMethod, setCredentials, showTokenExpiredAlert, hideTokenExpiredAlert, showNewCodeSentAlert, showCodeCallAlert, hideNewCodeSentAlert, hideCodeCallAlert, showInvalidCodeAlert, hideInvalidCodeAlert, showInvalidTokenAlert, showSmsRateLimitAlert, showGenericTwoFactorErrorAlert, showBlockedLoginMethodAlert, showUserDeactivatedAlert, hideUserDeactivatedAlert, toggleRememberDevice, switchAppState, toggleRememberDeviceModal, setLoginWithHSPrompt, setToken, setVettingUrl };