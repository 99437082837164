import TwoFactorAuthAppStates from '../constants/TwoFactorAuthAppStates';
import TwoFactorAuthLevels from '../constants/TwoFactorAuthLevels';
import TwoFactorAuthActionTypes from '../actions/TwoFactorAuthActionTypes';
const initialState = {
  appState: TwoFactorAuthAppStates.DEFAULT,
  backupCodesEnabled: true,
  credentials: {},
  isGenericTwoFactorErrorAlertVisible: false,
  isInvalidCodeAlertVisible: false,
  isInvalidTokenAlertVisible: false,
  isLoginWithHSPrompt: false,
  isNewCodeSentAlertVisible: false,
  isCodeCallAlertVisible: false,
  isRememberThisDeviceModalOpen: false,
  isRequestingCode: false,
  isSmsRateLimitAlertVisible: false,
  isTokenExpiredAlertVisible: false,
  isUserDeactivatedAlertVisible: false,
  isBlockedLoginMethodAlertVisible: false,
  vettingUrl: '',
  isVerifying: false,
  primary: null,
  rememberThisDevice: false,
  secondary: null,
  token: null,
  totp: '',
  twoFactorLevel: TwoFactorAuthLevels.PRIMARY
};
export default function twoFactorAuth(state = initialState, action) {
  switch (action.type) {
    case TwoFactorAuthActionTypes.START_VERIFYING:
      return Object.assign({}, state, {
        isVerifying: true,
        isInvalidCodeAlertVisible: false,
        isGenericTwoFactorErrorAlertVisible: false
      });
    case TwoFactorAuthActionTypes.FINISH_VERIFYING:
      return Object.assign({}, state, {
        isVerifying: false
      });
    case TwoFactorAuthActionTypes.START_REQUESTING_CODE_CALL:
    case TwoFactorAuthActionTypes.START_REQUESTING_CODE:
      return Object.assign({}, state, {
        isRequestingCode: true,
        isInvalidCodeAlertVisible: false,
        isGenericTwoFactorErrorAlertVisible: false
      });
    case TwoFactorAuthActionTypes.FINISH_REQUESTING_CODE_CALL:
    case TwoFactorAuthActionTypes.FINISH_REQUESTING_CODE:
      return Object.assign({}, state, {
        isRequestingCode: false
      });
    case TwoFactorAuthActionTypes.UPDATE_TOTP:
      return Object.assign({}, state, {
        totp: action.payload.totp,
        isInvalidCodeAlertVisible: false,
        isGenericTwoFactorErrorAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SET_TWO_FACTOR_METHOD:
      return Object.assign({}, state, {
        token: action.payload.token,
        twoFactorLevel: TwoFactorAuthLevels.PRIMARY,
        primary: action.payload.primary,
        secondary: action.payload.secondary,
        backupCodesEnabled: action.payload.backupCodesEnabled,
        totp: '',
        isInvalidCodeAlertVisible: false,
        isGenericTwoFactorErrorAlertVisible: false,
        isNewCodeSentAlertVisible: false,
        isCodeCallAlertVisible: false,
        hubspotAuthenticatorResponse: null
      });
    case TwoFactorAuthActionTypes.SWITCH_TWO_FACTOR_METHOD:
      return Object.assign({}, state, {
        twoFactorLevel: state.twoFactorLevel === TwoFactorAuthLevels.PRIMARY ? TwoFactorAuthLevels.SECONDARY : TwoFactorAuthLevels.PRIMARY,
        totp: '',
        isInvalidCodeAlertVisible: false,
        isGenericTwoFactorErrorAlertVisible: false,
        isNewCodeSentAlertVisible: false,
        isCodeCallAlertVisible: false,
        hubspotAuthenticatorResponse: null
      });
    case TwoFactorAuthActionTypes.SET_CREDENTIALS:
      return Object.assign({}, state, {
        credentials: action.payload.credentials
      });
    case TwoFactorAuthActionTypes.SET_TOKEN:
      return Object.assign({}, state, {
        token: action.payload.token
      });
    case TwoFactorAuthActionTypes.SHOW_TOKEN_EXPIRED:
      return Object.assign({}, state, {
        isTokenExpiredAlertVisible: true
      });
    case TwoFactorAuthActionTypes.HIDE_TOKEN_EXPIRED:
      return Object.assign({}, state, {
        isTokenExpiredAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SHOW_NEW_CODE_SENT:
      return Object.assign({}, state, {
        isNewCodeSentAlertVisible: true,
        isCodeCallAlertVisible: false,
        isInvalidCodeAlertVisible: false,
        isGenericTwoFactorErrorAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SHOW_CODE_CALL_ALERT:
      return Object.assign({}, state, {
        isCodeCallAlertVisible: true,
        isNewCodeSentAlertVisible: false,
        isInvalidCodeAlertVisible: false,
        isGenericTwoFactorErrorAlertVisible: false
      });
    case TwoFactorAuthActionTypes.HIDE_NEW_CODE_SENT:
      return Object.assign({}, state, {
        isNewCodeSentAlertVisible: false
      });
    case TwoFactorAuthActionTypes.HIDE_CODE_CALL_ALERT:
      return Object.assign({}, state, {
        isCodeCallAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SHOW_INVALID_CODE:
      return Object.assign({}, state, {
        isInvalidCodeAlertVisible: true
      });
    case TwoFactorAuthActionTypes.HIDE_INVALID_CODE:
      return Object.assign({}, state, {
        isInvalidCodeAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SHOW_INVALID_TOKEN:
      return Object.assign({}, state, {
        isInvalidTokenAlertVisible: true
      });
    case TwoFactorAuthActionTypes.HIDE_INVALID_TOKEN:
      return Object.assign({}, state, {
        isInvalidTokenAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SHOW_SMS_RATE_LIMIT:
      return Object.assign({}, state, {
        isSmsRateLimitAlertVisible: true
      });
    case TwoFactorAuthActionTypes.HIDE_SMS_RATE_LIMIT:
      return Object.assign({}, state, {
        isSmsRateLimitAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SHOW_GENERIC_TWO_FACTOR_ERROR:
      return Object.assign({}, state, {
        isGenericTwoFactorErrorAlertVisible: true
      });
    case TwoFactorAuthActionTypes.HIDE_GENERIC_TWO_FACTOR_ERROR:
      return Object.assign({}, state, {
        isGenericTwoFactorErrorAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SHOW_USER_DEACTIVATED:
      return Object.assign({}, state, {
        isUserDeactivatedAlertVisible: true
      });
    case TwoFactorAuthActionTypes.HIDE_USER_DEACTIVATED:
      return Object.assign({}, state, {
        isUserDeactivatedAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SHOW_BLOCKED_LOGIN_METHOD_ERROR:
      return Object.assign({}, state, {
        isBlockedLoginMethodAlertVisible: true
      });
    case TwoFactorAuthActionTypes.HIDE_BLOCKED_LOGIN_METHOD_ERROR:
      return Object.assign({}, state, {
        isBlockedLoginMethodAlertVisible: false
      });
    case TwoFactorAuthActionTypes.SET_VETTING_URL:
      return Object.assign({}, state, {
        vettingUrl: action.payload.vettingUrl
      });
    case TwoFactorAuthActionTypes.TOGGLE_REMEMBER_DEVICE:
      return Object.assign({}, state, {
        rememberThisDevice: !state.rememberThisDevice
      });
    case TwoFactorAuthActionTypes.TOGGLE_REMEMBER_DEVICE_MODAL:
      return Object.assign({}, state, {
        isRememberThisDeviceModalOpen: !state.isRememberThisDeviceModalOpen
      });
    case TwoFactorAuthActionTypes.SWITCH_APP_STATE:
      {
        const appState = state.appState === TwoFactorAuthAppStates.DEFAULT ? TwoFactorAuthAppStates.BACKUP : TwoFactorAuthAppStates.DEFAULT;
        const twoFactorLevel = action.payload.level || state.twoFactorLevel;
        return Object.assign({}, state, {
          appState,
          twoFactorLevel,
          totp: '',
          isInvalidCodeAlertVisible: false,
          isGenericTwoFactorErrorAlertVisible: false,
          isNewCodeSentAlertVisible: false,
          isCodeCallAlertVisible: false,
          hubspotAuthenticatorResponse: undefined
        });
      }
    case TwoFactorAuthActionTypes.UPDATE_HUBSPOT_APP_STATUS:
      return Object.assign({}, state, {
        hubspotAuthenticatorResponse: action.payload
      });
    case TwoFactorAuthActionTypes.CLICKED_BACK_BUTTON:
      return initialState;
    case TwoFactorAuthActionTypes.LOGIN_WITH_HS_PROMPT:
      return Object.assign({}, state, {
        isLoginWithHSPrompt: true
      });
    default:
      return state;
  }
}