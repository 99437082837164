import BaseLoginDao from './BaseLoginDao';
import http from 'hub-http/clients/noAuthApiClient';
class LoginDao extends BaseLoginDao {
  googleLogin(credentials) {
    return this.loginAtPath('/login-api/v1/google', credentials);
  }
  microsoftLogin(credentials) {
    return this.loginAtPath('login-api/v1/microsoft/id-token-login', credentials);
  }
  appleLogin(credentials) {
    return this.loginAtPath('login-api/v1/apple/login', credentials);
  }
  uiLogin(credentials) {
    return this.loginAtPath('/login-api/v1/login?src=ui', credentials);
  }
  userTrustTokenLogin(credentials) {
    return this.loginAtPath('/login-api/v1/login/trust-token', credentials);
  }
  hubUserTrustTokenLogin(credentials) {
    return this.loginAtPath('/login-api/v1/login/trust-token/hub-user', credentials);
  }
  getLoginRequirement(data) {
    return http.post(`/login-requirements/login`, {
      data: {
        email: data.email
      }
    });
  }
  getLoginRequirementWithPortal(data, queryData) {
    return http.post(`/login-requirements/login/portal-id/${queryData.loginPortalId}`, {
      data: {
        email: data.email
      }
    });
  }
  fetchLoginRequirement(email, loginPortalId) {
    const route = loginPortalId ? `/login-requirements/login/portal-id/${loginPortalId}` : '/login-requirements/login';
    return http.post(route, {
      data: {
        email
      }
    });
  }
  otpLogin(credentials) {
    return http.post('/login-api/v1/otp-login/verify', {
      data: credentials
    });
  }
  otpLoginInit(data) {
    return http.post('/login-api/v1/otp-login/renew', {
      data
    });
  }
}
export default LoginDao;