// @ts-expect-error no types from package
import HubSpotOnlyAppCookieLogin from 'Nabisco/app/HubSpotOnlyAppCookieLogin';
import ConfirmToLoginDao from '../data/ConfirmToLoginDao';
import SsoRequiredAuthStatuses from '../constants/SsoRequiredAuthStatuses';
import { stringify } from 'hub-http/helpers/params';
import { requestActions, simpleAction, updateAction } from './utils';
export const ConfirmToLoginActionTypes = {
  UPDATE_VERIFICATION_CODE: 'UPDATE_VERIFICATION_CODE',
  SEND_VERIFICATION_CODE: 'SEND_VERIFICATION_CODE',
  VERIFY_VERIFICATION_CODE: 'VERIFY_VERIFICATION_CODE',
  UPDATE_VERIFICATION_CODE_SUBMITTABLE: 'UPDATE_VERIFICATION_CODE_SUBMITTABLE',
  CLICKED_BACK_BUTTON: 'CLICKED_BACK_BUTTON',
  CLICKED_DISMISS_CODE_SENT_BUTTON: 'CLICKED_DISMISS_CODE_SENT_BUTTON'
};
const confirmToLoginDao = new ConfirmToLoginDao();
const appCookieLogin = new HubSpotOnlyAppCookieLogin({
  loginRequest: confirmToLoginDao.tryConfirmToLogin.bind(confirmToLoginDao),
  passThroughFallback: true
});
const SendNewVerificationCode = requestActions(ConfirmToLoginActionTypes.SEND_VERIFICATION_CODE);
const VerifyVerificationCode = requestActions(ConfirmToLoginActionTypes.VERIFY_VERIFICATION_CODE);
const ResetPage = simpleAction(ConfirmToLoginActionTypes.CLICKED_BACK_BUTTON);
export const dismissNewVerificationCodeSentAlert = simpleAction(ConfirmToLoginActionTypes.CLICKED_DISMISS_CODE_SENT_BUTTON);
export const updateVerificationCode = updateAction(ConfirmToLoginActionTypes.UPDATE_VERIFICATION_CODE);
export const sendNewVerificationCode = credentials => dispatch => {
  dispatch(SendNewVerificationCode.request());
  return confirmToLoginDao.generateCode(credentials).then(() => {
    dispatch(SendNewVerificationCode.success());
  }, error => {
    dispatch(SendNewVerificationCode.failure(error));
  });
};
export const verifyVerificationCode = credentials => dispatch => {
  dispatch(VerifyVerificationCode.request());
  appCookieLogin.login(credentials).then(responses => {
    if (responses[0].status === SsoRequiredAuthStatuses.SSO_LOGIN_REQUIRED) {
      const {
        hubId,
        requiredBy
      } = responses[0];
      const {
        loginRedirectUrl,
        email
      } = credentials;
      const nextPath = `sso?${stringify({
        email,
        loginPortalId: hubId,
        required: true,
        requiredBy,
        loginRedirectUrl
      })}`;
      responses[0].redirectUrl = nextPath;
    }
    appCookieLogin.redirectOnLoginSuccess(responses);
  }).catch(error => {
    dispatch(VerifyVerificationCode.failure(error));
  });
};
export const resetPage = () => dispatch => {
  dispatch(ResetPage());
};