/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-const */

'use es6';

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const splitCookies = value.split(';');
  for (let i = 0; i < splitCookies.length; i++) {
    const parts = splitCookies[i].split('=');
    if (parts.length === 2 && parts[0].trim() === name) {
      return parts[1];
    }
  }
  return null;
}
export function setCookie(name, value, expire) {
  let date = new Date();
  date.setTime(date.getTime() + expire);
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value + expires}; path=/`;
}