// @ts-expect-error no types from library
import Cookies from 'js-cookie';
import csrfVerifyClient from '../lib/csrfVerifyClient';
import * as LoginUtils from '../lib/LoginUtils';
import { hubletApi } from 'hub-http/middlewares/core';
import { OUTLOOK365 } from '../constants/LoginSources';
const REMEMBER_DEFAULT = Cookies.get('prefs') === '1';
class BypassLoginDao {
  bypass(credentials) {
    const source = LoginUtils.getLoginSource();
    if (source === OUTLOOK365) {
      credentials.rememberLogin = true;
    }
    credentials.rememberLogin = credentials.rememberLogin !== undefined ? credentials.rememberLogin : REMEMBER_DEFAULT;
    return csrfVerifyClient.post('/login-api/v1/bypass/hubless', {
      data: credentials,
      csrfCookieName: 'csrf.app',
      api: hubletApi('api', 'hubspot')
    });
  }
}
export default BypassLoginDao;