/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */

'use es6';

// Shallow copy all values from sources to target
// @See https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
export function objectAssign(target, ...sources) {
  sources.forEach(source => {
    let descriptors = Object.keys(source).reduce((descriptors, key) => {
      descriptors[key] = Object.getOwnPropertyDescriptor(source, key);
      return descriptors;
    }, {});
    Object.defineProperties(target, descriptors);
  });
  return target;
}

// Create a new object with all values from sources
// This method reads from left to right, so that defaults({ a: 1 }, { b: 2 }, { a: 3, b: 4, c: 5 })
// produces { a: 1, b: 2, c: 5 }
export function defaults(...sources) {
  if (sources.length === 0) {
    return undefined;
  }
  const clone = objectAssign({}, sources[0]);
  if (sources.length === 1) {
    return clone;
  }
  for (let i = 1; i < sources.length; i++) {
    let source = sources[i];
    let descriptors = Object.keys(source).reduce((descriptors, key) => {
      if (typeof clone[key] === 'undefined') {
        descriptors[key] = Object.getOwnPropertyDescriptor(source, key);
      }
      return descriptors;
    }, {});
    Object.defineProperties(clone, descriptors);
  }
  return clone;
}