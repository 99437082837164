'use es6';

import { cookieExists, defer, ensureFn } from './helpers';
export function createDeferredCallstack(fnHead) {
  let callstack = fnHead;
  const deferExecution = data => {
    defer(() => {
      if (typeof callstack === 'function') {
        const ref = callstack;
        callstack = undefined;
        ref(data);
      }
    });
  };
  return {
    execute: deferExecution,
    defer: fnTail => {
      const ref = callstack;
      callstack = data => {
        ensureFn(ref)(data);
        fnTail(data);
      };
    }
  };
}
export function createAsyncResolver({
  getAsyncData,
  resolve,
  onError
} = {}) {
  let cache;
  let callstack;
  return callback => {
    if (callstack) {
      callstack.defer(callback);
      if (cache) {
        callstack.execute(resolve(cache));
      }
    } else {
      callstack = createDeferredCallstack(callback);
      getAsyncData({
        onComplete: data => {
          cache = data || {};
          callstack.execute(resolve(cache));
        },
        onError: (err, data = {}) => {
          const networkAdapter =
          // This helps us to identify which network adapter is emitting an Error
          // Which is very useful for trailing the root cause of a Network Error
          data && data.networkAdapter ? `resolve:${data.networkAdapter}` : 'resolve';
          ensureFn(onError)(err, {
            fingerprint: ['laboratory-lib', 'lab:getAsyncData'],
            tags: {
              'laboratory-lib:error': networkAdapter
            },
            extra: {
              data
            }
          });
          cache = data;
          callstack.execute(resolve(cache));
        }
      });
    }
  };
}
export function verifyIfNetworkRequestIsNeeded({
  ceid = '',
  cacheKeys = [],
  schema = {},
  overridesFromCache = {},
  operationMode = 'default'
} = {}) {
  // Note.: `ceid` stands for the `copy experiments identifier` this is a unique identifier for the copy experiments
  // And needed for now in order to make copy experiments work. See: https://git.hubteam.com/HubSpot/Hublytics/issues/2706
  // @deprecated this is deprecated and should be removed soon once treatments schema is removed
  const isRequestingCopyExperimentData = !!ceid;

  // Currently the `treatments` object "allows" the `parameters` object to be empty even thought this is an invalid schema
  // To respect the current use cases and not break Applications, we will be supporting this until we remove the `treatments` schema completely
  // @deprecated should be removed once the treatments schema is removed
  const isSchemaNotEmptyButParametersAre = !cacheKeys.length && !!Object.keys(schema).length;

  // `cacheKeys` is an object created from the `treatments` coming from your `experiments.yaml` or `treatments` object passed when
  // creating the laboratory-lib client with `createClient`
  // This tests if either the treatments schema is empty or if there's any treatment key that doesn't exist within the cache
  const cacheHasMissingTreatmentKeys = cacheKeys.length && cacheKeys.some(({
    key
  }) => !overridesFromCache[key.trim()]);

  // If the current running Application invoking the laboratory-lib has the `hs_selenium` cookie
  // it likely means that this is running inside an Acceptance Test (not guaranteed as `prepareAppSession` is required)
  // and then we should send requests to the network during normal operation mode
  const documentHasSeleniumCookie = cookieExists('hs_selenium');
  switch (operationMode) {
    case 'network-only':
      return true;
    case 'cache-only':
      return false;
    case 'default':
    default:
      return documentHasSeleniumCookie || isRequestingCopyExperimentData || isSchemaNotEmptyButParametersAre || cacheHasMissingTreatmentKeys;
  }
}