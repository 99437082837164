'use es6';

import { getLogger } from './utils/debugging';
import { getCurrentTimestamp, getLatency } from './utils/Timing';
import { Deferred } from './utils/Promise';
import { serialize, parse } from './utils/messageTransformer';
import { MessageTypes } from './Constants';
const logger = getLogger();
let messageId = 0;
const getMessageId = () => {
  messageId++;
  return `${window.location.host}-${messageId}`;
};
export default class Interframe {
  constructor(targetOrigin, targetWindow, options = {}) {
    this.subscribe = callback => {
      const id = Symbol();
      this.subscriptions[id] = callback;
      return {
        unsubscribe: () => {
          delete this.subscriptions[id];
        }
      };
    };
    this.publish = data => {
      const subscriptionIds = Object.getOwnPropertySymbols(this.subscriptions);
      if (!subscriptionIds.length) {
        return;
      }
      subscriptionIds.forEach(key => {
        this.subscriptions[key](data);
      });
    };
    const {
      middleware
    } = options;
    this.targetOrigin = targetOrigin;
    this.targetWindow = targetWindow;
    this.onMessage = this.onMessage.bind(this);
    this.postMessage = this.postMessage.bind(this);
    this.postResponse = this.postResponse.bind(this);
    this.deferreds = {};
    this.messageMiddleware = middleware;
    this.subscriptions = {};
    window.addEventListener('message', this.onMessage, false);
  }
  postMessage(messageData, type = MessageTypes.RAW) {
    if (typeof type === 'function') {
      throw new Error('Passing a callback to postMessage() is no longer supported.');
    }
    // Support returning a response when calling postMessage for
    // backwards compatibility with v1 and v2 of interframe
    if (messageData.response && messageData._callbackId) {
      this.postResponse(messageData, messageData.response, type);
      return null;
    }
    const message = {
      type,
      _start: getCurrentTimestamp(),
      _callbackId: getMessageId(),
      body: serialize(messageData, type)
    };
    const deferred = new Deferred();
    this.deferreds[message._callbackId] = deferred;
    logger('Posting message to %s', this.targetOrigin, message);
    this._send(message);
    return deferred.promise;
  }
  postResponse(originalMessage, response, type = MessageTypes.RAW) {
    const {
      _start,
      _callbackId
    } = originalMessage._message;
    const message = {
      type,
      _callbackId,
      _start,
      _end: getCurrentTimestamp(),
      response: serialize(response, type)
    };
    logger('Posting message response to %s', this.targetOrigin, message);
    this._send(message);
  }
  _send(message) {
    this.targetWindow.postMessage(message, this.targetOrigin);
  }
  onMessage(event) {
    const {
      origin,
      data: message
    } = event;
    if (origin !== this.targetOrigin) {
      logger('Message origin %s does not match target origin %s', origin, this.targetOrigin, event);
      return;
    }
    if (!message || typeof message !== 'object') {
      return;
    }
    const {
      _callbackId: callbackId,
      _start,
      _end,
      body,
      response,
      type
    } = message;
    let messageData;
    if (response && callbackId) {
      messageData = parse(response, type);
      logger('Received response message from %s in %f ms', event.origin, getLatency(_end), messageData);
    } else if (body) {
      messageData = Object.assign({
        _callbackId: callbackId,
        _message: message
      }, parse(body, type));
      logger('Received message from %s in %f ms', event.origin, getLatency(_start), messageData);
    } else if (callbackId) {
      messageData = {
        _callbackId: callbackId,
        _message: message
      };
      logger('Received message from %s in %f ms', event.origin, getLatency(_start), messageData);
    } else {
      messageData = message;
      logger('Received message from %s not sent from Interframe', event.origin, messageData);
    }
    if (this.messageMiddleware) {
      messageData = this.messageMiddleware(messageData, this.postResponse);
    }
    if (!messageData) {
      return;
    }
    if (response && callbackId && this.deferreds[callbackId]) {
      if (type === MessageTypes.ERROR) {
        this.deferreds[callbackId].reject(messageData);
      } else {
        this.deferreds[callbackId].resolve(messageData);
      }
      this.deferreds[callbackId] = null;
    }
    this.publish(messageData);
  }
  destroy() {
    this.deferred = null;
    this.subscriptions = {};
    window.removeEventListener('message', this.onMessage, false);
  }
}