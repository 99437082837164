/* hs-eslint ignored failing-rules */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-rest-params */

'use es6';

import Storage from './Storage';
const DEBUG_FLAG = 'FIREALARM_DEBUG';
let storage;
try {
  const localStorage = window && window.localStorage ? window.localStorage : false;
  storage = new Storage(localStorage);
} catch (e) {
  storage = new Storage(false);
}
function debug(message) {
  if (storage.getItem(DEBUG_FLAG)) {
    console.log.apply(console, arguments);
  }
}
export default debug;