import { getLocationSearchList } from './utils/getLocationSearchList';
export class SignupEmbedderOptions {
  constructor() {
    this.affiliateLink = null;
    this.popupBlocked = false;
    this.autofocusDisabled = true;
    this.carryParameters = false;
    this.shouldRedirectWindow = true;
    this.shouldShowMessaging = false;
    this.shouldShowFireAlarm = true;
    this.shouldEnforceEmailVerification = false;
    this.deviceId = null;
    this.clientId = null;
    this.scopes = null;
    this.oauthState = null;
    this.redirect_uri = null;
    this.title = 'HubSpot Signup';
    this.shouldSkipPrefilledSteps = false;
  }
  /**
   * Returns a list of all protected options.
   *
   * @static
   * @returns {Array.<string>}
   * @memberof SignupEmbedderOptions
   */
  /**
   * Returns a list of all protected options that can be removed from the loginRedirectURL
   * Add options to this Set also which you add to the above protectedOptions
   * @static
   * @returns {Array.<string>}
   * @memberof SignupEmbedderOptions
   */
  /**
   * Checks if a given optionName is present on the page location's search attribute.
   *
   * @static
   * @param {string} option The option name to check
   * @returns {Boolean}
   * @memberof SignupEmbedderOptions
   */
  static hasOption(optionName) {
    return getLocationSearchList().some(([key]) => key === optionName);
  }

  /**
   * Gets an option value assigned to the current page location's search attribute.
   *
   * @static
   * @param {string} optionName
   * @returns {(string | null)}
   * @memberof SignupEmbedderOptions
   */
  static getOptionValue(optionName) {
    const searchQuery = getLocationSearchList();
    const searchOption = searchQuery.find(([key]) => {
      return key === optionName;
    });
    return searchOption ? searchOption[1] : null;
  }

  /**
   * Sets the affiliate link.
   *
   * @param {string} value
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setAffiliateLink(value) {
    this.affiliateLink = value;
    return this;
  }

  /**
   * Sets the popup blocked flag.
   *
   * @param {string} value
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setPopupBlocked() {
    this.popupBlocked = true;
    return this;
  }

  /**
   * Sets the autofocusDisabled flag.
   *
   * @param {boolean} value
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setAutofocusDisabled(value) {
    this.autofocusDisabled = value;
    return this;
  }

  /**
   * Sets the shouldEnforceEmailVerification flag.
   *
   * @param {boolean} value
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  enforceEmailVerification(value) {
    this.shouldEnforceEmailVerification = value;
    return this;
  }

  /**
   * Sets the shouldRedirectWindow flag,
   *
   * @deprecated
   * @param {boolean} value
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setShouldRedirectWindow(value) {
    this.shouldRedirectWindow = value;
    return this;
  }

  /**
   * Sets the carryParameters flag.
   *
   * @param {boolean} value
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setCarryParameters(value) {
    this.carryParameters = value;
    return this;
  }

  /**
   * Sets the title attribute to be used in the iframe.
   * @param title The title to be set.
   * @returns {SignupEmbedderOptions}
   */
  setTitle(title) {
    if (title && typeof title === 'string' && title.trim().length > 0) {
      this.title = title;
    }
    return this;
  }

  /**
   * Sets the device id override.
   *
   * @param {string} deviceId
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setDeviceId(deviceId) {
    this.deviceId = deviceId;
    return this;
  }

  /**
   * Sets that we should show messaging.
   *
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setShouldShowMessaging() {
    this.shouldShowMessaging = true;
    return this;
  }

  /**
   * Sets that we should skip prefilled steps.
   *
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setShouldSkipPrefilledSteps() {
    this.shouldSkipPrefilledSteps = true;
    return this;
  }
  /**
   * Sets that we should hide the fire alarm.
   *
   * @returns {SignupEmbedderOptions}
   * @memberof SignupEmbedderOptions
   */
  setShouldHideFireAlarm() {
    this.shouldShowFireAlarm = false;
    return this;
  }
  setOAuth(clientId, scopes, redirect_uri, state) {
    this.clientId = clientId;
    this.scopes = scopes;
    this.redirect_uri = redirect_uri;
    if (state) {
      this.oauthState = state;
    }
    return this;
  }

  /**
   * The url of the page embedding signup.
   *
   * @returns {string}
   * @memberof SignupEmbedderOptions
   */
  getSignupEmbeddingPage() {
    return `${window.location.origin}${window.location.pathname}`;
  }

  /**
   * Gets all options.
   *
   * @returns {string[][]}
   * @memberof SignupEmbedderOptions
   */
  getOptions() {
    const options = [[SignupEmbedderOptions.SIGNUP_EMBEDDING_PAGE_OPTION, this.getSignupEmbeddingPage()]];
    options.push([SignupEmbedderOptions.SIGNUP_EMBEDDER_IS_ACTIVE, '1']);
    if (this.shouldShowMessaging) {
      options.push([SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_MESSAGING, '1']);
    }
    if (this.shouldSkipPrefilledSteps) {
      options.push([SignupEmbedderOptions.SIGNUP_SHOULD_SKIP_PREFILLED_STEPS, '1']);
    }
    if (this.shouldRedirectWindow) {
      options.push([SignupEmbedderOptions.SIGNUP_SHOULD_REDIRECT_WINDOW_OPTION, '1']);
    }
    if (this.shouldShowFireAlarm) {
      options.push([SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_FIREALARM, '1']);
    }
    if (this.autofocusDisabled) {
      options.push([SignupEmbedderOptions.SIGNUP_AUTO_FOCUS_DISABLED_OPTION, '1']);
    }
    if (this.shouldEnforceEmailVerification) {
      options.push([SignupEmbedderOptions.SIGNUP_ENFORCE_EMAIL_VERIFICATION, '1']);
    }
    if (this.popupBlocked) {
      options.push([SignupEmbedderOptions.SIGNUP_POPUP_BLOCKED, '1']);
    }
    if (this.deviceId) {
      options.push([SignupEmbedderOptions.SIGNUP_DEVIDE_ID_OVERRIDE_OPTION, this.deviceId]);
      options.push([SignupEmbedderOptions.SIGNUP_UUID_OVERRIDE, this.deviceId]);
    }
    if (this.clientId && this.scopes && this.redirect_uri) {
      options.push(['intent', 'oauthAuthorization']);
      options.push([SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_ID, this.clientId]);
      options.push([SignupEmbedderOptions.SIGNUP_OAUTH_SCOPES, this.scopes]);
      options.push([SignupEmbedderOptions.SIGNUP_OAUTH_REDIRECT, this.redirect_uri]);
    }
    if (this.oauthState) {
      options.push([SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_STATE, this.oauthState]);
    }
    return options;
  }

  /**
   * Get the option query strings.
   * @returns {string}
   */
  getQueryStrings() {
    let querystrings = this.getOptions();
    if (this.carryParameters) {
      const embedderSearch = getLocationSearchList();
      querystrings = [...querystrings, ...embedderSearch];
    }
    return querystrings;
  }
}
SignupEmbedderOptions.SIGNUP_AUTO_FOCUS_DISABLED_OPTION = '_safd';
SignupEmbedderOptions.SIGNUP_EMBEDDING_PAGE_OPTION = '_sebp';
SignupEmbedderOptions.SIGNUP_DEVIDE_ID_OVERRIDE_OPTION = '_sdio';
SignupEmbedderOptions.SIGNUP_UUID_OVERRIDE = 'uuid';
SignupEmbedderOptions.SIGNUP_SHOULD_REDIRECT_WINDOW_OPTION = '_ssrw';
SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_ID = '_soaci';
SignupEmbedderOptions.SIGNUP_OAUTH_SCOPES = '_soas';
SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_STATE = '_soacs';
SignupEmbedderOptions.SIGNUP_OAUTH_REDIRECT = '_soar';
SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_MESSAGING = '_sssm';
SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_FIREALARM = '_sssf';
SignupEmbedderOptions.SIGNUP_EMBEDDER_IS_ACTIVE = '_seia';
SignupEmbedderOptions.SIGNUP_ENFORCE_EMAIL_VERIFICATION = '_seev';
SignupEmbedderOptions.SIGNUP_POPUP_BLOCKED = '_spb';
SignupEmbedderOptions.SIGNUP_SHOULD_SKIP_PREFILLED_STEPS = '_ssps';
SignupEmbedderOptions.protectedOptions = new Set([SignupEmbedderOptions.SIGNUP_AUTO_FOCUS_DISABLED_OPTION, SignupEmbedderOptions.SIGNUP_EMBEDDING_PAGE_OPTION, SignupEmbedderOptions.SIGNUP_DEVIDE_ID_OVERRIDE_OPTION, SignupEmbedderOptions.SIGNUP_UUID_OVERRIDE, SignupEmbedderOptions.SIGNUP_SHOULD_REDIRECT_WINDOW_OPTION, SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_ID, SignupEmbedderOptions.SIGNUP_OAUTH_SCOPES, SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_STATE, SignupEmbedderOptions.SIGNUP_OAUTH_REDIRECT, SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_MESSAGING, SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_FIREALARM, SignupEmbedderOptions.SIGNUP_EMBEDDER_IS_ACTIVE, SignupEmbedderOptions.SIGNUP_ENFORCE_EMAIL_VERIFICATION, SignupEmbedderOptions.SIGNUP_POPUP_BLOCKED, SignupEmbedderOptions.SIGNUP_SHOULD_SKIP_PREFILLED_STEPS]);
SignupEmbedderOptions.protectedOptionsRemovable = new Set([SignupEmbedderOptions.SIGNUP_AUTO_FOCUS_DISABLED_OPTION, SignupEmbedderOptions.SIGNUP_EMBEDDING_PAGE_OPTION, SignupEmbedderOptions.SIGNUP_DEVIDE_ID_OVERRIDE_OPTION, SignupEmbedderOptions.SIGNUP_SHOULD_REDIRECT_WINDOW_OPTION, SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_MESSAGING, SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_FIREALARM, SignupEmbedderOptions.SIGNUP_EMBEDDER_IS_ACTIVE, SignupEmbedderOptions.SIGNUP_POPUP_BLOCKED, SignupEmbedderOptions.SIGNUP_SHOULD_SKIP_PREFILLED_STEPS]);