import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["state"];
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Url from 'urlinator/Url';
// @ts-expect-error package not converted to TS
import HubSpotOnlyAppCookieLogin from 'Nabisco/app/HubSpotOnlyAppCookieLogin';
import LoginDao from '../../data/LoginDao';
import { useRedirect } from '../../hooks/useRedirect';
import { GOOGLE, APPLE } from '../../constants/LoginSources';
const loginDao = new LoginDao();
export const useConfirmationLanding = loginType => {
  const [error, setError] = useState();
  const {
    search,
    hash
  } = useLocation();
  const appCookieLogin = useMemo(() => {
    const asyncCall = () => {
      if (loginType === 'MICROSOFT') {
        return loginDao.microsoftLogin.bind(loginDao);
      } else if (loginType === APPLE) {
        return loginDao.appleLogin.bind(loginDao);
      } else {
        return loginDao.googleLogin.bind(loginDao);
      }
    };
    return new HubSpotOnlyAppCookieLogin({
      loginRequest: asyncCall(),
      passThroughFallback: true
    });
  }, [loginType]);

  // parse query params
  const credentials = useMemo(() => {
    const qp = new URLSearchParams(search);
    const hp = new URLSearchParams(hash.substring(1));
    let _Object$fromEntries = Object.fromEntries([...qp]),
      {
        state
      } = _Object$fromEntries,
      parsedParams = _objectWithoutPropertiesLoose(_Object$fromEntries, _excluded);
    const idToken = hp.get('id_token');
    if (idToken && loginType !== GOOGLE) {
      state = Object.fromEntries([...hp]).state;
      parsedParams = Object.fromEntries([...hp].filter(el => {
        return el[0] !== 'state' && el[0] !== 'id_token';
      }));
    }
    const URL_LOADED_LOCAL = Url.loaded.local;
    const params = Object.assign({}, parsedParams, JSON.parse(state || '{}'), URL_LOADED_LOCAL ? {
      redirectToLocal: true
    } : {});
    if (loginType !== GOOGLE) {
      return Object.assign({}, params, {
        authCode: parsedParams.code,
        idToken,
        code: undefined
      });
    }
    return params;
  }, [search, loginType, hash]);

  // response and redirect handlers
  const {
    redirectByStatus
  } = useRedirect(credentials);

  // onLoad handler
  const onLoad = useCallback(() => {
    appCookieLogin.login(credentials).then(responses => {
      const response = responses[0];
      redirectByStatus(response);
    }).catch(err => {
      const type = err && err.responseJSON && err.responseJSON.status;
      setError(type || 'LOGIN_UNAVAILABLE');
    });
  }, [setError, appCookieLogin, redirectByStatus, credentials]);
  return {
    error,
    onLoad
  };
};