export default {
  UPDATE_TOTP: 'UPDATE_TOTP',
  SET_TOKEN: 'SET_TOKEN',
  SET_TWO_FACTOR_METHOD: 'SET_TWO_FACTOR_METHOD',
  SWITCH_TWO_FACTOR_METHOD: 'SWITCH_TWO_FACTOR_METHOD',
  SET_CREDENTIALS: 'SET_CREDENTIALS',
  TOGGLE_REMEMBER_DEVICE: 'TOGGLE_REMEMBER_DEVICE',
  SWITCH_APP_STATE: 'SWITCH_APP_STATE',
  START_VERIFYING: 'START_VERIFYING',
  FINISH_VERIFYING: 'FINISH_VERIFYING',
  START_REQUESTING_CODE: 'START_REQUESTING_CODE',
  FINISH_REQUESTING_CODE: 'FINISH_REQUESTING_CODE',
  START_REQUESTING_CODE_CALL: 'START_REQUESTING_CODE_CALL',
  FINISH_REQUESTING_CODE_CALL: 'FINISH_REQUESTING_CODE_CALL',
  SHOW_TOKEN_EXPIRED: 'SHOW_TOKEN_EXPIRED',
  HIDE_TOKEN_EXPIRED: 'HIDE_TOKEN_EXPIRED',
  SHOW_NEW_CODE_SENT: 'SHOW_NEW_CODE_SENT',
  HIDE_NEW_CODE_SENT: 'HIDE_NEW_CODE_SENT',
  SHOW_CODE_CALL_ALERT: 'SHOW_CODE_CALL_ALERT',
  HIDE_CODE_CALL_ALERT: 'HIDE_CODE_CALL_ALERT',
  SHOW_INVALID_CODE: 'SHOW_INVALID_CODE',
  HIDE_INVALID_CODE: 'HIDE_INVALID_CODE',
  SHOW_INVALID_TOKEN: 'SHOW_INVALID_TOKEN',
  HIDE_INVALID_TOKEN: 'HIDE_INVALID_TOKEN',
  SHOW_SMS_RATE_LIMIT: 'SHOW_SMS_RATE_LIMIT',
  HIDE_SMS_RATE_LIMIT: 'HIDE_SMS_RATE_LIMIT',
  SHOW_USER_DEACTIVATED: 'SHOW_USER_DEACTIVATED',
  HIDE_USER_DEACTIVATED: 'HIDE_USER_DEACTIVATED',
  SHOW_BLOCKED_LOGIN_METHOD_ERROR: 'SHOW_BLOCKED_LOGIN_METHOD_ERROR',
  HIDE_BLOCKED_LOGIN_METHOD_ERROR: 'HIDE_BLOCKED_LOGIN_METHOD_ERROR',
  SHOW_GENERIC_TWO_FACTOR_ERROR: 'SHOW_GENERIC_TWO_FACTOR_ERROR',
  HIDE_GENERIC_TWO_FACTOR_ERROR: 'HIDE_GENERIC_TWO_FACTOR_ERROR',
  CLICKED_BACK_BUTTON: 'CLICKED_BACK_BUTTON',
  FETCH_HUBSPOT_APP_STATUS: 'FETCH_HUBSPOT_APP_STATUS',
  UPDATE_HUBSPOT_APP_STATUS: 'UPDATE_HUBSPOT_APP_STATUS',
  TOGGLE_REMEMBER_DEVICE_MODAL: 'TOGGLE_REMEMBER_DEVICE_MODAL',
  LOGIN_WITH_HS_PROMPT: 'LOGIN_WITH_HS_PROMPT',
  SET_VETTING_URL: 'SET_VETTING_URL'
};