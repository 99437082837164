'use es6';

export function parseExperiments({
  experiments = {},
  identifiers = {}
} = {}) {
  const parsedExperiments = {};
  const mappedIdentifiers = {
    PORTAL_ID: identifiers.portalId,
    USER_ID: identifiers.userId,
    PORTAL_USER: identifiers.portalUser,
    UTK: identifiers.utk,
    OTHER: identifiers.other
  };
  Object.keys(experiments).forEach(experimentKey => {
    const experiment = experiments[experimentKey];
    const identifier = mappedIdentifiers[experiment.identifierType];
    const parsedExperiment = {
      identifier,
      parameters: experiment.parameters
    };
    if (typeof experiment.maxAge === 'number') {
      parsedExperiment.maxAge = experiment.maxAge;
    }
    parsedExperiments[experimentKey] = parsedExperiment;
  });
  return parsedExperiments;
}