export const PAGE_HEADER = {
  MICROSOFT: 'MICROSOFT_LOGIN_REDIRECT_LOAD',
  GOOGLE: 'GOOGLE_LOGIN_REDIRECT_LOAD',
  APPLE: 'APPLE_LOGIN_REDIRECT_LOAD'
};
export const ERROR_COPY = {
  MICROSOFT: {
    header: 'login.microsoftLogin.failed.header',
    instructions: 'login.microsoftLogin.failed.instructions',
    navigate: 'login.microsoftLogin.failed.navigate'
  },
  GOOGLE: {
    header: 'login.googleLogin.failed.header',
    instructions: 'login.googleLogin.failed.instructions',
    navigate: 'login.googleLogin.failed.navigate'
  },
  APPLE: {
    header: 'login.appleLogin.failed.header',
    instructions: 'login.appleLogin.failed.instructions',
    navigate: 'login.appleLogin.failed.navigate'
  }
};
export const LOADING_COPY = {
  MICROSOFT: {
    loadingMessageType: 'microsoft',
    confirming: 'login.microsoftLogin.confirming'
  },
  GOOGLE: {
    loadingMessageType: 'google',
    confirming: 'login.googleLogin.confirming'
  },
  APPLE: {
    loadingMessageType: 'apple',
    confirming: 'login.appleLogin.confirming'
  }
};