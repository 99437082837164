import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
const queryDataValues = ['useDark', 'loginRedirectUrl', 'allowFbSignup', 'hideSignupLink', 'signupParams', 'pageId', 'loginPortalId'];
const queryDataBooleans = ['useDark', 'allowFbSignup'];
export const useQueryData = () => {
  const {
    search
  } = useLocation();
  return useMemo(() => {
    const query = new URLSearchParams(search);
    const queryData = Object.fromEntries(queryDataValues.map(value => value === 'loginPortalId' ? [value, !query.get(value) || isNaN(Number(query.get(value))) || Number(query.get(value)) <= 0 ? undefined : Number(query.get(value))] : queryDataBooleans.includes(value) ? [value, query.get(value) === 'true'] : [value, query.get(value) ? query.get(value) : undefined]));
    return {
      allQueryParams: Object.fromEntries([...query]),
      queryData
    };
  }, [search]);
};