import getNaiveObjectSize from './getNaiveObjectSize';
const getRecordSizesClosure = () => {
  const recordSizes = new Map();
  const getRecordCallback = (key, value) => recordSizes.set(key, getNaiveObjectSize(value));
  return {
    recordSizes,
    getRecordCallback
  };
};
export const getRecordSizes = getAllRecords => {
  const {
    recordSizes,
    getRecordCallback
  } = getRecordSizesClosure();
  getAllRecords(getRecordCallback);
  return recordSizes;
};
export const getRecordSizesAsync = getAllRecords => {
  const {
    recordSizes,
    getRecordCallback
  } = getRecordSizesClosure();
  return getAllRecords(getRecordCallback).then(() => recordSizes);
};