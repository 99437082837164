import { Environment } from 'signup-ui-lego-core/constants/Environment';
import { Language } from 'signup-ui-lego-core/constants/Language';
import * as MessageTypes from './constants/MessageTypes';
import { SignupEmbedder } from './SignupEmbedder';
import { SignupEmbedderOptions } from './SignupEmbedderOptions';
const w = window;
w.SignupEmbedder = SignupEmbedder;
w.SignupEmbedderOptions = SignupEmbedderOptions;
w.SignupEmbedderMessageTypes = MessageTypes;
w.SignupEmbedderEnvironments = Environment;
w.SignupEmbedderLanguages = Language;
export { SignupEmbedder } from './SignupEmbedder';
export { SignupEmbedderOptions } from './SignupEmbedderOptions';