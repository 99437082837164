'use es6';

class Severity {
  constructor() {
    const severities = {
      CRITSIT: {
        id: 0,
        priority: 4
      },
      OUTAGE: {
        id: 1,
        priority: 3
      },
      MAINTENANCE: {
        id: 2,
        priority: 2
      },
      DEBUG: {
        id: 3,
        priority: 1
      }
    };
    this.idIndex = {};
    Object.keys(severities).forEach(key => {
      const value = severities[key];
      this[key] = value;
      value.key = key;
      this.idIndex[value.id] = value;
    });
  }
  getById(id) {
    return this.idIndex[id];
  }
  getByKey(key) {
    return this[key];
  }
}
export default new Severity();