import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import * as core from 'hub-http/middlewares/core';
import * as hubapi from 'hub-http/middlewares/hubapi';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'hub-... Remove this comment to see the full error message
import * as debug from 'hub-http/middlewares/debug';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'hub-... Remove this comment to see the full error message
import { getCookie } from 'hub-http/helpers/cookies';
const withCsrfMaybe = options => {
  const csrfCookie = getCookie(options.csrfCookieName, options.cookies);
  return csrfCookie ? core.header('X-HubSpot-CSRF-hubspotapi', csrfCookie)(options) : options;
};
export default promiseClient(createStack(core.services, hubapi.defaults, debug.allowTimeoutOverride, core.jsonBody, core.httpsOnly, core.withApiAsOption, core.withQuery, withCsrfMaybe, debug.rewriteUrl, hubapi.timeoutInQuery, core.reportOptionsError, core.validateStatus, core.jsonResponse));