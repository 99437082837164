export const ensureFn = (fn, fallback = () => {}) => typeof fn === 'function' ? fn : fallback;
export function ensurePromise(thing) {
  if (thing instanceof Promise) {
    return thing;
  }
  return Promise.resolve(thing);
}
export function filterObject(obj, predicate = ([, v]) => Boolean(v)) {
  return Object.entries(obj).filter(predicate).reduce((acc, [k, v]) => Object.assign({}, acc, {
    [k]: v
  }), {});
}
export const pick = (source = {}, list = []) => Object.assign({}, ...list.map(key => ({
  [key]: source[key]
})));
export const once = fn => {
  let isCached;
  let result;
  return (...args) => {
    if (!isCached) {
      isCached = true;
      result = fn(...args);
    }
    return result;
  };
};
export function defer(func, args) {
  return setTimeout(() => {
    func.apply(undefined, args);
  }, 1);
}
export function merge(target, source) {
  const isObject = obj => typeof obj === 'object' && obj;
  if (!isObject(target) || !isObject(source)) {
    return source;
  }
  Object.keys(source).forEach(key => {
    // @ts-expect-error migrate this properly
    const targetValue = target[key];
    // @ts-expect-error migrate this properly
    const sourceValue = source[key];
    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      // @ts-expect-error migrate this properly
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      // @ts-expect-error migrate this properly
      target[key] = merge(Object.assign({}, targetValue), sourceValue);
    } else {
      // @ts-expect-error migrate this properly
      target[key] = sourceValue;
    }
  });
  return target;
}

// Note: params can both be an object or a string
// @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams#parameters
export function createQueryString(params = '') {
  const searchParams = new URLSearchParams(params);
  return [...searchParams.values()].length ? `?${searchParams.toString()}` : '';
}

// Note: params can both be an object or a string
// @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams#parameters
export function createQueryObject(params = '') {
  return Object.fromEntries(new URLSearchParams(params));
}
export const getNavigatorLanguage = (defaultLanguage = 'en-us') => {
  if (typeof navigator === 'object') {
    const preferredLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
    if (preferredLanguage) {
      return preferredLanguage;
    }
  }
  return defaultLanguage;
};
export const cookieExists = name => {
  const documentCookie = typeof document === 'object' ? document.cookie : '';
  return documentCookie.split(';').some(i => i.trim().startsWith(`${name}=`));
};
export const hasRaven = prop => {
  return typeof prop['Raven'] === 'object' && typeof prop['Raven'].captureException === 'function' && typeof prop['Raven'].captureMessage === 'function';
};