import { useRef } from 'react';
import { useButton } from '@react-aria/button';
import { useMaybeStopPropagation } from '../../components/AppProvider/PropagationProvider';
export const useTrellisButton = props => {
  const {
    onClick
  } = props;
  const ref = useRef(null);
  const {
    maybeStopPropagation
  } = useMaybeStopPropagation();
  const handleClick = event => {
    maybeStopPropagation(event);
    onClick(event);
  };
  const handleKeyUp = event => {
    if (event.key === ' ' || event.key === 'Enter') {
      maybeStopPropagation(event);
      onClick(event);
    }
  };
  const {
    buttonProps
  } = useButton(Object.assign({}, props, {
    onKeyUp: handleKeyUp,
    elementType: 'button'
  }), ref);

  // Extend the buttonProps from react-aria with our custom onClick handler
  return {
    buttonProps: Object.assign({}, buttonProps, {
      onClick: handleClick
    }),
    ref
  };
};