import { useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook that determines if the component is currently mounted.
 * @see https://usehooks-ts.com/react-hook/use-is-mounted
 * @returns The isMounted function, which returns a boolean when called that indicates if the component is mounted.
 */
export function useIsMounted() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return useCallback(() => isMounted.current, []);
}