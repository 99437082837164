'use es6';

import { logAndReportError } from './core/errors';
import * as superStore from './core/store';
import * as client from './client';
import * as constants from './constants';

/**
 * Imports Existing Type Definitions until we migrate to TypeScript
 *
 * @param {import('./index.d.ts').CreateClientExperimentOptions}
 */
export function createClient({
  // This defines the default error logging and reporting functions
  onError = logAndReportError,
  // This retrieves the default SuperStore instance (IndexedDB)
  store = superStore.getDefaultStore(),
  // How long laboratory-lib should wait before aborting the network request
  timeout = constants.TIMEOUT_DEFAULT,
  // When provided, laboratory-lib will attempt to use quick-fetch for doing network requests
  // If quick-fetch is unavailable or quick-fetch failed, it will fallback to hub-http
  quickFetchLabel = null,
  // Attempts to use `window.quickFetch` by default and falls-back to dynamic module import
  // Since if window.quickFetch is not defined, well, even if the module is available, pretty much it should not work
  // This should usually not be overridden, but when provided it will use the implementation provided from this library
  // @deprecated we want to encourage users to use `laboratory-lib/test-utils` or `devtools-extension` instead
  quickFetch = typeof window !== 'undefined' ? window.quickFetch : null,
  // This is the legacy treatments object before `experiments.yaml` got introduced
  // @deprecated this option is deprecated and should not be used.
  treatments = null,
  // This is the newly introduced identifiers object for `experiments.yaml`
  identifiers = null,
  // This is the newly introduced experiments object from `experiments.yaml`
  experiments = null,
  // Attempts to not use `hub-http` by default and falls-back to dynamic module import
  // @note you can provide your own in-house implementation or instance/override of hub-http
  http = null,
  // This is an override to the default API Domain that HubHTTP should be doing API requests
  apiDomain = null,
  // `ceid`'s are copy experiment identifiers. These are necessary to flag a request as to retrieve all
  // the copy experiments for a given application.
  // @see https://git.hubteam.com/HubSpot/Hublytics/issues/2706 for more details.
  // @deprecated this option is deprecated and should not be used.
  ceid = null,
  // This is the new laboratory-lib operation mode which decides if LaboratoryLib should make requests or not
  mode = null,
  // This is the unique instance name that can be given to Laboratory instances and should be used to identify your instance
  // If no instance name is given the instance will not be cached to the pool of instance caches
  name = null
} = {}) {
  return client.createClient({
    onError,
    store,
    timeout,
    http,
    quickFetchLabel,
    quickFetch,
    treatments,
    identifiers,
    experiments,
    apiDomain,
    ceid,
    mode,
    name
  });
}