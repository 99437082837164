'use es6';

class ApiError {
  constructor(httpRequest, exception) {
    this.httpRequest = httpRequest;
    this.exception = exception || null;
  }
  getMessage() {
    if (this.exception) {
      return this.exception.toString();
    }
    if (this.httpRequest && this.httpRequest.readyState === XMLHttpRequest.DONE) {
      return this.httpRequest.statusText;
    }
    return 'httpRequest did not complete';
  }
}
export default ApiError;