import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Metrics } from '../utils/Metrics';

/**
 * Emit metrics to help find places where apps aren't properly configured to
 * switch over to foundations components
 */
export function useFoundationsReadinessTracking() {
  const theme = useTheme();
  useEffect(() => {
    try {
      var _color;
      const isProbablySetUpForFoundationsComponents = typeof theme === 'object' && Object.keys(theme !== null && theme !== void 0 ? theme : {}).includes('color') && typeof theme.color === 'object' && Object.keys((_color = theme.color) !== null && _color !== void 0 ? _color : {}).includes('icon-primary-on-fill-default');
      Metrics.counter('foundations-theme-ready', {
        result: `${isProbablySetUpForFoundationsComponents}`
      }).increment();
    } catch (__e) {
      // It's fine, this is very best effort,
      // we just don't want to ever crash an app.
      // If we threw, then this app probably isn't set up though.
      Metrics.counter('foundations-theme-ready', {
        result: 'false'
      }).increment();
    }
  }, [theme]);
}