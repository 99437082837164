import Url from 'urlinator/Url';
import { hubletApi } from 'hub-http/middlewares/core';
import csrfVerifyClient from './csrfVerifyClient';
export const isValidEmail = email => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
};
export const isValidDomain = domain => {
  const regex = /* eslint-disable-next-line no-useless-escape */
  /^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/;
  return regex.test(domain);
};

// from p53 form validation: MUST CONTAIN ONLY NUMBERS, +()-. AND X.
export const isValidPhone = phone => {
  /* eslint-disable-next-line no-useless-escape */
  const regex = /^[0-9\+\(\)\-\.Xx ]+$/;
  return regex.test(phone.trim());
};
export const parseParams = url => {
  const params = url.split('#')[1] || '';
  const parsed = {};
  const keyValues = params.split('&');
  for (let i = 0; i < keyValues.length; i++) {
    const keyValue = keyValues[i];
    const splitKeyValue = keyValue.split('=');
    try {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      parsed[decodeURIComponent(splitKeyValue[0])] = decodeURIComponent(splitKeyValue[1]);
    } catch (ex) {
      console.error(`Error: Unable to parse params '${keyValue}'`);
    }
  }
  return parsed;
};
export const trimEmail = email => {
  return email.replace(/^\s+|\s+$/g, '');
};
export const extractRedirectQueryParams = query => {
  const {
    autoRedirectGoogle: google,
    loginPortalId: longPortal,
    loginRedirectUrl: redirectUrl,
    googleLoginRedirectUrl,
    samlLoginToken
  } = query;
  let sanePortal = longPortal;
  if (sanePortal && sanePortal <= 0) {
    sanePortal = null;
  }
  return {
    autoRedirectGoogle: google,
    loginPortalId: sanePortal,
    loginRedirectUrl: redirectUrl,
    googleLoginRedirectUrl,
    samlLoginToken
  };
};
export const checkCookiesAndCsrf = portalId => {
  const url = '/login-api/v1/cookie-verify/csrf-check';
  const hubspot = hubletApi('api', 'hubspot');
  return csrfVerifyClient.get(url, {
    api: hubspot,
    csrfCookieName: 'csrf.app',
    query: {
      portalId
    }
  });
};
export const getLoginSource = () => {
  const url = new Url(window.location.href);
  return url.params.source || null;
};