import performanceNow from './vendor/performanceNow';
import memoizeOne from 'react-utils/memoizeOne';
export default memoizeOne(history => {
  const {
    location: {
      pathname: initialPathname
    }
  } = history;
  return {
    pathname: initialPathname,
    listen: cb => history.listen(({
      pathname
    }) => {
      const timestamp = performanceNow();
      cb({
        timestamp,
        pathname
      });
    })
  };
});