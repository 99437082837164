import enviro from 'enviro';
const earlyResolve = error => {
  if (error) console.warn(error);
  return Promise.resolve();
};
const MIN_SIZE = 4; // You need at least 4 bytes to store anything useful in JS.

const CAPACITY_ERROR = new Error('IndexedDB capacity exceeded');

/**
 * @returns {Promise<Number|undefined>} - bytes of available storage, or undefined if quota can't be estimated
 * @private
 */
const getAvailable = () => {
  if (window.navigator && window.navigator.storage && window.navigator.storage.estimate) {
    try {
      return window.navigator.storage.estimate().then(({
        quota,
        usage
      }) => {
        if (!quota || !usage) {
          throw new Error('Browser storage estimate APIs did not perform as expected');
        }
        return quota - usage;
      }).catch(earlyResolve);
    } catch (e) {
      return earlyResolve(e);
    }
  }
  return earlyResolve();
};

/**
 * The StorageManager API has no support in Edge, IE or Safari. This utility provides a best-estimate attempt at catching
 * the case when storage is too full to accomodate any more data. The function will **only** return true if storage quotas
 * are available and indicate storage is full. If storage quotas are unknown, a warning will be logged, but the function
 * will return false.
 * @returns {Promise<void>}
 * @private
 */
const testQuota = () => {
  return getAvailable().catch(earlyResolve).then(availableStorage => {
    if (!availableStorage) {
      if (enviro.getShort() !== 'prod') {
        console.warn('Support for making estimate of available storage is missing on this browser.');
      }
      // If we can't get an estimate, we're still going to *try* setting the data. Otherwise, Safari would never set anything.
      return Promise.resolve();
    }
    if (availableStorage < MIN_SIZE) {
      if (enviro.getShort() !== 'prod') {
        console.warn(CAPACITY_ERROR);
      }
      return Promise.reject(CAPACITY_ERROR);
    }
    return Promise.resolve();
  });
};
export default testQuota;