'use es6';

import { getCookie } from 'hub-http/helpers/cookies';
class Cookies {
  get(name) {
    return getCookie(name, document.cookie || '');
  }
  create(name, value, meta = {}) {
    if (typeof meta.expires === 'number') {
      meta.expires = new Date(Date.now() + meta.expires * 864e5).toUTCString();
    }
    let metaString = '';
    for (const mKey in meta) {
      if (!Object.prototype.hasOwnProperty.call(meta, mKey)) {
        continue;
      }
      const mValue = meta[mKey];
      if (!mValue) {
        continue;
      }
      if (mValue === true) {
        metaString = `${metaString}; ${mKey}`;
      } else {
        metaString = `${metaString}; ${mKey}=${mValue}`;
      }
    }
    return `${encodeURIComponent(name)}=${value}${metaString}`;
  }
  set(name, value, meta = {}) {
    return document.cookie = this.create(name, value, meta);
  }
}
export default new Cookies();