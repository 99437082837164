/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { createIndexedDBSuperstore } from 'superstore';
import { STORE_CONFIGURATION } from '../constants';
import { ensureFn, ensurePromise } from './helpers';
const fallback = {};
export function getDefaultStore() {
  // The configuration object needs to be immutable as superstore
  // Seems to be mutating the original options
  return createIndexedDBSuperstore(Object.assign({}, STORE_CONFIGURATION));
}
export function getAsyncStore(store) {
  store = store || getDefaultStore();
  const flushConnection = database => {
    const closeConnection = ensureFn(database.close).bind(store);

    // Sometimes the database autocloses, hence why we need to catch the flush of the connection
    // Which is an odd thing as there are no concurrent connections
    // As transactions get resolved when an operation is resolved
    return ensurePromise(closeConnection()).catch();
  };
  const commitAndFlush = ({
    db,
    transaction
  }) => {
    return new Promise(resolveTransaction => {
      const resolveResult = result => {
        return flushConnection(db).then(() => resolveTransaction(ensurePromise(result))).catch(() => resolveTransaction(ensurePromise(result)));
      };

      // Attempts to resolve the Transaction result and then close the open connection
      // And finally send the resolved result back to the original caller
      return ensurePromise(transaction).then(resolveResult).catch(resolveTransaction);
    });
  };
  return (method, ...args) => {
    // Here we're using the concept of atomic commits by opening a connection
    // And then closing exactly after executing the request. This allows us
    // To ensure the transaction will always happen with an open connection
    // And if a connection was not possible, it will also not attempt to close the connection
    const _connection = ensureFn(store.open).bind(store)();
    return new Promise(resolve => {
      const startTransaction = (connection = fallback) => {
        return new Promise(commit => {
          // This Promise resolves the Database Connection and then executes the Transaction
          const executeOperation = (db = fallback) => {
            const operation = ensureFn(db[method]);

            // After getting the database connection we attempt to execute the
            // requested operation and then we forward the transaction result promise
            // With the connection to the next part of the Promise
            return ensurePromise(operation.bind(db)(...args)).then(transaction => commit({
              db,
              transaction
            })).catch(() => commit({
              db,
              transaction: Promise.resolve()
            }));
          };

          // If the connection fails to resolve we simply call the upper level `resolve`
          // As it means we will not be able to execute the operation, then neither flush the transaction
          // Hence why resolving the upper level Promise directly from here
          return ensurePromise(connection).then(executeOperation).catch(resolve);
        });
      };

      // If the database connection failed `db` will be undefined
      // Hence why we check if db is defined and then we attempt
      // to get the method from it and then bind it and resolve it
      return startTransaction(_connection).then(commitAndFlush).then(resolve).catch(resolve);
    });
  };
}