/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable guard-for-in */

'use es6';

class Dom {
  constructor(doc) {
    this.document = doc || window.document;
  }

  /**
   * Create a DOM element
   *
   * @param string        type        Type of DOM node to create
   * @param array         attributes  Optional. Map of attributes to add.
   * @param array||string children    Array of DomNodes to apppend to the new element or a string text to add
   * @return DomNode
   */
  createElement(type, attributes, children) {
    let element = this.document.createElement(type);
    if (attributes) {
      this.setAttributes(element, attributes);
    }
    if (children) {
      // Add text or child elements
      if (typeof children === 'string') {
        const text = this.document.createTextNode(children);
        element.appendChild(text);
      } else {
        children.forEach(child => {
          element.appendChild(child);
        });
      }
    }
    return element;
  }

  /**
   * Create an element from a HTML string
   *
   * @param string html
   * @return DomNode
   */
  createElementFromHtml(html) {
    let element = this.document.createElement('template');
    element.insertAdjacentHTML('afterbegin', html);
    let firstChild = element.firstChild;
    while (firstChild != null && firstChild.nodeType == 3) {
      firstChild = firstChild.nextSibling;
    }
    return firstChild;
  }
  getBody() {
    return this.document.body;
  }
  getElementById(id) {
    return this.document.getElementById(id);
  }
  setAttributes(element, attributes) {
    for (let attribute in attributes) {
      element.setAttribute(attribute, attributes[attribute]);
    }
    return element;
  }
}
export default Dom;