module.exports = {
  "ws-0013": {
    "identifierType": "OTHER",
    "parameters": {
      "group": [
        "control",
        "variantA",
        "variantB"
      ]
    }
  }
};