'use es6';

import Severity from '../constants/Severity';
class EventData {
  constructor(alarms) {
    const aboveNav = [];
    const belowNav = [];
    this.alarms = alarms;
    this.groupedAlarms = alarms.reduce((map, alarm) => {
      const alarmSeverity = alarm.severity.key;
      map[alarmSeverity] = map[alarmSeverity] || [];
      if (alarm.severity === Severity.CRITSIT) {
        aboveNav.push(alarm);
      } else {
        belowNav.push(alarm);
      }
      map[alarmSeverity].push(alarm);
      return map;
    }, {});
    this.placement = {
      aboveNav,
      belowNav
    };
  }
}
export default EventData;