import { Environment } from 'signup-ui-lego-core/constants/Environment';
import { getSignupQueryString } from './utils/getSignupQueryString';
export class SignupEmbedderEnvironment {
  constructor(embedder) {
    this.embedder = embedder;
  }
  getEnvExtension() {
    return [Environment.local, Environment.qa].includes(this.embedder.getEnvironment()) ? 'qa' : '';
  }
  getSignupSubdomain() {
    return this.embedder.getEnvironment() === Environment.local ? 'local' : 'app';
  }
  getSignupDomain() {
    const subdomain = this.getSignupSubdomain();
    const extension = this.getEnvExtension();
    return `https://${subdomain}.hubspot${extension}.com`;
  }
  getSignupUrl(asPopup = false, isPopupBlocked = false, embedderOptionsToKeepForPopupBlocked = []) {
    const signupEmbedderOptions = this.embedder.getOptions();
    if (isPopupBlocked) {
      signupEmbedderOptions.setPopupBlocked();
    }
    const embedderOptionsToUse = isPopupBlocked ? signupEmbedderOptions.getOptions().filter(embedderOption => {
      const embedderOptionKey = embedderOption[0];
      return embedderOptionsToKeepForPopupBlocked.includes(embedderOptionKey);
    }) : this.embedder.getOptions().getQueryStrings();
    const query = getSignupQueryString(embedderOptionsToUse, this.embedder.getQuery());
    const flow = this.embedder.getFlow();
    const domain = this.getSignupDomain();
    const url = `${domain}/signup-hubspot/${flow}${query}`;
    const urlObject = new URL(url);
    if (asPopup && !isPopupBlocked) {
      urlObject.searchParams.append('isSignupPopup', '1');
    }
    const affiliateLink = signupEmbedderOptions.affiliateLink;
    if (affiliateLink) {
      return `${affiliateLink}?u=${encodeURIComponent(urlObject.toString())}`;
    }
    return urlObject.toString();
  }
  getLoginUrl(asPopup = false, isPopupBlocked = false, embedderOptionsToKeepForPopupBlocked = []) {
    return `https://app.hubspot${this.getEnvExtension()}.com/login?loginRedirectUrl=${encodeURIComponent(this.getSignupUrl(asPopup, isPopupBlocked, embedderOptionsToKeepForPopupBlocked))}`;
  }
}