export const TEXT_RESET_STYLES = {
  boxSizing: 'border-box',
  fontSize: 'unset',
  fontStyle: 'unset',
  fontWeight: 'unset',
  textTransform: 'unset',
  margin: 0,
  padding: 0,
  backgroundColor: 'unset'
};
export const TEXT_VISUALLY_HIDDEN_STYLES = {
  border: '0px',
  padding: '0px',
  margin: '0px',
  position: 'absolute',
  height: '1px',
  width: '1px',
  overflow: 'hidden',
  clip: 'rect(1px, 1px, 1px, 1px)',
  clipPath: 'inset(50%)',
  whiteSpace: 'nowrap'
};