'use es6';

import { ensureFn } from './helpers';
import { globalInstanceCache } from './cache';
import * as loggers from './loggers';
import * as errors from './errors';
import * as constants from '../constants';
import * as messages from '../messages';
import * as mockedModules from '../mocks';
export function validateWithDefaults(onError, caller) {
  let validationErrors = 0;
  const createWarning = warning => loggers.logWarning(`[laboratory-lib "${caller}"] ${warning}`);
  const createError = (error, test) => {
    if (test === true) {
      // We use this just as a flag that we encountered errors
      validationErrors++;
      ensureFn(onError)(errors.createErrorObject(caller, error), {
        fingerprint: ['laboratory-lib', 'lab:validators'],
        tags: {
          'laboratory-lib:error': 'validation'
        }
      });
    }
    return test === true;
  };
  const createFatalError = (error, test) => {
    if (test === true) {
      createError(error, test);

      // In certain cases we've reached a non-recoverable situation
      // Hence we need to stop the execution of the App
      // These circumstances are rare, but exist.
      throw errors.createErrorObject(caller, error);
    }
  };
  const createErrorWithDefault = (error, test, value = undefined, defaults = undefined) => createError(error, test) ? defaults : value;
  function validateStore(store) {
    const isStoreInvalidShape = store !== null && typeof store !== 'object' || store && (typeof store.has !== 'function' || typeof store.get !== 'function' || typeof store.set !== 'function' || typeof store.delete !== 'function');

    // If the store is null the `adaptAsyncStore` function will create a mocked store
    const validatedStore = createErrorWithDefault(messages.ERROR_MESSAGES.storeInvalid, isStoreInvalidShape, store, null);
    return {
      store: validatedStore
    };
  }
  function validateTimeout(timeout) {
    const isTimeoutInvalid = isNaN(timeout) || typeof timeout !== 'number' || timeout < constants.TIMEOUT_MIN_MS || timeout > constants.TIMEOUT_MAX_MS;
    const validatedTimeout = createErrorWithDefault(messages.ERROR_MESSAGES.timeoutInvalid, isTimeoutInvalid, timeout, constants.TIMEOUT_DEFAULT);
    return {
      timeout: validatedTimeout
    };
  }
  function validateCeid(ceid) {
    const isCeidInvalidShape = ceid !== null && typeof ceid !== 'string' && typeof ceid !== 'number';
    let validatedCeid = createErrorWithDefault(messages.ERROR_MESSAGES.ceidInvalid, isCeidInvalidShape, ceid, null);
    const isCeidEmptyOrPositiveNumber = typeof validatedCeid === 'string' && ceid.trim().length > 0 || typeof validatedCeid === 'number' && ceid > 0;
    const isCeidNotNullAndNotValid = validatedCeid !== null && !isCeidEmptyOrPositiveNumber;
    if (isCeidInvalidShape || isCeidNotNullAndNotValid) {
      createWarning(messages.WARNING_MESSAGES.invalidCeidDisableCeid);
    }
    if (isCeidEmptyOrPositiveNumber) {
      // @deprecated `ceid` is deprecated and will be removed in the future once BE's support PR
      // generation for Copy Experiments. This should be removed together with all `ceid` related logic
      createWarning(messages.WARNING_MESSAGES.ceidDeprecationNotice);
    }
    if (isCeidNotNullAndNotValid) {
      validatedCeid = null;
    }
    return {
      ceid: validatedCeid
    };
  }
  function validateOperationMode(operationMode) {
    let validatedOperationMode = createErrorWithDefault(messages.ERROR_MESSAGES.operationModeInvalid, operationMode !== null && typeof operationMode !== 'string', operationMode, constants.DEFAULT_OPERATION_MODE);
    if (typeof operationMode === 'string') {
      validatedOperationMode = createErrorWithDefault(messages.ERROR_MESSAGES.operationModeInvalidOption, !['default', 'cache-only', 'network-only'].includes(operationMode), operationMode, constants.DEFAULT_OPERATION_MODE);
    }
    if (validatedOperationMode === null) {
      validatedOperationMode = constants.DEFAULT_OPERATION_MODE;
    }
    return {
      mode: validatedOperationMode
    };
  }
  function validateTreatmentsSchema(treatments, experiments) {
    const resultingTreatments = createErrorWithDefault(messages.ERROR_MESSAGES.treatmentsNotObject, treatments && typeof treatments !== 'object', treatments, null);
    if (resultingTreatments) {
      // @deprecated The `treatments` parameter is deprecated and should not be used
      // the `experiments` parameter should be used as it is the new `experiments.yaml` feature
      createWarning(messages.WARNING_MESSAGES.treatmentsDeprecationNotice);
    }

    // If treatments are provided when experiments are also provided, we report an error
    // as only one or another can be used, by default experiments will have higher precedence
    createError(messages.ERROR_MESSAGES.experimentsProvidedWithTreatments, Boolean(resultingTreatments && experiments));
    Object.keys(resultingTreatments || {}).forEach(k1 => {
      const treatment = treatments[k1];
      const isTreatmentInvalid = !treatment || typeof treatment !== 'object' || !treatment.identifier || !treatment.parameters;
      resultingTreatments[k1] = createErrorWithDefault(messages.ERROR_MESSAGES.treatmentInvalid(k1), isTreatmentInvalid, treatment, null);
      if (resultingTreatments[k1]) {
        const validatedTreatment = resultingTreatments[k1];
        const isMaxAgeInvalid = validatedTreatment.maxAge && (typeof validatedTreatment.maxAge !== 'number' || validatedTreatment.maxAge < 0);
        resultingTreatments[k1].maxAge = createErrorWithDefault(messages.ERROR_MESSAGES.treatmentMaxAgeInvalid(k1), isMaxAgeInvalid, validatedTreatment.maxAge, constants.DEFAULT_MAX_AGE);
        resultingTreatments[k1].parameters = createErrorWithDefault(messages.ERROR_MESSAGES.treatmentParametersInvalid(k1), typeof validatedTreatment.parameters !== 'object', validatedTreatment.parameters,
        // This will force that a network request is done if cache is not available
        // As the parameters are not valid
        {});

        // As the original `resultingTreatments` was mutated we cannot use `validatedTreatment` here
        Object.keys(resultingTreatments[k1].parameters).forEach(k2 => {
          const resultingParameter = resultingTreatments[k1].parameters[k2];
          const isTreatmentParameterInvalid = !Array.isArray(resultingParameter) || !resultingParameter.length;
          resultingTreatments[k1].parameters[k2] = createErrorWithDefault(messages.ERROR_MESSAGES.treatmentParameterInvalid(k1, k2), isTreatmentParameterInvalid, resultingParameter, null);

          // If the parameter validation failed we remove the parameter
          // As it will diverge from the original schema
          // If in the end all parameters are invalid, laboratory-lib
          // will force a network request to get the parameters
          if (resultingTreatments[k1].parameters[k2] === null) {
            delete resultingTreatments[k1].parameters[k2];
          }
        });
        return;
      }

      // We delete all the treatments that failed the minimum validation
      // As they're invalid data and can't really be used for anything here
      delete resultingTreatments[k1];
    });

    // When there are no `treatments` we default them to an empty object
    // This is needed as if no `experiments` or `treatments` are given
    // We will then default to an empty `treatments` object
    return {
      treatments: resultingTreatments || {}
    };
  }
  function validateIdentifiersSchema(identifiers) {
    const resultingIdentifiers = createErrorWithDefault(messages.ERROR_MESSAGES.identifiersNotObject, identifiers && typeof identifiers !== 'object', identifiers, {});
    const validIdentifierKeys = Object.values(constants.IDENTIFIER_TYPE_MAP);
    const validIdentifierKeysString = validIdentifierKeys.join('", "');
    Object.keys(resultingIdentifiers || {}).forEach(k1 => {
      createError(messages.ERROR_MESSAGES.identifierTypeInvalid(validIdentifierKeysString, k1), !validIdentifierKeys.includes(k1));

      // We remove all the invalid identifier types as they're not valid
      // and we cannot assess what they were originally meant to be
      if (!validIdentifierKeys.includes(k1)) {
        delete resultingIdentifiers[k1];
      }
    });

    // This one returns directly the values as it is called internally only
    // As the identifiers are part of the experiments schema
    return resultingIdentifiers;
  }
  function validateExperimentsSchema(experiments, rawIdentifiers) {
    const defaultedExperiments = createErrorWithDefault(messages.ERROR_MESSAGES.experimentsNotObject, experiments && typeof experiments !== 'object', experiments, null);
    const identifiers = validateIdentifiersSchema(rawIdentifiers);

    // If experiments are defined and valid but no identifiers were provided (aka identifiers is null)
    // then we should also report as identifiers is a mandatory argument when experiments is provided
    createError(messages.ERROR_MESSAGES.identifiersNotProvidedWithExperiment, defaultedExperiments && !identifiers);

    // If identifiers was not provided fallback but give a warning
    const resultingIdentifiers = identifiers ? Object.assign({}, identifiers) : {};
    const validIdentifierTypes = Object.keys(constants.IDENTIFIER_TYPES);
    const validIdentifierTypesString = validIdentifierTypes.join('", "');
    if (defaultedExperiments === null) {
      return {
        experiments: null,
        identifiers: resultingIdentifiers
      };
    }
    const resultingExperiments = Object.entries(defaultedExperiments).reduce((acc, [k1, experiment]) => {
      const experimentIsMalformed = createError(messages.ERROR_MESSAGES.experimentInvalid(k1), typeof experiment !== 'object' || !experiment || !experiment.identifierType || !experiment.parameters);
      if (experimentIsMalformed) {
        return acc;
      }
      const experimentHasMalformedIdentifierType = createError(messages.ERROR_MESSAGES.experimentIdentifierTypeInvalid(k1), typeof experiment.identifierType !== 'string');
      if (experimentHasMalformedIdentifierType) {
        return acc;
      }
      const experimentHasInvalidIdentifierType = createError(messages.ERROR_MESSAGES.experimentInvalidIdentifierType(validIdentifierTypesString, experiment.identifierType, k1), !validIdentifierTypes.includes(experiment.identifierType));
      if (experimentHasInvalidIdentifierType) {
        return acc;
      }
      const experimentIdentifierKey = constants.IDENTIFIER_TYPE_MAP[experiment.identifierType];
      const experimentIdentifierTypeValueWasNotProvided = createError(messages.ERROR_MESSAGES.identifierTypeNotIncludedInIdentifiers(experimentIdentifierKey, k1), !Object.keys(resultingIdentifiers).includes(experimentIdentifierKey));
      if (experimentIdentifierTypeValueWasNotProvided) {
        return acc;
      }
      experiment.maxAge = createErrorWithDefault(messages.ERROR_MESSAGES.experimentMaxAgeInvalid(k1), experiment.maxAge && (typeof experiment.maxAge !== 'number' || experiment.maxAge < 0), experiment.maxAge, constants.DEFAULT_MAX_AGE);
      const experimentParametersAreMalformed = typeof experiment.parameters !== 'object';
      experiment.parameters = createErrorWithDefault(messages.ERROR_MESSAGES.experimentParametersInvalid(k1), experimentParametersAreMalformed, experiment.parameters,
      // This will force that a network request is done if cache is not available
      // As the parameters are not valid
      {});
      const experimentParameters = Object.keys(experiment.parameters);
      createError(messages.ERROR_MESSAGES.experimentWithoutParameters(k1), experimentParametersAreMalformed || experimentParameters.length === 0);
      experimentParameters.forEach(k2 => {
        const experimentParameterIsMalformed = createError(messages.ERROR_MESSAGES.experimentParameterInvalid(k1, k2), !Array.isArray(experiment.parameters[k2]) || !experiment.parameters[k2].length);

        // If the parameter validation failed we remove the parameter
        // As it will diverge from the original schema
        // If in the end all parameters are invalid, laboratory-lib
        // will force a network request to get the parameters
        if (experimentParameterIsMalformed) {
          delete experiment.parameters[k2];
        }
      });
      return Object.assign({}, acc, {
        [k1]: experiment
      });
    }, {});
    return {
      experiments: resultingExperiments,
      identifiers: resultingIdentifiers
    };
  }
  function validateClientInstance(clientInstance) {
    createFatalError(messages.FATAL_ERROR_MESSAGES.invalidClientInstance, !clientInstance || typeof clientInstance !== 'object' || typeof clientInstance.resolve !== 'function' || typeof clientInstance.logExposure !== 'function' || typeof clientInstance.logExposures !== 'function');
  }
  function validateHttp(hubHttpInstance) {
    const validateInstance = httpInstanceCache => {
      const isHttpModuleInvalid = !httpInstanceCache || typeof httpInstanceCache.post !== 'function' || typeof httpInstanceCache.get !== 'function';
      return createErrorWithDefault(messages.ERROR_MESSAGES.httpModuleInvalid, isHttpModuleInvalid, httpInstanceCache, mockedModules.MOCK_HTTP_CLIENT);
    };
    const httpResolver = () => {
      // Acts as a cached value so it only needs to resolve once
      let httpInstanceCache = hubHttpInstance;
      return new Promise(resolve => {
        if (httpInstanceCache === undefined || httpInstanceCache === null) {
          // Attempts to import `hub-http` only when needed as a dynamic imnport
          // If the import fails we do nothing and continue with the mocked hub-http instance
          // As we migth not even need hub-http if all the experiments are cached
          // If we need hub-http then the mocked hub-http will let them know that they didn't
          // have any hub-http instance to begin with
          import('hub-http/clients/noAuthApiClient').then(({
            default: resolvedInstance
          }) => {
            // Updates the cache with the resolved `hub-http` instance
            // So that future resolutions use the cached value
            httpInstanceCache = resolvedInstance;
          }).catch() // We do nothing here as we will fallback to the mocked hub-http instance
          .finally(() => resolve(validateInstance(httpInstanceCache)));
          return;
        }
        resolve(validateInstance(httpInstanceCache));
      });
    };

    // This means that the http module will be resolved on-demand
    return {
      http: httpResolver
    };
  }
  function validateQuickFetch(quickFetchInstance, quickFetchLabel) {
    const validateInstance = quickFetchCache => {
      const isQuickFetchModuleInvalid = !quickFetchCache || typeof quickFetchCache !== 'object' || typeof quickFetchCache.getRequestStateByName !== 'function' || typeof quickFetchCache.makeEarlyRequest !== 'function' || typeof quickFetchCache.getApiUrl !== 'function';
      return createErrorWithDefault(messages.ERROR_MESSAGES.quickFetchModuleInvalid, isQuickFetchModuleInvalid, quickFetchCache, mockedModules.MOCK_QUICK_FETCH_CLIENT);
    };
    const quickFetchResolver = () => {
      let quickFetchCache = quickFetchInstance;
      return new Promise(resolve => {
        if (quickFetchLabel) {
          if (quickFetchCache === undefined || quickFetchCache === null) {
            // Attempts to import `quick-fetch` only when needed as a dynamic imnport
            // If the import fails we do nothing and continue with the mocked quick-fetch instance
            // As we migth not even need quick-fetch if all the experiments are cached
            // If we need quick-fetch then the mocked quick-fetch will let them know that they didn't
            // have any quick-fetch instance to begin with
            import('quick-fetch').then(({
              default: resolvedInstance
            }) => {
              // Updates the cache with the resolved `quick-fetch` instance
              // So that future resolutions use the cached value
              quickFetchCache = resolvedInstance;
            }).catch() // We do nothing here as we will fallback to the mocked quick-fetch instance
            .finally(() => resolve(validateInstance(quickFetchCache)));
            return;
          }
          resolve(validateInstance(quickFetchCache));
        }
        resolve(mockedModules.MOCK_QUICK_FETCH_CLIENT);
      });
    };
    return {
      quickFetch: quickFetchResolver
    };
  }
  function validateQuickFetchLabel(quickFetchLabel) {
    const isQuickFetchLabelInvalidShape = quickFetchLabel !== null && typeof quickFetchLabel !== 'string';
    let validatedQuickFetchLabel = createErrorWithDefault(messages.ERROR_MESSAGES.quickFetchLabelInvalid, isQuickFetchLabelInvalidShape, quickFetchLabel, null);
    const isQuickFetchLabelEmptyString = typeof validatedQuickFetchLabel === 'string' && validatedQuickFetchLabel.trim().length === 0;
    if (isQuickFetchLabelInvalidShape || isQuickFetchLabelEmptyString) {
      // We prefer to disable quick-fetch at all if the label is invalid
      // This is a security measure to prevent accidental use of quick-fetch
      createWarning(messages.WARNING_MESSAGES.invalidQuickFetchLabelDisableQuickFetch);
    }
    if (isQuickFetchLabelEmptyString) {
      validatedQuickFetchLabel = null;
    }
    return {
      quickFetchLabel: validatedQuickFetchLabel
    };
  }
  function validateApiDomain(apiDomain) {
    const isApiDomainInvalidShape = apiDomain !== null && typeof apiDomain !== 'string';
    let validatedApiDomain = createErrorWithDefault(messages.ERROR_MESSAGES.apiDomainInvalid, isApiDomainInvalidShape, apiDomain, constants.DEFAULT_API_DOMAIN);
    const isApiDomainEmptyString = typeof validatedApiDomain === 'string' && validatedApiDomain.trim().length === 0;
    if (isApiDomainInvalidShape || isApiDomainEmptyString) {
      createWarning(messages.WARNING_MESSAGES.invalidApiDomainFallbackToDefault);
    }
    if (validatedApiDomain === null || isApiDomainEmptyString) {
      validatedApiDomain = constants.DEFAULT_API_DOMAIN;
    }
    return {
      apiDomain: validatedApiDomain
    };
  }
  function validateExposures(treatmentKeys) {
    const validatedExposures = createErrorWithDefault(messages.ERROR_MESSAGES.exposuresInvalid, !Array.isArray(treatmentKeys), treatmentKeys, []);
    const uniqueExposures = new Set();
    validatedExposures.forEach(treatmentKey => {
      const isTreatmentKeyValid = typeof treatmentKey === 'string' && treatmentKey.trim().length > 0;
      createError(messages.ERROR_MESSAGES.exposureItemToBeDefined, !isTreatmentKeyValid);
      if (isTreatmentKeyValid) {
        uniqueExposures.add(treatmentKey);
      }
    });
    return {
      treatmentKeys: [...uniqueExposures]
    };
  }
  function validateInstanceName(instanceName) {
    const isInstanceNameInvalidShape = instanceName !== null && typeof instanceName !== 'string';
    let validatedInstanceName = createErrorWithDefault(messages.ERROR_MESSAGES.instanceNameInvalid, isInstanceNameInvalidShape, instanceName, '');
    const isInstanceNameEmptyString = typeof validatedInstanceName === 'string' && validatedInstanceName.trim().length === 0;
    if (isInstanceNameInvalidShape || isInstanceNameEmptyString) {
      createWarning(messages.WARNING_MESSAGES.invalidInstanceNameDisableCache);
    }
    if (validatedInstanceName === null || isInstanceNameEmptyString) {
      const currentLength = globalInstanceCache.size;

      // We set a instance name if none is given, but since the user didn't explicit
      // any instance name, and we want to avoid colision, we simply set a default one with
      // an incremental number of the current number of instances + 1
      validatedInstanceName = `default-${currentLength + 1}`;
    }
    return {
      name: validatedInstanceName
    };
  }
  return {
    validateCeid,
    validateHttp,
    validateStore,
    validateTimeout,
    validateExposures,
    validateApiDomain,
    validateOperationMode,
    validateQuickFetch,
    validateQuickFetchLabel,
    validateTreatmentsSchema,
    validateExperimentsSchema,
    validateClientInstance,
    validateInstanceName,
    checkValidationErrors: () => {
      if (validationErrors > 0) {
        createWarning(messages.WARNING_MESSAGES.didEncounterErrors(validationErrors));
      }
    }
  };
}