import { DEFAULT_API_DOMAIN } from './constants';
import { ERROR_MESSAGES } from './messages';
// This mocked version of a `hub-http` client is used when validating and/or resolving `hub-http` failed
// leaving to this mocked client instance which will always fail, the intention here
// is to during runtime let the users know they have a faulty installation of `laboratory-lib`
// this can be caused due to missing `hub-http` or `hubspot-dlb` dependencies.
const MOCK_HTTP_CLIENT = {
  get: () => new Promise((_onSuccess, onError) => onError(ERROR_MESSAGES.faultyHttpModule)),
  post: () => new Promise((_onSuccess, onError) => onError(ERROR_MESSAGES.faultyHttpModule))
};

// This mocked version of `quick-fetch` is used when we don't need the `quick-fetch` instance
// (e.g. when the app is using `hub-http` instead of `quick-fetch`), but in some cases
// this is is also used when validating and/or resolving `quick-fetch` failed
// this can be caused either by missing `quick-fetch` dependency or
// `quick-fetch` was not correctly installed on the App's HTML templates
const MOCK_QUICK_FETCH_CLIENT = {
  getRequestStateByName: () => ({
    whenFinished: () => {},
    onError: callback => {
      callback(null, ERROR_MESSAGES.faultyQuickFetchModule);
    }
  }),
  makeEarlyRequest: () => {},
  getApiUrl: () => DEFAULT_API_DOMAIN
};

// This is a mocked version of the `superstore` IDB client, this is used for testing environments
// And allows us to not rely on `superstore` or IndexedDB's at all during a testing environment
// Note.: This mock is different from the one used on `test/mock/mockModules.js@getHackableStore`
// since here we want a fully dummy implementation of `superstore` and not a hackable one.
const MOCK_SUPERSTORE_INDEXED_CLIENT = {
  _objectStoreName: '',
  _dbName: '',
  _checkInit: () => Promise.resolve(),
  open: () => Promise.resolve(MOCK_SUPERSTORE_INDEXED_CLIENT),
  close: () => Promise.resolve(),
  has: () => Promise.resolve(true),
  get: () => Promise.resolve(),
  set: () => Promise.resolve(),
  delete: () => Promise.resolve(),
  getAllKeys: () => Promise.resolve([]),
  getAllRecords: () => Promise.resolve(),
  clear: () => Promise.resolve()
};
export { MOCK_HTTP_CLIENT, MOCK_QUICK_FETCH_CLIENT, MOCK_SUPERSTORE_INDEXED_CLIENT };