'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["updatedAt"],
  _excluded2 = ["key"],
  _excluded3 = ["updatedAt"];
import { DEFAULT_CACHE_STATUS, DEFAULT_MAX_AGE } from '../constants';
import { hasValidStatus } from '../resolvers/treatments';
import { ensureFn } from './helpers';
function extractProperty(objs = [], property = '', defaultValue = '') {
  const match = objs.find(obj => obj && typeof obj[property] !== 'undefined');
  return match ? match[property] : defaultValue;
}
function isExpired(updatedAt, maxAge) {
  const timeInStoreMs = Math.abs(Date.now() - updatedAt);
  return timeInStoreMs > maxAge;
}
function getCacheKey(treatmentKey, paramKey) {
  return `${treatmentKey}-${paramKey}`;
}
export function getCacheKeys({
  schema
}) {
  return Object.keys(schema).reduce((acc, treatmentKey) => {
    // maxAge needs to by default be undefined otherwise it
    // will set the maxAge for all Experiments to 0
    const {
      parameters,
      maxAge = undefined
    } = schema[treatmentKey];
    const parameterKeys = Object.keys(parameters);
    parameterKeys.forEach(paramKey => {
      acc.push({
        key: getCacheKey(treatmentKey, paramKey),
        maxAge
      });
    });
    return acc;
  }, []);
}
export function getOverridesFromCache({
  cacheKeys,
  asyncStore,
  onError
}) {
  const onErrorWithExtra = e => {
    ensureFn(onError)(e, {
      fingerprint: ['laboratory-lib', 'lab:getOverridesFromCache'],
      tags: {
        'laboratory-lib:error': 'cache:get'
      }
    });
  };

  // Despite the name, cacheKeys is a Record constructed from the Experiments Schemas
  // Containing the maxAge and the treatmentKey as a value and the "cache key" as the key
  const asyncStorePromises = cacheKeys.map((cacheKey = {}) => {
    return new Promise(resolve => {
      return asyncStore('get', cacheKey.key).then(cacheEntry => {
        const _ref = cacheEntry || {},
          {
            updatedAt = 0
          } = _ref,
          cacheObject = _objectWithoutPropertiesLoose(_ref, _excluded);

        // Checks if the cache entry has a value, a valid updatedAt (non-zero)
        // and if the status is a valid status object, if yes then it
        // checks if the cache entry hasn't expired.
        // If any of the conditions are falsey, network requests might be needed
        if (cacheObject.value && updatedAt && hasValidStatus(cacheObject)) {
          const treatmentKey = extractProperty([cacheKey, cacheObject], 'key', '');
          const resolvedMaxAge = extractProperty([cacheKey, cacheObject], 'maxAge', DEFAULT_MAX_AGE);
          if (!isExpired(updatedAt, resolvedMaxAge) && treatmentKey) {
            resolve({
              key: treatmentKey,
              value: cacheObject.value || '',
              status: cacheObject.status
            });
          }
        }
      }).catch(onErrorWithExtra).finally(resolve);
    });
  });
  const mapCacheToEntries = overrides =>
  // Maps the { key, value, status } into an object of { [key]: { value, status} .. }
  overrides.filter(entry => !!entry).map(_ref2 => {
    let {
        key
      } = _ref2,
      entry = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return [key, entry];
  });

  // This is the safest asynchronous way that avoids racing conditions where
  // Where the IndexedDB Promise fullfils before the checks of the cache entry end
  // So we purposelly return a Promise that resolves once all checks are done
  // This also allows us to then map the final result into the format we want
  return Promise.all(asyncStorePromises).then(overrides => Object.fromEntries(mapCacheToEntries(overrides)));
}
export function saveOverridesToCache(args = {}) {
  const {
    schema,
    treatments,
    asyncStore,
    onError
  } = args;
  const onErrorWithExtra = e => {
    ensureFn(onError)(e, {
      fingerprint: ['laboratory-lib', 'lab:saveOverridesToCache'],
      tags: {
        'laboratory-lib:error': 'cache:save'
      }
    });
  };
  Object.keys(schema).forEach(treatmentKey => {
    const treatment = treatments[treatmentKey] || {};
    const definition = schema[treatmentKey];
    const treatmentParameters = treatment.parameters || [];
    Object.keys(treatmentParameters).forEach(paramKey => {
      const cacheKey = getCacheKey(treatmentKey, paramKey);
      asyncStore('get', cacheKey).then(cacheEntry => {
        const _ref3 = cacheEntry || {},
          {
            updatedAt = 0
          } = _ref3,
          cacheObject = _objectWithoutPropertiesLoose(_ref3, _excluded3);
        const resolvedMaxAge = extractProperty([definition, treatment, cacheObject], 'maxAge', DEFAULT_MAX_AGE);
        const resolvedStatus = extractProperty([treatment, cacheObject], 'status', DEFAULT_CACHE_STATUS);
        const value = treatmentParameters[paramKey];
        const isCacheExpiredOrInvalid =
        // Checks if the cache entry is expired or if the cache entry
        // lacks a `status` object meaning we need to revalidate the cache
        isExpired(updatedAt, resolvedMaxAge) || !cacheObject.status;

        // If the treatment is being overridden we shouldn't cache the treatment at all
        // and prevent caching at all costs whilst it's being overridden
        const isOverridden = resolvedStatus.isOverridden;
        if (resolvedMaxAge > 0 && isCacheExpiredOrInvalid && !isOverridden) {
          const cachedStatus = Object.assign({}, resolvedStatus, {
            isCached: true,
            isOverridden: false
          });
          const cacheValue = {
            key: cacheKey,
            updatedAt: Date.now(),
            status: cachedStatus,
            maxAge: resolvedMaxAge,
            value
          };
          asyncStore('set', cacheKey, cacheValue).catch(onErrorWithExtra);
        } else if (!!updatedAt && resolvedMaxAge === 0) {
          asyncStore('delete', cacheKey).catch(onErrorWithExtra);
        }
      }).catch(onErrorWithExtra);
    });
  });
}