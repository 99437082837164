/* hs-eslint ignored failing-rules */
/* eslint-disable array-callback-return */

'use es6';

import EventGroups from '../constants/EventGroups';
const FIREALARM_PREOADED_EVENTS_KEY = '_preLoadEvents';
class Events {
  constructor() {
    this.events = {};
    this.eventMarkers = {};
    EventGroups.keys().forEach(key => {
      const group = EventGroups[key];
      this.events[group] = [];
    });
  }
  addEvent(group, callback) {
    this.events[group] = this.events[group] || [];
    this.events[group].push(callback);
  }
  addEventMarker(marker, data) {
    this.eventMarkers[marker] = this.eventMarkers[marker] || [];
    this.eventMarkers[marker].push(data);
  }
  extractPreloadEvents(data) {
    const preloadEvents = data && data[FIREALARM_PREOADED_EVENTS_KEY] ? data[FIREALARM_PREOADED_EVENTS_KEY] : {};
    EventGroups.keys().forEach(key => {
      const group = EventGroups[key];
      if (preloadEvents[group] && Array.isArray(preloadEvents[group])) {
        preloadEvents[group].map(callback => {
          this.addEvent(group, callback);
        });
      }
    });
  }
  runEvents(eventGroup, data) {
    const eventData = {
      eventGroup,
      data
    };
    if (this.events[eventGroup]) {
      switch (eventGroup) {
        case EventGroups.eventGroups.CREATE_ALARM:
          {
            this.events[eventGroup].map(alarmData => {
              data.addAlarm(alarmData);
            });
            break;
          }
        default:
          {
            this.events[eventGroup].map(callback => {
              callback(eventData);
            });
          }
      }
    }
    this.addEventMarker(eventGroup, eventData);
  }
}
export default Events;