import * as LoginUtils from '../lib/LoginUtils';
import { OUTLOOK365 } from '../constants/LoginSources';
import http from 'hub-http/clients/noAuthApiClient';
class ConfirmToLoginDao {
  tryConfirmToLogin(credentials) {
    const source = LoginUtils.getLoginSource();
    if (source === OUTLOOK365) {
      credentials.rememberLogin = true;
    }
    return http.post('/login-api/v1/login/verification-code', {
      data: credentials
    });
  }
  generateCode(credentials) {
    return http.post('/login-api/v1/login/verification-code/new-code/', {
      data: credentials
    });
  }
}
export default ConfirmToLoginDao;