/* hs-eslint ignored failing-rules */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-const */

'use es6';

import { objectAssign } from '../util/Objects';
import Severity from '../constants/Severity';
import EventGroups from '../constants/EventGroups';
const DEFAULT_CTA_TEXT = 'View status page';
class Alarm {
  constructor(alarmData, dom, storage, events, date) {
    objectAssign(this, alarmData);
    this.dom = dom;
    this.storage = storage;
    this.events = events;
    this.date = date || Date;
    this.severity = Severity.getByKey(alarmData.severity);
    var id = this.id || this.uuid;
    this.alarmId = `alarm.${id}.revision.${this.revisionNumber}`;
    this.className = 'Alarm';
    this.storageKey = `firealarm.dismiss.${this.alarmId}`;
    this.analyticsKey = `firealarm.analytics.${this.alarmId}`;
    this.domElement = null;
    this.alarmElementId = `firealarm-${this.id || this.date.now()}`;
    this.alarmDismissButtonId = `${this.alarmElementId}-dismiss`;
  }
  dismiss() {
    this.storage.setItem(this.storageKey, 'true');
    if (this.domElement) {
      const parent = this.domElement.parentNode;
      parent.removeChild(this.domElement);
      if (!parent.hasChildNodes()) {
        parent.parentNode.removeChild(parent);
      }
    }
    this.events.runEvents(EventGroups.ON_DISMISS_ALARM, this);
  }
  getDomElement() {
    if (!this.domElement) {
      const ctaText = this.callToAction && this.callToAction.ctaText || DEFAULT_CTA_TEXT;
      this.domElement = this.dom.createElementFromHtml(`<div
          id="${this.alarmElementId}"
          class="fire-alarm_alarm fire-alarm_${this.severity.key.toLowerCase()}"
          role="alert"
          aria-live="assertive"
          aria-atomic="true" >
            <div class="fire-alarm_alarm-inner">
              <div class="fire-alarm_alarm-body">
                <h2>${this.title}</h2>
                <p>${this.message}</p>
              </div>
            </div>
        </div>`);
      if (this.callToAction) {
        const callToActionLink = this.dom.createElementFromHtml(`<a class="fire-alarm_alarm-status-page-link" href="${this.callToAction.url}" target="_blank">${ctaText}</a>`);
        this.domElement.appendChild(callToActionLink);
      }
      if (this.dismissible !== false) {
        this.renderDismissButton();
      }
    }
    return this.domElement;
  }
  renderDismissButton() {
    let dismissButton = this.dom.createElementFromHtml(`<button id=${this.alarmDismissButtonId} type="button" data-action="close" class="fire-alarm_alarm-close-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" class="alarm-close-icon">
            <path transform="translate(-1 -1)" d="M14.5,1.5l-13,13m0-13,13,13"></path>
          </svg>
          <span class="fire-alarm_alarm-button-text">Close</span>
        </button>`);
    this.domElement.appendChild(dismissButton);

    // wire up dismiss button
    // This should handle click and touch events
    if (dismissButton) {
      const onDismissButton = event => {
        event.preventDefault();
        this.dismiss();
      };
      dismissButton.addEventListener('touchend', onDismissButton);
      dismissButton.addEventListener('click', onDismissButton);
    }
  }
  isDismissed() {
    return this.storage.getItem(this.storageKey) === 'true';
  }
}
export default Alarm;