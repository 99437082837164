'use es6';

import { DEFAULT_STATUS } from '../constants';
export function adaptParametersFromApi(parameters = []) {
  return parameters.reduce((acc, parameter) => {
    acc[parameter.key] = parameter.value;
    return acc;
  }, {});
}
export function adaptTreatmentsFromApi(treatments = []) {
  return treatments.reduce((acc, treatment) => {
    acc[treatment.key] = Object.assign({}, treatment, {
      parameters: adaptParametersFromApi(treatment.parameters || [])
    });
    return acc;
  }, {});
}
export function hasValidStatus(treatment = {}) {
  if (treatment && treatment.status) {
    return typeof treatment.status.isActive === 'boolean' && typeof treatment.status.isEnrolled === 'boolean' && typeof treatment.status.isDefaulted === 'boolean' && typeof treatment.status.isOverridden === 'boolean' && typeof treatment.status.isCached === 'boolean';
  }
  return false;
}
export function shouldUseOption(options = [], value) {
  return value && options.indexOf(value) !== -1;
}
export function resolveParameter(options = [], values = []) {
  return values.find(({
    value
  }) => shouldUseOption(options, value)) || values[values.length - 1];
}
export function resolveParameters(treatmentKey, treatmentParameters = {}, treatmentStatus = {}, parametersFromSchema = {}, overridesFromCache = {}) {
  let resolvedStatus = treatmentStatus;
  const resolvedParameters = Object.keys(parametersFromSchema).reduce((acc, paramKey) => {
    const cacheKey = `${treatmentKey}-${paramKey}`;
    const valueFromNetwork = treatmentParameters[paramKey];
    const valuesFromSchema = parametersFromSchema[paramKey];
    const {
      value: valueFromCache,
      status: statusFromCache
    } = overridesFromCache[cacheKey] || {};
    const values = [{
      value: valueFromNetwork,
      status: {}
    }, {
      value: valueFromCache,
      status: statusFromCache
    }, {
      value: valuesFromSchema[0],
      status: {}
    }];
    const {
      value,
      status
    } = resolveParameter(valuesFromSchema, values);
    resolvedStatus = Object.assign({}, resolvedStatus, status);
    return Object.assign({}, acc, {
      [paramKey]: value
    });
  }, treatmentParameters);
  return {
    resolvedParameters,
    resolvedStatus
  };
}
export function resolveTreatment({
  treatment,
  definition = {},
  overridesFromCache = {}
}) {
  const treatmentParameters = treatment && treatment.parameters || {};
  const treatmentStatus = hasValidStatus(treatment) ? treatment.status : DEFAULT_STATUS;

  // To avoid the original network object and definition from being mutated
  const resolved = JSON.parse(JSON.stringify(treatment || definition));
  const {
    resolvedParameters,
    resolvedStatus
  } = resolveParameters(definition.key, treatmentParameters, treatmentStatus, definition.parameters, overridesFromCache);
  resolved.parameters = resolvedParameters;
  resolved.status = resolvedStatus;
  if (typeof definition.maxAge === 'number') {
    resolved.maxAge = definition.maxAge;
  }
  return resolved;
}
export function resolveTreatments({
  treatments = [],
  schema = {},
  overridesFromCache = {},
  // Since we do not have TypeScript this is the schema that comes from `network.js`
  // eslint-disable-next-line no-unused-vars
  networkAdapter = 'hub-http'
} = {}) {
  const adaptedTreatments = adaptTreatmentsFromApi(treatments);
  return Object.keys(schema).reduce((acc, key) => {
    const definition = schema[key];
    definition.key = key;
    const treatment = adaptedTreatments[key];
    const resolvedTreatment = resolveTreatment({
      treatment,
      definition,
      overridesFromCache
    });
    return Object.assign({}, acc, {
      [key]: resolvedTreatment
    });
  }, adaptedTreatments);
}