import PortalIdParser from 'PortalIdParser';
import { NAMESPACE_PREFIX } from '../constants';
export default (opts => {
  let storeName = opts.namespace;
  if (opts && opts.namespace) {
    storeName = `${NAMESPACE_PREFIX}.${opts.namespace}`;
  }
  if (!opts.global) {
    let partitionFn;
    if (opts.partition && typeof opts.partition === 'function') {
      partitionFn = opts.partition;
    } else {
      partitionFn = () => PortalIdParser.get();
    }
    let partitionVal = partitionFn();
    if (partitionVal) {
      partitionVal = `.${partitionVal}`;
    } else {
      partitionVal = '';
    }
    storeName = `${storeName}${partitionVal}`;
  }
  return storeName;
});