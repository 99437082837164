'use es6';

import Url from 'urlinator/Url';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import Cookies from '../../cookies';
export const REMEMBER_ME_COOKIE_NAME = 'prefs';
let REMEMBER_DEFAULT;
export const _resetRememberDefault = () => REMEMBER_DEFAULT = Cookies.get(REMEMBER_ME_COOKIE_NAME) === '1';
_resetRememberDefault();
export default {
  login(credentials, lb) {
    credentials.rememberLogin = credentials.rememberLogin !== undefined ? credentials.rememberLogin : REMEMBER_DEFAULT;
    return noAuthApiClient.post(new Url('/login-api/v1/login', {
      lb
    }).href, {
      data: credentials
    });
  },
  prep(lb) {
    return noAuthApiClient.post(new Url('/login-api/v1/login/prep', {
      lb
    }).href);
  }
};