import keyMirror from 'react-utils/keyMirror';
export default keyMirror({
  OK: null,
  TWO_FACTOR_REQUIRED: null,
  INVALID_TWO_FACTOR_TOKEN: null,
  EXPIRED_TWO_FACTOR_TOKEN: null,
  INVALID_CODE: null,
  SMS_RATE_LIMIT_HIT: null,
  APPROVED: null,
  DEACTIVATED_USER: null,
  BLOCKED_LOGIN_METHOD: null
});