import { createHashHistory, createBrowserHistory } from 'history';
import { OUTLOOK365 } from '../constants/LoginSources';

/**
 * Certain hosting environments, like O365 WebViews,
 * require hash routing as browserHistory is not supported.
 * @param {string} source
 */
const requiresHashRouting = source => source === OUTLOOK365;
export default (({
  rootUrl,
  source
}) => {
  return (requiresHashRouting(source) ? createHashHistory : createBrowserHistory)({
    basename: rootUrl
  });
});