import { combineReducers } from 'redux';
import confirmToLogin from './confirmToLogin';
import twoFactorAuth from './twoFactorAuth';
// @ts-expect-error reducer not yet converted
import twoFactorRecovery from './twoFactorRecovery';
const rootReducer = combineReducers({
  confirmToLogin,
  twoFactorAuth,
  twoFactorRecovery
});
export default rootReducer;