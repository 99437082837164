'use es6';

import enviro from 'enviro';
import * as resolvers from './core/resolvers';
import * as loggers from './core/loggers';
import * as messages from './messages';
import { validateWithDefaults } from './core/validators';
import { getAsyncStore } from './core/store';
import { globalInstanceCache } from './core/cache';

/**
 * Defines Type Defintions from the TypeScript definition file
 *
 * @typedef {import('./index').TreatmentClient} TreatmentClient
 * @typedef {import('./index').CreateClientExperimentOptions} CreateClientExperimentOptions
 */

/**
 * This ensures that the methods when called are always the ones from the cache
 * instead of the existing boilerplate instance
 *
 * If the cache entry does not exist (should never happen) then we fallback to
 * the method provided on the current instance
 *
 * @param {string} name the name of the cache entry
 * @param {keyof TreatmentClient} method the method to retrieve from the cache
 * @param {TreatmentClient[keyof TreatmentClient]} fallback the fallback method to use
 */
const getMethod = (name, method, fallback = () => {}) => globalInstanceCache.has(name) ? globalInstanceCache.get(name)[method] : fallback;

/**
 * Imports Existing Type Definitions until we migrate to TypeScript
 *
 * @param {CreateClientExperimentOptions} params
 * @returns {TreatmentClient}
 */
export function createClient(params = {}) {
  const validators = validateWithDefaults(params.onError, 'createClient');

  // This method validates the inputs given to laboratory-lib and calls the onError
  // to report the error to Sentry or custom logic defined by the user
  // it will also mutate the values from the input to default ones
  // to prevent the application from being broken, as it attempts it best to recover
  const settings = Object.assign({}, params, validators.validateStore(params.store), validators.validateTimeout(params.timeout), validators.validateCeid(params.ceid), validators.validateOperationMode(params.mode), validators.validateApiDomain(params.apiDomain), validators.validateQuickFetchLabel(params.quickFetchLabel), validators.validateInstanceName(params.name), validators.validateExperimentsSchema(params.experiments, params.identifiers));

  // Thse are parameters that define the structure of the Experiments
  const {
    experiments,
    identifiers
  } = settings;

  // These are params that define behavioural aspects of the library
  const {
    store,
    timeout,
    onError,
    apiDomain
  } = settings;

  // These are params that define overrides and extra functionalities
  const {
    ceid,
    mode,
    quickFetchLabel,
    name
  } = settings;
  const debug =
  // Check for enviro debug mode or if it's a local deployment
  enviro.debug('laboratory-lib') || enviro.debug('') || enviro.isQa() || !enviro.deployed();

  // This creates an instance of the debug logger
  const {
    logDebug
  } = loggers.createDebugLogger({
    debug
  });

  // If a cached instance already exists this means that a cached instance will be used
  // instead of the current `laboratory-lib` one. This should be mostly used for reusability
  // and to avoid creating multiple instances ofV the same `laboratory-lib` client
  // it is also used for the `laboratory-lib/test-utils` for allowing us to manipulate laboratory-lib
  if (name.length && globalInstanceCache.has(name)) {
    logDebug(messages.DEBUG_MESSAGES.instanceCacheHit(name));
  }

  // @deprecated the treatments schema is deprecated and we put it in a different section
  // to highlight that it should be removed in the future
  // it also relies on the parsed experiments schema to verify if both are present
  // as only either experiments or treatments should be used
  const {
    treatments
  } = validators.validateTreatmentsSchema(settings.treatments, settings.experiments);

  // Validates quick-fetch parameter and creates a promise with a dynamic import of the resulting validation
  // This allows treeshaking and quick-fetch to be loaded actually only when needed.
  const {
    quickFetch
  } = validators.validateQuickFetch(settings.quickFetch, settings.quickFetchLabel);

  // Validates http parameter and creates a promise with a dynamic import of the resulting validation
  // This allows treeshaking and http to be loaded actually only when needed.
  const {
    http
  } = validators.validateHttp(settings.http);

  // Check if we encountered Errors and warns that we faced errors but will attempt to continue execution
  validators.checkValidationErrors();
  const {
    resolveTreatments
  } = resolvers.createTreatmentsResolver({
    asyncStore: getAsyncStore(store),
    apiDomain,
    ceid,
    http,
    onError,
    quickFetch,
    quickFetchLabel,
    treatments,
    experiments,
    identifiers,
    timeout,
    logDebug,
    mode
  });
  const {
    resolveExposableTreatments
  } = resolvers.createExposureResolver({
    resolveTreatments,
    onError
  });
  const {
    logExposure,
    logExposures
  } = loggers.createExposureLogger({
    apiDomain,
    http,
    identifiers,
    resolveTreatments,
    onError,
    logDebug
  });

  // This will cache the instance if a cache name is provided
  // Effectively the functions themselves are cached with all the related
  // properties already incorporated. This works well because the API
  // is based on generator-functions, meaning after the functions of
  // `instanceCache` are defined, they don't need any other properties or params
  // from the current instance. Hence, we can easily simply cache an object containing
  // the refernece of these methods and it should work fine across the application bundle
  if (name.length && !globalInstanceCache.has(name)) {
    globalInstanceCache.set(name, {
      logExposure,
      logExposures,
      resolve: resolveTreatments,
      resolveExposures: resolveExposableTreatments
    });
  }
  return {
    logExposure: (...args) => getMethod(name, 'logExposure', logExposure)(...args),
    logExposures: (...args) => getMethod(name, 'logExposures', logExposures)(...args),
    resolve: (...args) => getMethod(name, 'resolve', resolveTreatments)(...args),
    resolveExposures: (...args) => getMethod(name, 'resolveExposures', resolveExposableTreatments)(...args)
  };
}