import { useCallback, useState } from 'react';

/**
 * A generic hook for managing controlled or uncontrolled state.
 */
export const useControlledState = (controlledValue, defaultValue) => {
  const [internalValue, setInternalValue] = useState(defaultValue);
  const isControlled = controlledValue !== undefined;
  const value = isControlled ? controlledValue : internalValue;
  const setValue = useCallback(newValue => {
    if (!isControlled) {
      setInternalValue(newValue);
    }
  }, [isControlled]);
  return {
    value,
    setValue
  };
};