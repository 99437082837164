const CHROMELESS_WINDOW_FEATURES = 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,titlebar=no,toolbar=no';
const DEFAULT_WINDOW_WIDTH = 550;
const DEFAULT_WINDOW_HEIGHT = 800;

/**
 * Get the default popup window options.
 *
 * @param {Number} [width=DEFAULT_WINDOW_WIDTH]
 * @param {Number} [height=DEFAULT_WINDOW_HEIGHT]
 * @param {boolean} [formatForChrome=false]
 * @returns {string} The popup window options.
 */
export const getWindowOptions = (width = DEFAULT_WINDOW_WIDTH, height = DEFAULT_WINDOW_HEIGHT) => {
  const {
    screenY,
    screenX,
    outerHeight,
    outerWidth
  } = window;

  // calculate top and left in order to center the window on the users' screen
  const top = Math.round(screenY + Math.max(0, Math.floor(outerHeight - height) / 2));
  const left = Math.round(screenX + Math.max(0, Math.floor(outerWidth - width) / 2));
  return `${CHROMELESS_WINDOW_FEATURES},width=${width},height=${height},top=${top},left=${left}`;
};