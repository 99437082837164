import TwoFactorAuthLevels from '../constants/TwoFactorAuthLevels';
import { createSelector } from 'reselect';
import TwoFactorAuthMethods from '../constants/TwoFactorAuthMethods';
import TwoFactorAuthHSAppStatuses from '../constants/TwoFactorAuthHSAppStatuses';
const getTwoFactorAuthState = state => state.twoFactorAuth;
export const getIsInvalidTokenAlertVisible = createSelector([getTwoFactorAuthState], ({
  isInvalidTokenAlertVisible
}) => isInvalidTokenAlertVisible);
export const getIsTokenExpiredAlertVisible = createSelector([getTwoFactorAuthState], ({
  isTokenExpiredAlertVisible
}) => isTokenExpiredAlertVisible);
export const getIsGenericTwoFactorErrorAlertVisible = createSelector([getTwoFactorAuthState], ({
  isGenericTwoFactorErrorAlertVisible
}) => isGenericTwoFactorErrorAlertVisible);
export const getIsInvalidCodeAlertVisible = createSelector([getTwoFactorAuthState], ({
  isInvalidCodeAlertVisible
}) => isInvalidCodeAlertVisible);
export const getIsNewCodeSentAlertVisible = createSelector([getTwoFactorAuthState], ({
  isNewCodeSentAlertVisible
}) => isNewCodeSentAlertVisible);
export const getIsCodeCallAlertVisible = createSelector([getTwoFactorAuthState], ({
  isCodeCallAlertVisible
}) => isCodeCallAlertVisible);
export const getIsSmsRateLimitAlertVisible = createSelector([getTwoFactorAuthState], ({
  isSmsRateLimitAlertVisible
}) => isSmsRateLimitAlertVisible);
export const getIsUserDeactivatedAlertVisible = createSelector([getTwoFactorAuthState], ({
  isUserDeactivatedAlertVisible
}) => isUserDeactivatedAlertVisible);
export const getIsBlockedLoginMethodAlertVisible = createSelector([getTwoFactorAuthState], ({
  isBlockedLoginMethodAlertVisible
}) => isBlockedLoginMethodAlertVisible);
export const getToken = createSelector([getTwoFactorAuthState], ({
  token
}) => token);
export const getIsVerifying = createSelector([getTwoFactorAuthState], ({
  isVerifying
}) => isVerifying);
export const getIsRequestingCode = createSelector([getTwoFactorAuthState], ({
  isRequestingCode
}) => isRequestingCode);
export const getTotp = createSelector([getTwoFactorAuthState], ({
  totp
}) => totp);
const getCredentials = createSelector([getTwoFactorAuthState], ({
  credentials
}) => credentials);
export const getEmail = createSelector([getCredentials], ({
  email
}) => email);
export const getPassword = createSelector([getCredentials], ({
  password
}) => password);
export const getRememberLogin = createSelector([getCredentials], ({
  rememberLogin
}) => rememberLogin);
export const getRememberThisDevice = createSelector([getTwoFactorAuthState], ({
  rememberThisDevice
}) => rememberThisDevice);
export const getBackupCodesEnabled = createSelector([getTwoFactorAuthState], ({
  backupCodesEnabled
}) => backupCodesEnabled);
export const getAppState = createSelector([getTwoFactorAuthState], ({
  appState
}) => appState);
const getPrimary = createSelector([getTwoFactorAuthState], ({
  primary
}) => primary);
const getPrimaryMethod = createSelector([getPrimary], primary => primary && primary.method);
const getSecondary = createSelector([getTwoFactorAuthState], ({
  secondary
}) => secondary);
const getSecondaryMethod = createSelector([getSecondary], secondary => secondary && secondary.method);
export const getTwoFactorLevel = createSelector([getTwoFactorAuthState], ({
  twoFactorLevel
}) => twoFactorLevel);
export const isPrimaryMethod = createSelector([getTwoFactorLevel], twoFactorLevel => {
  return twoFactorLevel === TwoFactorAuthLevels.PRIMARY;
});
export const hasSecondaryMethod = createSelector([getSecondary], secondary => secondary !== null);
export const getTwoFactorMethod = createSelector([getTwoFactorLevel, getPrimary, getSecondary], (twoFactorLevel, primary, secondary) => {
  return twoFactorLevel === TwoFactorAuthLevels.PRIMARY ? primary : secondary;
});
export const getTwoFactorMethodMethod = createSelector([getTwoFactorMethod], twoFactorMethod => twoFactorMethod && twoFactorMethod.method);
export const getPhoneNumber = createSelector([getTwoFactorMethod], twoFactorMethod => twoFactorMethod && twoFactorMethod.obfuscatedInfo ? twoFactorMethod.obfuscatedInfo : '');
export const getAreBothGoogleAuth = createSelector([getPrimaryMethod, getSecondaryMethod], (primaryMethod, secondaryMethod) => primaryMethod === TwoFactorAuthMethods.GOOGLE_AUTHENTICATOR && secondaryMethod === TwoFactorAuthMethods.GOOGLE_AUTHENTICATOR);
export const isHubspotAuthRequestResponded = createSelector([getTwoFactorAuthState], ({
  hubspotAuthenticatorResponse
}) => !!hubspotAuthenticatorResponse);
export const isHubspotAuthenticatorErrorVisible = createSelector([getTwoFactorAuthState], ({
  hubspotAuthenticatorResponse
}) => hubspotAuthenticatorResponse && hubspotAuthenticatorResponse !== TwoFactorAuthHSAppStatuses.APPROVED);
export const getIsRememberThisDeviceModalOpen = createSelector([getTwoFactorAuthState], ({
  isRememberThisDeviceModalOpen
}) => isRememberThisDeviceModalOpen);
export const getIsLoginWithHSPrompt = createSelector([getTwoFactorAuthState], ({
  isLoginWithHSPrompt
}) => isLoginWithHSPrompt);
export const getVettingUrl = createSelector([getTwoFactorAuthState], ({
  vettingUrl
}) => vettingUrl);