'use es6';

import { createErrorMessage, createErrorObject } from './errors';
import { DEBUG_MESSAGES, ERROR_MESSAGES } from '../messages';
import { ensureFn, ensurePromise, createQueryString, createQueryObject } from './helpers';
import { ENDPOINT_EXPOSURE_BULK_LOG, ENDPOINT_TREATMENTS_BULK_GET } from '../constants';

// This function allows us to standardise Network Errors
// into something that is easy to flag as being part of Laboratory
export const mutateNetworkError = error => {
  const networkError = createErrorObject('api', error instanceof Error ? error.message : error);

  // We assign this custom name to identify this is a Network Error from Laboratory
  networkError.name = 'LaboratoryNetworkError';
  if (error instanceof Error) {
    // By default we delete the original stack as laboratory-lib errors should not have a stack
    // But for Network Errors the rabbit hole doesn't originate from laboratory-lib itself
    // So if a stack is available we attach it.
    networkError.stack = error.stack;
  }
  return networkError;
};
export function logExposures(args = {}) {
  const {
    apiDomain = '',
    data,
    http,
    onError,
    query = {},
    logDebug
  } = args;
  const params = {
    data,
    query: createQueryObject(query)
  };
  const safeHttp = ensureFn(http, () => ensurePromise({}));
  const safeError = error => ensureFn(onError)(mutateNetworkError(error));
  const totalExposures = params.data ? params.data.exposures.length : 0;
  const logTreatmentExposure = () => ensureFn(logDebug)(DEBUG_MESSAGES.treatmendExposed(totalExposures), 'api');

  // Requests the resolution of the dynamic import and then acts on it
  // The whole logic is bounded with multiple fail-safes to ensure
  // That no exception happens and that the execution continues
  return safeHttp().then(resolvedHttp => {
    if (totalExposures === 0) {
      // We don't need to make `http` requests if we have 0 treatments to be exposed
      // Or if somehow the data is malformed. This is different from the other methods
      // As here `ceid` doesn't make a difference at all.
      return undefined;
    }
    const endpoint = `${apiDomain}/${ENDPOINT_EXPOSURE_BULK_LOG}`;
    const request = ensureFn(resolvedHttp.post)(endpoint, params);
    return ensurePromise(request).then(logTreatmentExposure).catch(safeError);
  });
}
export function getTreatmentsFromHttp(args = {}) {
  const {
    apiDomain = '',
    data = [],
    http,
    timeout,
    onComplete,
    onError,
    query = {}
  } = args;
  const params = {
    timeout,
    data,
    query: createQueryObject(query)
  };
  const safeHttp = ensureFn(http, () => ensurePromise({}));
  const safeError = error => ensureFn(onError)(mutateNetworkError(error));

  // Requests the resolution of the dynamic import and then acts on it
  // The whole logic is bounded with multiple fail-safes to ensure
  // That no exception happens and that the execution continues
  return safeHttp().then(resolvedHttp => {
    // Here we don't calculate if the `params.data.length` is empty
    // As for now we have the `ceid` parameter, that might be used
    // Even if the `data` being empty resulting on a network request being needed
    const endpoint = `${apiDomain}/${ENDPOINT_TREATMENTS_BULK_GET}`;
    const request = ensureFn(resolvedHttp.post)(endpoint, params);
    return ensurePromise(request).then(ensureFn(onComplete)).catch(safeError);
  });
}
export function getTreatmentsFromQuickFetch(args = {}) {
  const {
    apiDomain = '',
    data = [],
    quickFetch,
    quickFetchLabel,
    timeout,
    onComplete,
    onError,
    onTimeout,
    query = {}
  } = args;

  // Note, if `quick-fetch` module (import from static_conf) was not available at runtime
  // then we switch over for a mock. The mock will throw an Error if the module is not correctly installed
  const safeQuickFetch = ensureFn(quickFetch, () => ensurePromise({}));
  const safeError = error => ensureFn(onError)(mutateNetworkError(error));
  const safeTimeout = warn => ensureFn(onTimeout)(createErrorMessage('api', warn));

  // Requests the resolution of the dynamic import and then acts on it
  // The whole logic is bounded with multiple fail-safes to ensure
  // That no exception happens and that the execution continues
  return safeQuickFetch().then(resolvedQuickFetch => {
    const safeGetRequestData = ensureFn(resolvedQuickFetch.getRequestStateByName);
    let requestState = safeGetRequestData(quickFetchLabel);

    // We don't want to execute the quickFetch query again if it was either already executed within laboratory-lib
    // or if it was executed within the codebase (earlyRequester) of the consuming application.
    if (!requestState) {
      const queryString = createQueryString(query);
      ensureFn(resolvedQuickFetch.makeEarlyRequest)(quickFetchLabel, {
        url: `${apiDomain}/${ENDPOINT_TREATMENTS_BULK_GET}${queryString}`,
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json',
        type: 'POST',
        timeout
      });

      // Update the requestState with the new request state from quickFetch
      requestState = safeGetRequestData(quickFetchLabel);
    }
    if (requestState && typeof requestState === 'object') {
      const quickFetchTimeout = setTimeout(() => safeTimeout(ERROR_MESSAGES.internalTimeout),
      // Always ensure the timeout is a positive number
      Math.max(timeout, 0));

      // This ensures that the contexts are respected as `requestState` methods
      // self reference the `requestState` object and access entries from it.
      const onErrorCallback = ensureFn(requestState.onError).bind(requestState);
      const whenFinishedCallback = ensureFn(requestState.whenFinished).bind(requestState);
      whenFinishedCallback(treatments => {
        clearTimeout(quickFetchTimeout);
        ensureFn(onComplete)(treatments);
      });

      // Note that the quick-fetch library errors are always a string
      // So our `mutateNetworkError` will make it become an Error object
      onErrorCallback((_xhr, err) => {
        clearTimeout(quickFetchTimeout);
        safeError(err);
      });
      return;
    }

    // If quickFetch script is not available (i.e. window.quickFetch) then a handful of things might be at play
    // such as the environment being a test environment or Acceptance Tests or quick-fetch is indeed installed incorrectly
    // hence in this case we extraordinarily fallback to hub-http
    safeTimeout(ERROR_MESSAGES.missingWindowQuickFetch);
  });
}