/* hs-eslint ignored failing-rules */

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Nabi... Remove this comment to see the full error message
import BaseCookieLogin from 'Nabisco/BaseCookieLogin';
import { stringify } from 'hub-http/helpers/params';
import Raven from 'raven-js';
import { FAILED_LOGIN_PAGES } from '../constants/FailedLoginPages';
const IGNORE_REDIRECT_WRAPPER = new BaseCookieLogin().ignoreRedirectWrapper;
export default {
  create(triggerReset, transitionTo, extraSearchObject = {}) {
    return ({
      responseJSON
    }) => {
      if (responseJSON) {
        console.log('error factory');
        const {
          status,
          errorTokens
        } = responseJSON;
        if (status in FAILED_LOGIN_PAGES) {
          let searchString = `?${stringify(extraSearchObject)}`;
          if (responseJSON.errorTokens && responseJSON.errorTokens.email) {
            searchString = `?${stringify(Object.assign({
              email: responseJSON.errorTokens.email[0]
            }, extraSearchObject))}`;
          }
          if (responseJSON.errorTokens && responseJSON.errorTokens.vettingUrl) {
            searchString = `?${stringify(Object.assign({
              vettingUrl: responseJSON.errorTokens.vettingUrl[0]
            }, extraSearchObject))}`;
          }
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const nextPath = `${FAILED_LOGIN_PAGES[status]}${searchString}`;
          transitionTo(nextPath);
        } else {
          triggerReset(status, errorTokens);
        }
      } else {
        triggerReset('LOGIN_UNAVAILABLE');
      }
    };
  },
  createRedirectIgnored(triggerReset, transitionTo, extraSearchObject) {
    return IGNORE_REDIRECT_WRAPPER(this.create(triggerReset, transitionTo, extraSearchObject));
  },
  createRavenReporter(message, triggerReset, transitionTo, extraSearchObject) {
    const handler = this.createRedirectIgnored(triggerReset, transitionTo, extraSearchObject);
    return response => {
      let errorStatus = response.status.toString();
      if (response.responseJSON) {
        /* eslint-disable-next-line hubspot-dev/no-confusing-browser-globals */
        errorStatus = response.responseJSON.status || status;
      }
      Raven.captureMessage(message, {
        extra: {
          response: response.responseText,
          status: response.status
        },
        tags: {
          'error.status': errorStatus
        }
      });
      return handler(response);
    };
  }
};