'use es6';

import AppLoginDao from './dao/AppLoginDao';
import BaseCookieLogin from '../BaseCookieLogin';

// For now we need to pass `app` to the BaseCookieLogin class
// All api requests go to `app-api` though.
// This value gets used for cookie names.
const COOKIE_LOAD_BALANCERS = ['app'];
const VISIT_PATH = '/login-api/v1/login/visit';
class HubSpotOnlyAppCookieLogin extends BaseCookieLogin {
  constructor(options) {
    const {
      loginRequest
    } = options || {};
    super(COOKIE_LOAD_BALANCERS, loginRequest || AppLoginDao.login, AppLoginDao.prep, VISIT_PATH);
  }
  loginRequests(credentials) {
    this.credentials = credentials;
    return [this.loginRequest(credentials, 'app-api')];
  }
  redirectOnLoginSuccess([{
    redirectUrl
  }]) {
    window.location = redirectUrl;
  }
  domainPreps() {
    return [this.prepRequest('app-api')];
  }
}
export default HubSpotOnlyAppCookieLogin;