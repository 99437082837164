'use es6';

import { logError, logWarning } from './loggers';
import { hasRaven } from './helpers';
const SENTRY_EXTRA = Object.freeze({
  level: 'error',
  fingerprint: ['laboratory-lib', 'lab:error'],
  tags: {
    'laboratory-lib:error': 'error'
  }
});
export function createErrorMessage(caller, error) {
  return `[laboratory-lib "${caller}"] ${error}`;
}
export function createErrorObject(caller, error) {
  const errorObject = new Error(createErrorMessage(caller, error));

  // We set a custom Error name to tell this comes from Laboratory
  errorObject.name = 'LaboratoryError';

  // The Error stack is irrelevant as the Error doesn't originate from here
  delete errorObject.stack;
  return errorObject;
}

// First logs the error in the console and then attempts to report it to Sentry
// Which still could fail and if it does, we log the failure exception
export function logAndReportError(e, ops = {}) {
  if (typeof e === 'object') {
    if (hasRaven(window)) {
      window.Raven.captureException(e, Object.assign({}, SENTRY_EXTRA, ops));
    }
    logError(e);
  }
  if (typeof e === 'string') {
    logWarning(e);
  }
}